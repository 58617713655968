import { Md5 } from 'ts-md5/dist/md5';

interface PaymentDetails {
  name_first?: string;
  name_last?: string;
  email_address?: string;
  cell_number?: string | number;
  m_payment_id?: string;
  amount: number | null;
  item_name: string | null;
  item_description?: string;
  custom_str1?: string;
  custom_str2?: string;
}

export const postToURL = (pd: PaymentDetails, env: any) => {
  const redirectURLs = {
    return_url: `${env.url}/home?type=success`,
    cancel_url: `${env.url}/home?type=cancel`,
    notify_url: env.payfast.notify_url,
  };

  const values: any = {
    merchant_id: env.payfast.merchant_id,
    merchant_key: env.payfast.merchant_key,
    ...redirectURLs,
    ...pd,
  };

  const valuesArray = Object.values(values) as string[];
  const keysArray = Object.keys(values);
  const numberOfKeys = keysArray.length;

  const form = createElement('form', {
    action: env.payfast.payFastUrl,
    method: 'POST',
    style: 'display: none',
  });

  keysArray.forEach((key: any) => {
    form.appendChild(
      createElement('input', {
        type: 'hidden',
        name: key,
        value: values?.[key],
      })
    );
  });

  const pfOutput = createOutputString(valuesArray, keysArray, numberOfKeys);
  const signatureValue = Md5.hashStr(pfOutput) as string;

  form.appendChild(
    createElement('input', {
      type: 'hidden',
      name: 'signature',
      value: signatureValue,
    })
  );

  console.log('final: ', { pfOutput });

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};

const createOutputString = (
  values: string[],
  keys: string[],
  length: number
) => {
  let pfOutput = '';
  keys.forEach((key, i) => {
    if (values[i]) {
      pfOutput += `${key}=${encodeURIComponent(values[i])}${
        length - 1 === i ? '' : '&'
      }`;
    }
  });

  const pfOutputFinal = pfOutput.replace(/%2B/g, '+').replace(/%20/g, '+');

  console.log(pfOutputFinal);
  console.log({ md5: Md5.hashStr(pfOutputFinal) });

  return pfOutputFinal;
};

const createElement = (
  tagName: string,
  attributes: {
    [key: string]: string;
  }
): any => {
  const _attributes = attributes ?? {};
  const element = document.createElement(tagName);
  for (const attr in _attributes) {
    // eslint-disable-next-line no-prototype-builtins
    if (_attributes.hasOwnProperty(attr)) {
      element.setAttribute(attr, _attributes[attr]);
    }
  }
  return element as any;
};

// formatString(str: string) {
//   const trim = str.trim();
//   // const replaced = trim.replace(/ /g, "+");
//   return trim;
// }

// not used
// const getParameter = () => {
//   // Add "=" to the parameter name (i.e. parameterName=value)
//   const parameterNam = "pt";
//   const parameterName = parameterNam + "=";
//   const queryString = window.location.href.slice(
//     window.location.href.indexOf("?") + 1
//   );
//   if (queryString.length > 0) {
//     // Find the beginning of the string
//     let begin = queryString.indexOf(parameterName);
//     // If the parameter name is not found, skip it, otherwise return the value
//     if (begin !== -1) {
//       // Add the length (integer) to the beginning
//       begin += parameterName.length;
//       // Multiple parameters are separated by the "&" sign
//       let end = queryString.indexOf("&", begin);
//       if (end === -1) {
//         end = queryString.length;
//       }
//
//       return unescape(queryString.substring(begin, end));
//     }
//
//     // Nothing found
//     return "null";
//   }
// };
