import { Address } from './address';
import { FormlyFieldConfig } from '@ngx-formly/core';

export interface Image {
  id?: string;
  downloadURL: string;
  title?: string;
  description?: string;
  approved?: boolean;
  imageCategory?: string;
}

export interface ImagesData {
  imageFormFieldsIsRequired?: boolean;
  images?: Image[];
  componentType?: string;
  isBigDevice?: boolean;
  imageCategories?: string[];
  categoryName?: string;
  title: 'Add Image';
}

export interface Review {
  entityId: string; // business's id or masjid's id etc
  entityName: string; // business's name or masjid's name etc
  authorId: string;
  authorAvatar: string;
  authorName: string;
  createdAt: any;
  approved: boolean;
  viewedByAdmin?: boolean;
  subject?: string;
  message: string;
}

export interface Like {
  authorId: string;
  entityId: string; // business's id or masjid's id etc
  createdAt?: any;
}

export interface AbuseReport {
  reporterId: string;
  entityId: string; // business's id or masjid's id etc
  createdAt: any;
  message: string;
  resolved: boolean;
}

export interface Cart {
  products: { [id: string]: BookProduct };
  deliveryMethod?: 'collect' | 'deliver';
  deliveryAddress?: Address;
  deliveryCost: number;
  PFPaymentId?: string;
  PFPaymentStatus?: string;
  PFDataIsValid?: boolean;
  PFSignatureValid?: boolean;
  PFValidHost?: boolean;
  PFAmountMatched?: boolean;
  details?: Details;
  uid: string;
  isAnonymous: boolean;
  isPile?: boolean;
}

export interface Details {
  displayName: string;
  email: string;
  contactNumber: string;
  whatsappNumber?: string;
}

export interface BookProduct {
  id: string;
  title: string;
  description: string;
  thumbUrl?: string;
  price: string;
  quantity: number;
  quantityLimit: number;
  insertedAt?: any; // timestamp
  updatedAt?: any; // timestamp
  state?: States;
  views?: number;
  weight: number;
}

export interface BookMoreInfo {
  pageCount?: number;
  previewLink?: string;
  publisher?: string;
  language?: string;
  title?: string;
  description?: string;
  categories?: string[];
  publishedDate?: string;
  maturityRating?: string;
  imageLinks?: { thumbnail: string; smallThumbnail: string };
  authors?: string[];
}

export interface Book {
  id?: string;
  title?: string;
  description?: string;
  images?: Image[];
  price?: string;
  shortName?: string;
  imgHeight?: string;
  previewUrl?: string;
  views?: number;
  likes?: Like[];
  createdAt?: any;
  authorName?: string;
  creatorId?: string;
  viewedByAdmin?: boolean;
  type?: string;
  creatorType?: string;
  isDraft?: boolean;
  approved?: boolean;
  quantity?: number;
  moreInfo: BookMoreInfo;
  weight: number;
}

export interface BookBasic {
  id: string;
  title: string;
  description: string;
  imgUrl: string;
  price: string;
  shortName: string;
  imgHeight?: string;
  previewUrl?: string;
  views?: number;
  likes?: Like[];
}

export interface PaymentDetails {
  name_first?: string;
  name_last?: string;
  email_address?: string;
  cell_number?: string | number;
  m_payment_id?: string;
  amount: number | null;
  item_name: string | null;
  item_description?: string;
  custom_str1?: string;
  custom_str2?: string;
  custom_str3?: string;
  custom_str4?: string;
}

const enum States {
  cart = 1,
  delivery,
  payment,
  paySuccessful,
  payCancelled,
}

export interface Order {
  uid: string;
  createdAt: any;
  delivered: boolean;
  deliveredAt?: any;
  deliveryAddress?: Address;
  deliveryDate?: any;
  deliveryMethod?: 'collect' | 'deliver';
  collectionDate?: any;
  fastWayCollectionDate?: any;
  recipientName: string;
  recipientNumber?: string;
  recipientEmail: string;
  status?: string;
  isPile?: boolean;
  products: {
    [id: string]: {
      quantity: number;
    };
  };
}

export interface Service {
  quantity?: number;
  price?: string;
  quantityCanEdit?: boolean;
  text?: string;
  id?: string;
  category?: string;
  details?: string;
  isHeader?: boolean;
  level?: number;
  position?: number; // this is for its sequence in the list to sort
  _ft_updatedBy?: any;
  _ft_updatedAt?: any;
  updatedBy?: any;
  updatedAt?: any;
  hidden?: boolean;
}

export interface ProductInCart {
  id: string;
  title: string;
  description: string;
  thumbUrl?: string;
  price: string;
  quantity: number;
  quantityLimit: number;
  insertedAt?: any; // timestamp
  updatedAt?: any; // timestamp
  state?: States;
  views?: number;
  weight: number;
}

export interface Product {
  id?: string;
  title?: string;
  description?: string;
  images?: Image[];
  price?: string;
  shortName?: string;
  imgHeight?: string;
  previewUrl?: string;
  views?: number;
  likes?: Like[];
  createdAt?: any;
  authorName?: string;
  creatorId?: string;
  viewedByAdmin?: boolean;
  type?: string;
  creatorType?: string;
  isDraft?: boolean;
  approved?: boolean;
  quantity?: number;
  moreInfo: ProductMoreInfo;
  weight: number;
}

export interface ProductMoreInfo {
  pageCount?: number;
  previewLink?: string;
  publisher?: string;
  language?: string;
  title?: string;
  description?: string;
  categories?: string[];
  publishedDate?: string;
  maturityRating?: string;
  imageLinks?: { thumbnail: string; smallThumbnail: string };
  authors?: string[];
}

export interface ProductBasic {
  id: string;
  title: string;
  description: string;
  imgUrl: string;
  price: string;
  shortName: string;
  imgHeight?: string;
  previewUrl?: string;
  views?: number;
  likes?: Like[];
}

export const cleaningServiceFields: FormlyFieldConfig[] = [
  {
    key: 'id',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    defaultValue: 'UNSET',
    props: {
      label: 'ID',
      labelPosition: 'stacked',
      disabled: true,
      required: true,
    },
  },
  {
    key: 'isHeader',
    type: 'checkbox',
    wrappers: ['radium-formly-wrapper'],
    defaultValue: false,
    props: {
      label: 'Is this a header only?',
      // placeholder: 'Enter Is Header',
      required: true,
      disabled: true,
      labelPosition: 'stacked',
    },
  },
  {
    key: 'hidden',
    type: 'checkbox',
    wrappers: ['radium-formly-wrapper'],
    defaultValue: false,
    props: {
      label: 'Hidden',
      // placeholder: 'Enter Is Header',
      required: true,
      labelPosition: 'stacked',
    },
  },
  {
    key: 'text',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Name',
      placeholder: 'Enter Service Name',
      required: true,
      labelPosition: 'stacked',
    },
  },
  {
    key: 'details',
    type: 'textarea',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Details',
      placeholder: 'Enter Service Details',
      required: true,
      labelPosition: 'stacked',
    },
  },
  {
    key: 'category',
    type: 'select',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Category',
      hideLabel: false,
      placeholder: 'Select Service Category',
      required: true,
      labelPosition: 'stacked',
      options: [],
    },
  },
  {
    key: 'price',
    type: 'number',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Price',
      placeholder: 'Enter Service Price',
      required: true,
      labelPosition: 'stacked',
    },
  },
  {
    key: 'quantity',
    type: 'number',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Quantity',
      placeholder: 'Enter Service Quantity',
      required: true,
      labelPosition: 'stacked',
    },
  },
  {
    key: 'quantityCanEdit',
    type: 'checkbox',
    wrappers: ['radium-formly-wrapper'],
    defaultValue: false,
    props: {
      label: 'Can the quantity be edited?',
      // placeholder: 'Enter Is Header',
      required: true,
      labelPosition: 'stacked',
    },
  },
  // {
  //   key: 'child',
  //   type: 'input',
  //   wrappers: ['radium-formly-wrapper'],
  //   props: {
  //     label: 'Child',
  //     placeholder: 'Enter Child',
  //     required: true,
  //     labelPosition: 'stacked',
  //   },
  // },
  // {
  //   key: 'level',
  //   type: 'number',
  //   wrappers: ['radium-formly-wrapper'],
  //   props: {
  //     label: 'Level',
  //     placeholder: 'Enter Level',
  //     required: true,
  //     labelPosition: 'stacked',
  //   },
  // },
  {
    key: 'position',
    type: 'number',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Position in the list',
      placeholder: 'Enter Position',
      required: true,
      labelPosition: 'stacked',
    },
  },
];
