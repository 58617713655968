import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'radium-nav-buttons',
  templateUrl: './nav-buttons.component.html',
  styleUrls: ['./nav-buttons.component.scss'],
})
export class NavButtonsComponent {
  @Input() last?: boolean;
  @Input() first?: boolean;
  @Input() hasItems?: boolean;
  @Input() disableBack?: boolean;
  @Input() isBigDevice?: boolean;
  @Input() isOdd?: boolean;
  @Input() limit?: number;
  @Input() length?: number;
  @Output() navAction = new EventEmitter();

  doAction(type: any) {
    this.navAction.emit(type);
  }
}
