import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CustomValidators } from '../../../../../utils';

@Component({
  selector: 'radium-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
})
export class ContactDetailsComponent implements OnInit, OnDestroy {
  @Input() package?: string;
  @Input() isOrg = false;
  contactDetailsForm?: FormGroup;
  uid?: string;
  isBigDevice?: boolean;

  categories = [
    { id: 1, description: 'Gauteng' },
    { id: 2, description: 'Western Cape' },
    { id: 3, description: 'Northern Cape' },
    { id: 4, description: 'Mpumalanga' },
    { id: 5, description: 'Eastern Cape' },
    { id: 6, description: 'Limpopo' },
    { id: 7, description: 'North West:' },
    { id: 8, description: 'KwaZulu-Natal' },
    { id: 9, description: 'Free State' },
  ];

  private sub?: Subscription;
  private sub1?: Subscription;

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.contactDetailsForm = this.formBuilder.group({
      contactPersonName: ['', Validators.required],
      contactPersonNumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(20),
          Validators.minLength(4),
        ]),
      ],
      email: [
        '',
        Validators.compose([Validators.required, CustomValidators.email]),
      ],
      whatMatters: [''],
      whatsappNumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(20),
          Validators.minLength(4),
        ]),
      ],
    });

    this.sub = this.contactDetailsForm
      ?.get('contactPersonNumber')
      ?.valueChanges.subscribe((x: string) => {
        let newX = x;

        if (x !== undefined && x?.substr(0, 3) !== '+27') {
          newX = '+27' + x;
          this.contactDetailsForm?.get('contactPersonNumber')?.setValue(newX);
        }

        if (this.contactDetailsForm?.get('whatsappNumber')?.pristine) {
          this.contactDetailsForm?.get('whatsappNumber')?.setValue(newX);
        }

        // console.log(
        //   this.contactDetailsForm
        //     .get('contactPersonNumber')
        //     ?.getError('phoneEmptyError')
        // );

        // if (
        //   this.contactDetailsForm
        //     .get('contactPersonNumber')
        //     ?.getError('phoneEmptyError') !== null
        // ) {
        //   this.contactDetailsForm.get('contactPersonNumber')?.setErrors(null);
        // }
      });

    this.sub1 = this.contactDetailsForm
      ?.get('whatsappNumber')
      ?.valueChanges.subscribe((x: string) => {
        let newX = x;

        if (x !== undefined && x?.substr(0, 3) !== '+27') {
          newX = '+27' + x;
          this.contactDetailsForm?.get('whatsappNumber')?.setValue(newX);
        }
        // console.log(this.contactDetailsForm.controls);
        //
        // if (
        //   this.contactDetailsForm
        //     .get('whatsappNumber')
        //     ?.getError('phoneEmptyError') !== null
        // ) {
        //   this.contactDetailsForm.get('whatsappNumber')?.setErrors(null);
        // }
      });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.sub1) {
      this.sub1.unsubscribe();
    }
  }
}
