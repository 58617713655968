import { catchError, of, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { User as fUser } from '@angular/fire/auth';
import { AlertController, ToastController } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import { take } from 'rxjs/operators';
import {
  Address,
  LocationDetailsComponent,
  User,
  UserBasicInfoComponent,
} from '@radium/ui';
import {
  FirebaseBusinessService,
  FirebaseUserService,
  LoadingService,
} from '@radium/core';

@Component({
  selector: 'radium-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit, OnDestroy {
  @ViewChild('basicInfo') basicInformation?: UserBasicInfoComponent;
  @ViewChild('locationDetails') ld?: LocationDetailsComponent;
  uid = '';
  sub?: Subscription;
  isBigDevice?: boolean;
  newUser?: boolean;
  action = 'Create ';

  private sub2?: Subscription;

  isError?: boolean;
  private sub3?: Subscription;
  emailVerified?: boolean;
  params: any;
  infoComplete?: boolean;
  accountApproved?: boolean;
  @Input() hasCart?: boolean;
  @Input() showFireBaseUIUser = false;
  @Input() showAddressAndBasicUserInfo = true;
  @Input() currentUser: fUser;

  constructor(
    public fireBusService: FirebaseBusinessService,
    public alertCtrl: AlertController,
    public aRoute: ActivatedRoute,
    private fb: FormBuilder,
    private router: Router,
    public loadingService: LoadingService,
    public toastCtrl: ToastController,
    public fireUser: FirebaseUserService
  ) {}

  ngOnInit() {
    this.fireUser.isBigDevice().subscribe((x) => (this.isBigDevice = !x));

    this.aRoute.queryParams
      .pipe(take(1))
      .subscribe((params) => (this.params = params));

    this.loadingService.loadingPresent('Loading ...', 'account').then(() => {
      if (this.currentUser !== null) {
        this.uid = this.currentUser?.uid;
        this.emailVerified = this.currentUser?.emailVerified;

        if (this.params.becomeAffiliate && this.currentUser?.emailVerified) {
          this.toast().catch();
        }

        this.sub2 = this.fireUser
          .getUserDoc(this.currentUser?.uid)
          .pipe(
            catchError((err) => {
              this.wrapUpLoading();
              return of(err);
            })
          )
          .subscribe((user) => {
            console.log({ user });
            if (!user) {
              return;
            } else if (user?.['code'] === 'permission-denied') {
              this.isError = true;
            } else {
              this.isError = false;
              this.infoComplete = user?.infoComplete;
              this.accountApproved = user?.approved;
              this.patchValues(user);
            }
            this.wrapUpLoading();
          });
      }
    });
  }

  addAddress(addresses: Address[]) {
    console.log({ addresses });
    const addressesCopy: Address[] = addresses;
    addressesCopy.map((ac: any) => {
      for (const key in ac) {
        if (ac?.[key] === undefined) {
          delete ac?.[key];
        }
      }
      return ac;
    });
    //
    console.log({ addressesCopy });
    this.fireUser
      .updateUserDoc(this.uid, { addresses: addressesCopy })
      .catch((err) => console.log({ err }));

    if (this.hasCart) {
      this.fireBusService
        .updateCart(this.uid, {
          deliveryAddress: addressesCopy.find((x) => x.inUse === true),
        })
        .catch();
    }
  }

  confirmContinue(normalUser?: boolean, refreshOnly?: boolean) {
    console.log('userForm', this.basicInformation?.userForm.value);

    if (refreshOnly) {
      this.fireUser
        .getUserDoc(this.uid)
        .pipe(take(1))
        .subscribe((user: any) => {
          if (!user) {
            return;
          } else if (user?.['code'] === 'permission-denied') {
            this.isError = true;
          } else {
            this.isError = false;
            this.infoComplete = user?.infoComplete;
            this.accountApproved = user?.approved;
            this.patchValues(user);
          }
        });
    } else {
      const data: Partial<User> = {
        email: this.basicInformation?.userForm?.get('email')?.value,
        displayName: this.basicInformation?.userForm?.get('displayName')?.value,
        contactNumber:
          this.basicInformation?.userForm.get('contactNumber')?.value,
        type: normalUser ? 'normalUser' : 'affiliate',
        approved: false,
      };

      this.fireUser
        .updateUserDoc(this.uid, data)
        .then(() => this.router.navigate(['home']))
        .catch((err) => console.log({ err }));

      if (this.hasCart) {
        this.fireBusService
          .updateCart(this.uid, {
            details: {
              ...this.basicInformation?.userForm?.value,
            },
          })
          .catch();
      }
    }
  }

  private patchValues(user: User) {
    this.basicInformation?.userForm?.patchValue({
      displayName: user.displayName,
      email: user.email,
      contactNumber: user.contactNumber,
      whatsappNumber: user?.whatsappNumber ?? user?.contactNumber ?? '',
    });

    if (user?.addresses && user?.addresses?.length > 0 && this.ld) {
      this.ld?.initializeAddresses(user?.addresses);
    }

    this.action = 'Update ';
  }

  private wrapUpLoading() {
    return setTimeout(() => {
      if (this.loadingService.isLoading$) {
        this.loadingService.loadingDismiss('account').catch();
      }
    }, 1000);
  }

  async toast() {
    const toast = await this.toastCtrl.create({
      duration: 7000,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
          handler: () => {
            console.log('Close clicked');
          },
        },
      ],
      position: 'middle',
      header: `Please fill in all the required fields in the forms on this page.
        Once you have submitted it, an admin will approve your submission. Thank you.`,
    });

    await toast.present();
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.sub2) {
      this.sub2.unsubscribe();
    }
    if (this.sub3) {
      this.sub3.unsubscribe();
    }
  }
}
