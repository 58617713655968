import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price',
})
export class PricePipe implements PipeTransform {
  transform(
    value: { price: number; quantity: number }[],
    deliveryFee: number
  ): number {
    return (
      Number(value.reduce((x, y) => x + y.price * y.quantity, 0).toFixed(2)) +
      deliveryFee
    );
  }
}
