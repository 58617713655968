import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { AccountComponent } from './account';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';
import { HeaderModule, UiModule } from '@radium/ui';
import { LoginComponent } from './login';
import { CheckoutComponent } from './checkout';
import { MaterialModule } from '@radium/material';
import { PayfastReturnComponent } from './payfast-return';
import { BookCardComponent } from './book-card';
import { TagInputModule } from 'ngx-chips';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { MatListModule } from '@angular/material/list';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { FormatsDialogComponent } from './formats-dialog';
import { CreateBookComponent } from './createBook';
import { MenuComponent } from './menu';
import { RouterModule } from '@angular/router';
import { TableComponent } from './table/table.component';
import { MatRippleModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FooterComponent } from './footer/footer.component';
import { TableModalComponent } from './table-modal';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MaterialModule,
    ReactiveFormsModule,
    NgxAuthFirebaseUIModule,
    TagInputModule,
    ZXingScannerModule,
    MatListModule,
    MatDialogModule,
    MatMenuModule,
    RouterModule,
    MatRippleModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatCheckboxModule,
    HeaderModule,
    UiModule,
  ],
  declarations: [
    AccountComponent,
    LoginComponent,
    CheckoutComponent,
    PayfastReturnComponent,
    BookCardComponent,
    FormatsDialogComponent,
    CreateBookComponent,
    MenuComponent,
    TableComponent,
    FooterComponent,
    TableModalComponent,
  ],
  exports: [
    AccountComponent,
    LoginComponent,
    CheckoutComponent,
    PayfastReturnComponent,
    BookCardComponent,
    FormatsDialogComponent,
    CreateBookComponent,
    MenuComponent,
    TableComponent,
    FooterComponent,
    TableModalComponent,
  ],
})
export class ScreensModule {
  static forRoot(env: any): ModuleWithProviders<ScreensModule> {
    return {
      ngModule: ScreensModule,
      providers: [{ provide: 'environment', useValue: env }],
    };
  }
}
