import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AlertController, ToastController } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'radium-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() pageName?: string;
  @Input() infoComplete?: boolean;
  @Input() accountApproved?: boolean;
  @Input() isBigDevice?: boolean;
  @Input() showMenuButton = true;
  @Input() showBackButton?: boolean;
  @Input() uid?: string;
  @Input() isNormalUser = true;
  @Input() showCart = true;
  @Input() showSearch = false;
  @Input() showSearchIcon = false;
  @Input() showFilter = false;
  @Input() showFilterIcon = false;
  @Input() isAnonymous = true;
  @Input() count = 0;
  @Input() filterItems: string[] = [];
  @Input() incomingFilters = [];
  @Input() showToggle = false;
  @Input() showImg = false;
  @Input() img: string;
  @Input() buttons: {
    text: string;
    icon: string;
    link: string;
    color?: string;
    fill?: string;
    disabled?: boolean;
    onClick?: (e: Event) => void;
  }[] = [];
  @Input() showProfile = false;
  @Output() searchQuery = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<void>();
  @Output() headerEvent = new EventEmitter();

  sub?: Subscription;

  constructor(
    public alertCtrl: AlertController,
    public router: Router,
    public toastCtrl: ToastController
  ) {}

  ngOnInit() {
    console.log('im init: ', this.incomingFilters);
    //
    // this.searchFunction({query: this.searchQuery}).then((result) => {
    //   // const { hits } = result;
    //   console.log("Found results", result);
    // });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  cancelSearch() {
    this.cancel.emit();
    this.showSearch = false;
    this.showFilter = false;
    this.incomingFilters = [];
  }

  selectedFilters(event: any) {
    if (event?.length === 0) {
      this.cancelSearch();
    } else {
      this.searchQuery.emit(event.map((x: any) => x.value));
    }
  }
}
