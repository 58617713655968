import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import {
  deleteObject,
  getDownloadURL,
  ref,
  Storage,
  StorageReference,
  UploadResult,
  uploadString,
} from '@angular/fire/storage';

@Injectable({
  providedIn: 'root',
})
export class FirebaseStorageService {
  ref?: StorageReference;
  task?: UploadResult;

  constructor(private storage: Storage, private http: HttpClient) {}

  async createFile(json: string, id: any, newName: string) {
    this.ref = ref(this.storage, `iraabsNew/${newName + '.json'}`);
    this.task = await uploadString(this.ref, json).catch();
    const url = await getDownloadURL(this.ref).catch();
    return { task: this.task, url };
  }

  getFile(url: string) {
    return this.http.get(url).pipe(
      take(1),
      map((res) => res)
    );
  }

  deleteFile(storagePath: any) {
    return deleteObject(ref(this.storage, storagePath)).catch();
  }
}
