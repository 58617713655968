import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

import { Platform } from '@ionic/angular';

export const CITY_PICKER_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadiumTagsInputComponent),
  multi: true,
};

@Component({
  selector: 'radium-tags-input',
  providers: [CITY_PICKER_VALUE_ACCESSOR],
  template: `
    <div
      class="iti-tags-wrap"
      style="display: flex;flex-wrap: wrap;"
      (click)="unDone()"
    >
      <radium-tag
        *ngFor="let tag of _tags; let $index = index"
        [tag]="tag"
        [mode]="$any(mode)"
        [color]="cssColor"
        [allowClear]="!hideRemove && !readonly"
        (cleared)="btnRemoveTag($index)"
      >
      </radium-tag>
    </div>
    <input
      #tagsInput
      [hidden]="readonly"
      [disabled]="readonly"
      class="iti-input"
      [type]="type"
      [placeholder]="field.props?.tagPlaceholder || placeholder"
      (blur)="blur()"
      (keyup.backspace)="keyRemoveTag(); (false)"
      (keyup)="separatorStrAddTag()"
      (keyup.enter)="keyAddTag()"
      [formControl]="formControl"
      [formlyAttributes]="field"
      (click)="unDone()"
      title="Click enter or comma to finish typing!"
    />
    <ion-button
      *ngIf="_tags?.length > 0 && formControl.dirty"
      color="success"
      (click)="done()"
      >done</ion-button
    >
  `,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./radium-tags-input.component.scss'],
})
export class RadiumTagsInputComponent
  extends FieldType<FieldTypeConfig>
  implements ControlValueAccessor, OnInit
{
  @Input() mode = '';
  @Input() readonly = false;
  @Input() hideRemove = false;
  @Input() maxTags = -1;
  @Input() placeholder = '+Tag';
  @Input() type = 'text';
  @Input() separatorStr = ',';
  @Input() canEnterAdd = true;
  @Input() canBackspaceRemove = true;
  @Input() verifyMethod: (tagSrt: string) => boolean;

  @Input()
  set color(value: string) {
    this.cssColor = value;
  }

  @Output() changed: EventEmitter<any> = new EventEmitter();
  @Output() ionFocus: EventEmitter<any> = new EventEmitter();
  @Output() ionBlur: EventEmitter<any> = new EventEmitter();
  @ViewChild('tagsInput') input;

  _tags: Array<string> = [];
  _isFocus = false;
  _onChanged: (x?) => any;
  _onTouched: (x?) => any;
  cssColor: string;

  @HostBinding('class.tit-border-color')
  private addTitBorderColorClass = true;

  @HostBinding('class.radium-tags-input')
  private addRadiumTagsInputClass = true;

  @HostBinding('style.border-bottom-color')
  get borderBottomColor() {
    return this._isFocus ? this.cssColor : null;
  }

  @HostBinding('class.active')
  get active() {
    return this._isFocus;
  }

  @HostBinding('class.readonly')
  get readonlyClass() {
    return this.readonly;
  }

  @HostListener('click', ['$event'])
  onClick(ev: UIEvent) {
    ev.preventDefault();
    ev.stopPropagation();
  }

  constructor(public plt: Platform, public ref: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    console.log(this);
    if (this.mode === '') {
      this.plt.ready().then(() => {
        this.initMode();
      });
    }
    if (this.field.defaultValue && this.field.defaultValue?.length > 0) {
      this._tags = this.field.defaultValue;
      setTimeout(() => {
        this.formControl.setValue('');
      }, 100);
    }
  }

  keyAddTag() {
    const tagStr = this.formControl.value?.trim();
    if (!this.canEnterAdd) return;
    if (!this.verifyTag(tagStr)) return;
    console.log(this.field.props?.once);
    if (this.field.props?.once && !this.isOnce(tagStr)) {
      this.formControl.setValue('');
      return;
    }
    this.pushTag(tagStr);
  }

  separatorStrAddTag() {
    const lastIndex: number = this.formControl.value.length - 1;
    let tagStr = '';
    if (!this.separatorStr) return;

    if (this.formControl.value[lastIndex] === this.separatorStr) {
      tagStr = this.formControl.value.split(this.separatorStr)[0].trim();

      if (this.verifyTag(tagStr) && this.isOnce(tagStr)) {
        this.pushTag(tagStr);
      } else {
        this.formControl.setValue('');
      }
    }
  }

  keyRemoveTag() {
    if (!this.canBackspaceRemove) return;
    if (this.formControl.value === '') {
      this.removeTag(-1);
      this.formControl.setValue('');
    }
  }

  btnRemoveTag($index: number) {
    this.removeTag($index);
  }

  verifyTag(tagStr: string): boolean {
    if (typeof this.verifyMethod === 'function') {
      if (!this.verifyMethod(tagStr)) {
        this.formControl.setValue('');
        return false;
      } else {
        return true;
      }
    }

    if (!tagStr.trim()) {
      this.formControl.setValue('');
      return false;
    } else {
      return true;
    }
  }

  pushTag(tagStr: string) {
    if (this.maxTags !== -1 && this._tags.length >= this.maxTags) {
      this.formControl.setValue('');
      return;
    }
    this._tags.push(tagStr.trim());
    this.ref.detectChanges();
    this.changed.emit(this._tags);
    this.formControl.setValue('');
  }

  removeTag($index: number) {
    if (this._tags.length > 0) {
      if ($index === -1) {
        this._tags.pop();
        this.changed.emit(this._tags);
      } else if ($index > -1) {
        this._tags.splice($index, 1);
        this.changed.emit(this._tags);
      }
    }
  }

  isOnce(tagStr: string): boolean {
    const tags: string[] = this._tags;
    return tags.every((e: string): boolean => {
      return e !== tagStr;
    });
  }

  @HostListener('click', ['$event'])
  _click(ev: UIEvent) {
    // if (!this._isFocus) {
    // }
    this.focus();
    ev.preventDefault();
    ev.stopPropagation();
  }

  public blur() {
    if (this._isFocus) {
      this._isFocus = false;
      this.ionBlur.emit(this._tags);
    }
  }

  public focus() {
    if (!this._isFocus) {
      this._isFocus = true;
      this.input.nativeElement.focus();
      this.ionFocus.emit(this._tags);
    }
  }

  writeValue(val): void {
    this._tags = val;
  }

  registerOnChange(fn): void {
    this._onChanged = fn;
    this.setValue(this._tags);
  }

  registerOnTouched(fn): void {
    this._onTouched = fn;
    this.input.nativeElement.style.width = 'auto';
  }

  setValue(val) {
    this._tags = val;
    if (this._tags) {
      this._onChanged(this._tags);
    }
  }

  done() {
    this.formControl.setValue(this._tags);
    this.formControl.markAsPristine({ onlySelf: true });
    this.input.nativeElement.style.width = '0';
  }

  unDone() {
    this.formControl.setValue('');
    this.formControl.markAsDirty({ onlySelf: true });
    this.input.nativeElement.style.width = 'auto';
  }

  initMode() {
    this.mode = this.plt.is('ios')
      ? 'ios'
      : this.plt.is('android')
      ? 'md'
      : 'md';
  }
}
