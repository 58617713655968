import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  fromTask,
  getDownloadURL,
  getMetadata,
  percentage,
  ref,
  Storage,
  StorageReference,
  uploadBytesResumable,
  UploadTask,
  UploadTaskSnapshot,
} from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

// import { storage } from "firebase/app";

@Component({
  selector: 'radium-upload-task',
  templateUrl: './upload-task.component.html',
  styleUrls: ['./upload-task.component.scss'],
})
export class UploadTaskComponent implements OnInit {
  @Input() fileInfo?: File;
  @Output() imageData = new EventEmitter();
  ref?: StorageReference;
  task?: UploadTask;
  percentage?: Observable<{ progress: number; snapshot: UploadTaskSnapshot }>;
  snapshot?: Observable<UploadTaskSnapshot>;

  // snapshot: Observable<storage.UploadTaskSnapshot>;

  constructor(
    private storage: Storage,
    @Inject('environment') private env: any
  ) {}

  ngOnInit() {
    this.startUpload();
  }

  startUpload() {
    if (!this.fileInfo) {
      console.error('no file info :( ');
      return;
    }

    if (this.fileInfo?.type?.split('/')[0] !== 'image') {
      console.error('unsupported file type :( ');
      return;
    }

    // Totally optional metadata
    const customMetadata = this.env?.storageConfig?.customMetadata;

    // The storage path
    const path = `${this.env?.storageConfig?.bucketName}/${Date.now()}_${
      this.fileInfo.name
    }`;

    // Reference to storage bucket
    this.ref = ref(this.storage, path);

    // The main task
    this.task = uploadBytesResumable(this.ref, this.fileInfo, {
      customMetadata,
    });

    // Progress monitoring
    this.percentage = percentage(this.task);
    //
    this.snapshot = fromTask(this.task).pipe(
      // The file's download URL
      finalize(async () => {
        const downloadURL = await getDownloadURL(this.ref).catch(
          (error: any) => {
            console.error(error);
            this.imageData.emit('error');
            // if (downloadURL?.name === 'FirebaseError') {
            //   this.imageData.emit('error');
            //   return;
            // }
            return;
          }
        );

        getMetadata(this.ref).then((metadata) => {
          console.log({ metadata });
        });
        this.imageData.emit({
          downloadURL,
          path,
        });
      }),
      catchError(async (err: any) => console.log({ err }))
    ) as unknown as Observable<UploadTaskSnapshot>;
  }

  isActive(snapshot: UploadTaskSnapshot) {
    return (
      snapshot.state === 'running' &&
      snapshot.bytesTransferred < snapshot.totalBytes
    );
  }

  cancel(task: UploadTask) {
    task.cancel();
    this.imageData.emit('cancel');
  }
}
