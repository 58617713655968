import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amount',
})
export class AmountPipe implements PipeTransform {
  transform(value: { quantity: number }[]): number {
    return value.reduce((x, y) => x + y.quantity, 0);
  }
}
