export const thumbnail = {
  image: `aVZCT1J3MEtHZ29BQUFBTlNVaEVVZ0FBQURJQUFBQXlDQVlB
    QUFBZVA0aXhBQUFBQm1KTFIwUUEvd0QvQVArZ3ZhZVRBQUFBQ1hCSQ0K
    V1hNQUFCYnFBQUFXNmdIbGprTVFBQUFBQjNSSlRVVUg0Z1lPRWpJQmZSS
    0JRd0FBRWRWSlJFRlVhTjdObW5tUVhYV1Z4eisvMzcxdg0KNzlkcjBraz
    ZIYkowRmpBeElTVElha1JBcEFDdFFYRVpSaEJGTEJYTEVyWEtHblZtc0J
    5Y0trZG1ha1pucEFvVndSZ2dFNFFrSkVBQQ0KZzBuSVRtZnBKSkNsMDUx
    T3VqdmRyMSsvMTYvN2JYZjVuZm5qdmQ2U0FBbFFVM083WHRXOXQrOXYrW
    jV6dnQ5emZyOTdGV01Pei9kcw0Kb0VLaEFrN082WXZFSXlKR2xOSktBSH
    pmdTB4cmF6b3dCQnozZmJmRHRvTXUvdzhPTmZiQ04xNDFjQk9vdXhUcUVQ
    QzAxcnJaR0Q4cw0KNkc5b3hSV1NQekhIZU4wNUszN2xWcURPR0Y3d3ZQe
    kdVQ2lhTXNZQW9MWCtQd2RpajcrVUlxZ080Q2pRcUdBcTBLeVUxZ3F1em
    N2Vw0Kajc2UmU2MityYStIeHNMZWFWZk52TVVMVjE1ME1oaU1uRGJHUDZ
    5MVRsN1E2RjFBd3djTXhEY2VscmJ6d0hiSHlPR0E0dU5BaDRobw0KMTIy
    N2VzRGRQM1dudTc3cSthRVdEdlpsbUozWVBTTlI3RDU1ZWNOMVMyYlVY
    emFnZ2xWa3BKQ283QWlmb0JlaEFxdnNjVUVRT3ZDNA0KR1gvYzZNTWdq
    Z0p6UHREUUVpdzlla3RPN0xhWSt1RWJpMTdiUDI3ci9lWlZ2ODhIMUo1
    OGtId3VUeUdicGFHdm0vdW0zc3JpMmtWUA0KWGp6ck14dDFLSExyNzRxU
    C9mdnJ4UzErU0VKUmhWS2lSQ3dzSjZxaUEzSGk2YmlLRHlJVUhrbzlWTXg
    xNWp5dVJjYk42RGd3NjMwQw0KRVJHVUdyMVY2SC94UG1WVmZzc0tMNG9sY
    3dmblBMM3ZFWjdzMk1HYkFhaWVVZ1dpaUdlTFhGclV6aDJUUHVaK2F1a1By
    V0tvWXNBVg0KRDR5eHROSmFsQmhmZk44VHIrQ0tteDB3QTkwdFRzdis3L2Q
    vLzlXT2dZNW1GdEw5Z1FKeG5CVEJZQTBpb29CdllWNjU4ZVgrcDVZYw0KUEg
    ycVlWcDZjZUhHZVhlMXVrSHRibWw3dHZHRnRwY212VERZU2JHK2xrQllFWGV
    GSmpmRURkRVozTnAwTjlQcmx4SUtWWmROWFlvcw0KRWNGZ3hNZDNpbExNRkt
    YWTMrdjJIdHRhM0xyNXA0TS9mYVZyU2RjZWVpbXB4U3ZBQW1EeWUvZUluU3Nj
    V3BSeE4vOTJoM1JjdWpGNw0KaUlOZEhjUTZJMXdSbTMzaXMzTStsYjJvZW5aO
    WEvclVoTlhIMTdHMmN6TnRFWTFiR1NPWXpUSHBSSUt2MWwvSDMxNy9JeWJXej
    MvWA0Kd1lmTUVEMStUMXZTSlBjTWVBT3YzM1Q4cHRVc3BQV2Qyb2dwbVVkcDl
    VNnF4VlJMVjkrVHpqWFBlQ1haeXFZaFE3OVRpeGNaNUdUeQ0KcjlNemJwZjdO
    N1B2VVpjMTNzZ0QxVk9wTXdIVzlMVndOSm5DSzBDRmFRS0pZTXIyRVpHekxUY
    21kQ3QwQlJXNll1WXNaczNzMXQzTA0KZnRINGl5a0h1Zzg4dDdId2FzdkptczZzVkVrQUVLVlVVVVFDZ0syVX
    lnLzNMYjVCMjlZNVBiTEU5NHFQZHZmdnlxMXRXYkZnL2VsOQ0KVlh0MUJxcyt
    qbThIQ0F3a3VNd0xjUHVrajNQTC9HOVRVOTNJamlOLzVBL2JWdENiTFBDVnhY
    ZHkyM1hmQUJYQTlYeFVPYmpHVGw0cA0KTmZJN3k5b2l2RHowOG9aMXVmV1B6NDh0T1BEMTJKZW4rT2Fv
    bjgrbnU0TEJEMVVHQTlXaXROcHhSdTdEMGpabjllWjc3aVNEK1o1Vw0KNXA2
    RDdXdnEvN1R2dDZ4SXRHT20xaEdJV1dDRTJseWVaVjZBcjMzNEI4eHErRGl1Y
    1hDY1FlS3htZGlXVmJMV09ieHhwbWNzeXpvWA0KSUdrdGRyeVY4cEtweGFId3B
    jWEMzNXYrVktNT2h1N29xYXU5N2huTDVtZEtxY3piQXZFOUY4c09ERnRtVm9haGU1VXorSVZVK21UVA0KbHBP
    djhxYzNsN1BYTWppMVZZUVFJaWZUWEplTjhOM2JIbVorMHczbGR1QjVIaURuUjlD
    eVo3VFc0d0RsblY3ZllhVmZFWHd4U0c4Yg0KdVVLT1htOGlHVy94c1NyL3pqL1B1d
    VJqcXdEaitZWHVnQjNwY3R6OEtFZXNiWUhSQVI1VzdhdnVmU1o5YmVWMWNsSDlSN2
    c5WGs5OQ0KdUpvMTdSdjRhOTlSRWxtSHlia2FMcDExTlpYUmlZZ1JqSUF4M2dWSnB
    oaURLQkEwV3BYaTNKaGRoSUl2V1dGL295WDc5cVArNEJGTg0KcDZsWjJvNS9SVWRUT3
    BiODB0RmorYW5UR205NE9od090d01FQTVGUklIZGZlemRQOEFSZmJmMktGUWxIcnF
    6WEU1WnAzMjlBUXlReQ0KZ3h2bmY1UEdpa25VSDF4UHE5UEhzdWtMdWZNVER4QUsxK0
    Q1UGlMK21aUjdkNDlvR3hHTitDNUduVVFGM2tMVU0walBxNmczRXFqbg0KcXBGSDAyaHN
    hdmNMRWFkSHRTNWEzZEFmcS81aXNUQndhbUxWNXc0QVk4cWkxMGRQdjlaNmI5MmF2d
    FZQWkx4TVJrVEVpQkZqakxpdQ0KSnlJaVRqRWh1YUZXTWI0dnJ1dUs0emp2OGVlSzd6
    dmllbzRVaTN2RnpYOUR2TnhrOGZ1YXhQL1hHZUpOcXhBZkxXTFpJb0dBQ0FHUg0KQ2JZ
    VXY2ZGsrMHZJcHMwUHlMWXRhMzlTcGtKd3ZQeXVKblJJdmJub29ZcWZYeC9WMFRnQ3hwZ1
    I4bnF1Z0tyQ0RsYmgrLzU1TXVGYw0KM05EWXRvWHY3QVQ3U2F6Z2E2aGtCdlYwRkZuUkQvd
    HlxS3hCMnphQ29FUktpU0lKZ2VXYUJRdDlObFVzSjM3UmhHKzVubHdNckNzQg0K
    dWFZMHdHZW0zOTY0VkM2L3N5cFFOY0hDd2pjR0l6TENYVUhLNGFQSzUvSWVRTmlJNUJoS1B
    ZR09QVVd3NnlUV2l5N3llQjkwV2FndQ0KRjFVd2xFZ2pxT0Z3VlNBKzBLK0lMQmNtM3BVZzd
    lNmIwckozNjhMRlM2N2VNZFlqNnFxYXF4YmNXbjNicDBJNkZNU0FKUm9MRFJxdw0KR0ZFbUp
    WQXVKc2FsVmQvM3owTjZCYVUwcUJwNmUzT283aTVxT3hUeG5TNDRUbW5HQVRWY056UEdpbUNW
    emJoRlUzK1RvYmY2S0lmeg0KRzh6aUpWZTMyandLM0FjOFM4Tmt1MkhKSmRGNTllbHNDM21kQmcxY
    VcvaEdjTk5GYXNNTFFCWElteE9ZZ0FLdEVTWDRHWitnWDB0Tg0KeFNVTXI2bmVIb3RCcVFDaDZLZVJ
    kSUVCOVRqT3ZLM3dXWitLMXl4VXJ5cFozaTRYc0dhc044dU
    FDb3A0Ti9pMUNib0t1L05BcTgxOQ0KcFlmdW4vYnRwaHAvd3VKY01jWDI5cDl4S0g2RVlsd1RzTU5r
    Y3c2cHZ4emg3ejcwQklIb0NkNXcvb3ZlU1hGMElJU0hSMkYvaXFiMA0KTmR5eDdCSENVW
    HZNaUp3emV3Tm9LOHpNR2ZlUWlNN2lWT0RmYUovekFqTW11MFNmVWVnT2pmSXBSWUpTNTdTS2xRZkpP
    UlQ5bkVJSQ0KaklUVy9OQ0hwbFdxaXJtdTc5T1pEdk5jWHdYZEVVM0VzaWttQmIwM3dxZG5LTUsyWmtk
    WGhFMURGVVRDSVhLWkxKRzlpbHR0Q05obA0KSHIwTGRVcnpNaGhmTVdIaU1xS3hScm82RjNMNGpuK2hxZH
    FqNW1tTkhBS2xoMk9aMGZBcTkrM1pZSlNOWmNLZ3hoU05sYnB5U2dXeA0KS2NZSXZrRGVVMlFjd1ZVR3R5Q0V
    QRUdNUWtUaCtEQllNTGpHSlpjejRDaDhTMTFnRmlsTlZDbUlSR2N4dGZGZUhDL053TzFQRWt6Mw0KRVR0aEk4VXk
    0ZVhzZW4wd0FpWldTVXhQTmtDdUZORy9JV2FKVlIrUlNOe
    UlqMUpnS2NGQzBNcGdLY0hXWlFzcFZmNC9LQXhhQ1phbQ0KdExLOEFDUWlnakVHei9jUWdVaGtPdlcxWDhaT
    W4wM3hjbUNoTUxJd1ZtZmsybXBEVHd4VTNSU2FabDBiQXFhVlBES1JDaEdxUWhKQw0KeEp6aFJqW
    GFrUnE5VmVxNFhNVXF4UVc3ZzFLT0tnM2xJVUJselNVNHBnNDlFV2dzcWFQSW1QRk1TYm1LbHh0T3h5QVFuMUd
    jZDlHMQ0KWFlDVVBLS0ppREZSVzJ6TVdNbWoxSmthRG13NWM3NEM4dlpyai9QUExTVXZHNitYU0NSRE1BZjBX
    bVdwSGhFN01JSWZGM28rSVF4Vw0KemNSV3N3N01iSnI3dEZKcVV3bElrSkJyUEJ0VFNuVkt4Z1RqaVBVMW5FT
    0xoUGVVRjgvSUt4WktDNE1ES3duSVNZSW5RUFlMb21VMA0KQWp3Z3FoaGFZdGdhZzhyR200bFpjLzZzbEZvK09
    qc1hYM3dqbnUrVlBUTFdGWlF6K1RnL2pkZjJjd2J5K1lJSVlvekJLUndpWFBVNA0KNFgyOVdOdENrQktVcFZBb
    3hBVWlNTGpVNWREbkJYdkNyY3ljZnN2dXE2NzhnaGFScGFNNTJjVnhYYmRZOEl0RUpUSk83Y1lpR0lZag0KWit
    FWkR1YnpkNDFDZ2JKUU9NQitMUDBvMGNIVDZKVStiQmxqR0s5a3JNeGlsOE0zYW81VlRTczIxRnl6cm1IUzBwVjJn
    QTdmYzdPag0KUUFiSkY5ekNVTllib2xZaTU3U2NxT0hCVmZsOExBZ3VHQWhLWVZtYVl2NG9SZWRQS1ArUHhIWlZv
    TlpxT09taXJISlcxMUM0ek9mWQ0KOVlxMitYT0poeitUV2JENFN3L1gxVTNaT2lJYXZsOE9yWlZrOGs2K0wrTm5
    DbEtXQ1ZIanc2ZTBxYVBLZitNNU1qSjlVUmNRVktWVw0KaGR3QkVvbHRkSGRH
    R2RpZXhxRUkwVksxYTBKQ29VazRjaSswWHpHSFdPVHpjdFVuZnRCcnhlS0pzWDFweXlwNzVDV0txVytuVDZmYw0
    KZEo4eDBtalUrTWlSOGdTVk9wc3BvOEF1a0I4aStMNVB2T1oyd2hWWGtrbTlSdXZOdnlSeXhVRWFOeGlxWHdVbnB
    0ajJCWTlUOWx3Vw0KTmZ5WWhuazM5ZjZoOWJGMXN5Tk5mVys3OTNzODEzcHF0anY3aFBIOXhwTHR6MVh1amVyQXFE
    ZkdGdlRxQXJFWUlJQnRUNk95NXRQTQ0KcTFwSTk2bEhPUlZZUmZ2Q0JMMHBHR3k0aHFXWC9vQ21tWitrYUJtM0psalZ
    tZkw2ODJjREtTZWNsYW1WYllzbkxXa3BaQXZYS0pGUg0KR1ZhajVjN0lUNDJ2R0laejR2aGE0bnpCZUlEQ3Rtc0kyel
    hJNVB2SlZNMW1jTjVCcWpLVlRBdmV3TVd6YnlGQnByZy9zWHVmTytSdQ0KU3hmVExzQ1Juc1BNblRTdkJDU2lJdVRKd3l
    ZNk9pL3FibTRiYUhORS9LQkdBMlprMjFPcDBTV1ZFaGtEWWt4MmZ4KzVSTVRGZGFHcQ0KY2o2VnNYazRUZ29taHdpRktrc
    2I5c21ESjM1MTdGZXZyUG5vbWwzRHJZWkJBT2c4WlMrdFlLZzkwN0gvK2ZUcVRhNXhQWTAxVG9ORw0KOW5HRk0wVzRMRnJ
    2S3l1T1pIaGpmSlJsRTRwTUhBRUJVSFNMYjYwNXNtYjd5STN2ajI4Nzd0WFNCdi8xMXViODl1Y0tKbGNZMXFpeA0KdEM
    4SDNBaURwRXhhUldrUjlINmhpQWkrYi9BOHI3VGFMR2ZubG5STFgydXlkVHYzMHZKMmJVdEFmbFRla1grQlZKTlR1MH
    RCVWNZVw0KVm1lc2JXUmM1VmlxSk5VSDRKR3pFeWJrL0J6ck85ZS8vTVBtSC80VnlJNDg4UEM1Z1B5OGZMVzh4NytqZG
    xtdkpaWTdxa2ZEU3FYSw0KWEI0dnYyYzg5Y0dBVUFwdGxYWWZOeWMySDlqWXZuRjE2anVwbHJNVDF4bEFSRWJWeks0ZUtsZ0VEQVlRTTF
    MNURuTkRDZWl4d2FVbw0KN3hhKzQwTDlna0JZbG9VdlBrY0hqM1k5ZHZpeGxjMjdtMThuemVBN2xYUzYxSGkwTEp
    sYWMyVmx4SW9xYlVwYm1xalNnc29EZkZHQQ0KUnFQS3V5VmxnaXJCRlI4eC9nY0NBdUQ0MFBIVER6US84T3lxRG
    F1ZVRqeVk2QVlJWEJ3NHY3ZTZJcklnbisvL1JXdlBsZ2k1NHlMYQ0KS0FtVWtJVERDdDk0R0UvUVltRk1DWWpXbW
    9KeHlMaFp4aS9wNUx3bWZpNFFCd2NPSnBZZlc3NTIzY1oxdnlOTjI1aU9lV2V5ang1dA0KdnAvL3plUm9nMWZsQ
    jVWeWl5TlBHVnRUY0lwb3NZallNY1F6cFRDek5NV1FJU1VESkpQZEtDVm9yUzRJaEtXdEVSRFBkejkvN05jdA0K
    djM1czFkWlZ2K1BYdk1sLzR3N0xyZnVXKzg1QWhqbWlsTW9Xc3dkZm16RmhVY2NVQWlibUZQRXhLRnRSaU5paz
    h3T0VxV0JLOUNMRQ0KZFVzYzBpRFZVWHJzREh2ZjJvREN4dExXT0lVYjNhelVLS1ZIa3FpbExXemJScFJ3UEh0
    ODZNV1RMMjUvNnVCVHYzMWt3eU1yam4zbg0KV0ROSkNpTUVmL2c4M3JPUDVVamRwSThhL01MZUQwZFhOQjBiN0
    sxNk0rOFF0aXpjeWpDbk1tMnlzSDZoMTFEUkpPSFR6VUUvR2tMWg0KTmxaZEZiMWVnWmM3MXJFd3NZeXBFK1lTc0
    NLSWdERlM1cWRCYVZPcXFwVU5DSzY0RERsRCtWTkRwenEzbnQ3YS9KTnRQM2t1dVRLNQ0KaVExMG51azllWmRRdGM
    2ODhlQ0QvNnpSa2RwdzhlVEYzZG5VcE1PWlBpUm9ZNHdpMXArVzZmR0dUTHk2Tm4ra2EwY3NhUVVRUzJPSA0KQXhRR
    FBqMU9MNVdGMDB5STF4Q054UkRsWXNoalZBNWpaY2k3UFRqT0VJRkF0UWhHZGVXNnVsZTFyZHJ5M2RlKysrVHkvMWo
    rZVA2WA0KK2MyMGtnYmdJWmo0bDRuRWlKRWRrejR1QU1pREJqZzZlY0lpMXhvNlBzMUx0MC9hMHpkSXFDNUdJdFdtcHV
    yNjhMSUpsNFZtVlVmYw0KMTF2MzJCa2xXS0VRd1VnUXB6TENQa2x4S0xlSmpzSmErdFI2RXZhTEhKUFY3QTY4eWt2N
    y80ZTl6YzF1MWNTTCs0eHk1WVh0Ni8vcA0KL3EvZC81L0YvdUl1YjZmWHpTQ2pKTGdCWW4rSkFaQWpkLzZmY0JnUmxB
    dXF0T2dZRkpIc1IrZDkzWjlZdmNERURqeDZlbXQvVzhPYg0KUWMwei9WdFV4WEh0Zlc3SkZ6UC9vT05WSzQ0K0g5
    cloxa2s2WGtXME5vWVRFSm9SMm9vK2xSbVhRRnJJSjdNVU9udG9TTmh5MDZTUA0KT0RNcXB5Y0hzcW1IRXIySl
    p6bEFWLzVBL294SjJYZy85a2lRZUcvdjJZMFpWUnpQeUNXVzRwTjRnN2UySjNaZXZyTm5kMkJiY2xkMA0KLzh
    sRFZCUUNmSEhPSi8yUE5GMWJQSkh2ais1SnZzSGVWQXZIc2dQa1ZSRFBzakZhWTZPcE5qWXp3OVhNQ2M2bEtU
    d2pjWEh0bk0yTA0KRjF5L0tuMGlzNkZtUnRWWkgrRWtrMG5xNnVyZTN5Y2N3MkJLbnlvcHNvTjlzV2hGM1czQ
    TNTQzlIYWQrdjJqdHNWMVRkcHg2eTJrdw0KcWNHdlhQTXYrWGxOTnh1L3NDZTY4OWlhQ1Z0UGRGVzlsZXc3blRWRkw2Q0M0VW9WaWt5eFF0NlNob2
    50eXhiZmxZNVVYOW9LUEsrVQ0KZW5GMFBEOE9LbHorQnF5Z3RaYjNWcHU5eTFGMGM3WkdoYlFLRzIxeEwza
    VhRbGVubU80OStXTGxYTnVhZWlRWXJLd0ZiZ2F6R0pLLw0KeDA4UFlDSXpzU3Rub1NwendGcGdKOURyNUlm
    Y1VEVHVsa0E0R3V3bHdIUmdQOUNxdFg1UDVjSC9BckF3UTVvUnBjbWNBQUFBQUVsRg0KVGtTdVFtQ0M=`,
  mimeType: 'image/png',
};
