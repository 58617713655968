<ion-card style="margin: 5px">
  <ion-card-content>
    <ion-badge
      *ngIf="isEditing"
      color="success"
      style="position: absolute; right: 5px; top: 5px"
      >{{ book?.quantity }}
    </ion-badge>

    <div>
      <ion-img
        style="margin-bottom: 5px; width: 111px; height: 146px"
        [src]="book.moreInfo?.imageLinks?.smallThumbnail"
      ></ion-img>
    </div>

    <p
      class="ion-text-center"
      [ngStyle]="
        isBigDevice
          ? { fontWeight: 'bold', color: '#5a5a5a' }
          : {
              fontWeight: 'bold',
              color: '#5a5a5a',
              fontSize: '13px'
            }
      "
    >
      {{
        book.title.length > 15
          ? (book.title | titlecase | slice : 0 : 15) + ' ...'
          : (book.title | titlecase)
      }}

      <br />
      <span style="font-size: 11px; font-style: italic">{{
        book?.authorName | titlecase
      }}</span>

      <!--              Description: {{book?.description}}-->
    </p>

    <p
      style="
        font-size: 15px;
        margin-bottom: 2px;
        font-weight: bold;
        color: #5a5a5a;
      "
      class="ion-text-center"
    >
      R {{ book.price }}
    </p>

    <ion-segment class="ion-no-margin ion-text-center">
      <ion-segment-button
        class="ion-padding-end"
        (click)="openItem.emit(book.id)"
        style="
          min-width: 50px;
          --indicator-color: transparent !important;
          --color-checked: clear;
        "
      >
        <ion-button
          expand="block"
          color="clear"
          *ngIf="isBigDevice"
          style="color: black"
        >
          <ion-icon
            [name]="isEditing ? 'archive-outline' : 'eye'"
            slot="start"
            [color]="isEditing ? 'danger' : 'primary'"
          ></ion-icon>
          view
        </ion-button>

        <ion-icon
          *ngIf="!isBigDevice"
          [name]="isEditing ? 'archive-outline' : 'eye'"
          color="primary"
        ></ion-icon>
      </ion-segment-button>
      <ion-segment-button
        (click)="eventFromBookCard.emit(book)"
        style="
          min-width: 50px;
          --indicator-color: transparent !important;
          --color-checked: clear;
        "
      >
        <ion-button
          expand="block"
          color="clear"
          *ngIf="isBigDevice"
          style="color: black"
        >
          <ion-icon
            [name]="isEditing ? 'create-outline' : 'cart'"
            [color]="isEditing ? 'primary' : 'success'"
            slot="start"
          ></ion-icon>
          {{ isEditing ? 'edit' : 'Add to cart' }}
        </ion-button>

        <ion-icon
          *ngIf="!isBigDevice"
          [name]="isEditing ? 'create-outline' : 'cart'"
          [color]="isEditing ? 'primary' : 'success'"
        ></ion-icon>
      </ion-segment-button>
    </ion-segment>
  </ion-card-content>
</ion-card>
