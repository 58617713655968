<ion-header>
  <ion-toolbar>
    <ion-title>
      <ion-menu-toggle auto-hide="false"> Menu</ion-menu-toggle>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content [fullscreen]="true">
  <ion-list class="ion-no-padding">
    <ion-menu-toggle auto-hide="false">
      <ion-item
        *ngFor="let p of normalPages"
        [routerDirection]="'root'"
        [routerLink]="p.link && [p.link]"
        routerLinkActive="active"
        (click)="goTo(p.event)"
        [style]="p.styles"
      >
        <ion-icon [name]="p.icon" slot="start"></ion-icon>
        <ion-label>
          {{ p.title }}
        </ion-label>
      </ion-item>

      <div *ngIf="isAnonymous">
        <ion-item
          *ngFor="let p of anonPages"
          [routerDirection]="'root'"
          [routerLink]="p.link && [p.link]"
          routerLinkActive="active"
          (click)="goTo(p.event)"
          [style]="p.styles"
        >
          <ion-icon [name]="p.icon" slot="start"></ion-icon>
          <ion-label>
            {{ p.title }}
          </ion-label>
        </ion-item>
      </div>
      <div *ngIf="!isAnonymous">
        <ion-item
          *ngFor="let p of loggedInPages"
          [routerDirection]="'root'"
          [routerLink]="p.link && [p.link]"
          routerLinkActive="active"
          (click)="goTo(p.event)"
          [style]="p.styles"
        >
          <ion-icon [name]="p.icon" slot="start"></ion-icon>
          <ion-label>
            {{ p.title }}
          </ion-label>
        </ion-item>
      </div>
      <div *ngIf="isAdmin">
        <ion-item
          *ngFor="let p of adminPages"
          [routerDirection]="'root'"
          [routerLink]="p.link && [p.link]"
          routerLinkActive="active"
          (click)="goTo(p.event)"
          [style]="p.styles"
        >
          <ion-icon [name]="p.icon" slot="start"></ion-icon>
          <ion-label>
            {{ p.title }}
          </ion-label>
        </ion-item>
      </div>
    </ion-menu-toggle>

    <ion-item class="ion-text-end" lines="none">
      version: {{ version }}
    </ion-item>

    <ion-item>Powered by {{ powerName }}</ion-item>
  </ion-list>
</ion-content>
