import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { Auth, authState } from '@angular/fire/auth';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AnonGuard {
  constructor(
    private authService: Auth,
    private router: Router,
    public toastCtrl: ToastController
  ) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | Observable<boolean> | Promise<boolean> {
    return authState(this.authService).pipe(
      switchMap((cu) => {
        if (!cu) {
          this.router
            .navigate(['home'], {
              queryParams: { redirectUrl: segments[0] },
              replaceUrl: true,
            })
            .catch();
          return of(false);
        } else if (cu.isAnonymous) {
          this.router
            .navigate(['login'], {
              queryParams: {
                redirectUrl: segments[0],
                segments: segments.map((s) => s.path),
              },
              replaceUrl: true,
            })
            .catch();
          return of(false);
        } else {
          return of(true);
        }
      })
    );

    // return authState(this.auth).pipe(
    //   map(res => {
    //     console.log({ res });

    // if (res === null) {
    //   this.router
    //     .navigate(["home"], {
    //       queryParams: { redirectUrl: segments[0] },
    //       replaceUrl: true
    //     })
    //     .catch();
    //   return false;
    // } else if (res.isAnonymous) {
    //   this.router
    //     .navigate(["login"], {
    //       queryParams: {
    //         redirectUrl: segments[0],
    //         segments: segments.map(s => s.path)
    //       },
    //       replaceUrl: true
    //     })
    //     .catch();
    //   return false;
    // } else {
    //   console.log({ route });
    //   console.log({ segments });
    //   return true;
    // }
    // })
    // );
  }
}
