import {
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  animate,
  animateChild,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

@Component({
  selector: 'radium-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
  animations: [
    // the fade-in/fade-out animation.
    trigger('fadeOut', [
      transition(':leave', [
        query(':leave', animateChild(), { optional: true }),
        animate(300, style({ opacity: 0 })),
      ]),
    ]),
  ],
  styles: [
    `
      .splash-screen {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9999;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SplashScreenComponent implements OnInit {
  show = true;
  @Input() backgroundColor = 'white';
  @Input() backgroundSize = '100% 100%';
  @Input() margin = '0';
  @Input() img?: string;
  @Input() img2?: string;
  @Input() otherClass = 'none';
  @Input() duration = 5000;
  @Output() done = new EventEmitter();

  constructor(private cdr: ChangeDetectorRef, private appRef: ApplicationRef) {}

  ngOnInit() {
    // this.pwaService.checkForUpdate().subscribe(result => {
    //     //
    //     // });
    setTimeout(() => {
      this.show = false;
      this.done.emit(true);
      this.cdr.detectChanges();
    }, this.duration);
  }
}
