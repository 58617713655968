import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

export interface MenuItem {
  icon: string;
  title: string;
  link?: string;
  showWhen?: string;
  event?: string;
  styles?: string;
}

@Component({
  selector: 'radium-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnChanges {
  @Input() appPages: MenuItem[];
  @Input() isAnonymous: boolean;
  @Input() uid: string;
  @Input() isAdmin: boolean;
  @Input() version: string;
  @Input() powerName = 'Admineze';

  @Output() go = new EventEmitter();

  anonPages: MenuItem[] = [];
  normalPages: MenuItem[] = [];
  adminPages: MenuItem[] = [];
  loggedInPages: MenuItem[] = [];

  constructor(public cd: ChangeDetectorRef) {}

  ngOnInit() {
    console.log('appPages', this.appPages);
    this.anonPages = this.appPages.filter(
      (page) => page.showWhen === 'isAnonymous'
    );
    this.normalPages = this.appPages.filter((page) => !page.showWhen);
    this.loggedInPages = this.appPages.filter(
      (page) => page.showWhen === 'isLoggedIn'
    );

    this.adminPages = this.appPages.filter(
      (page) => page.showWhen === 'isAdmin'
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appPages) {
      this.normalPages = this.appPages.filter((page) => !page.showWhen);
      this.cd.detectChanges();
    }
  }

  goTo(contact: string) {
    // this.menuCtrl.close().catch();
    this.go.emit(contact);
  }
}
