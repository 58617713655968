import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import {
  AmountPipe,
  DateAgoPipe,
  ObjectKeysPipe,
  PricePipe,
  SpaceSeparatedPipe,
} from './pipes';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [
    DateAgoPipe,
    PricePipe,
    AmountPipe,
    ObjectKeysPipe,
    SpaceSeparatedPipe,
  ],
  exports: [
    DateAgoPipe,
    PricePipe,
    AmountPipe,
    ObjectKeysPipe,
    SpaceSeparatedPipe,
  ],
})
export class PipesModule {}
