import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PickerController } from '@ionic/angular';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'radium-duration-picker',
  templateUrl: './duration-picker.component.html',
  styleUrls: ['./duration-picker.component.css'],
})
export class DurationPickerComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  hours: number[] = Array.from(Array(25), (_, i) => i);
  minutes: number[] = Array.from(Array(4), (_, i) => i * 15);

  pickerColumns = [
    {
      name: 'Hours',
      options: this.hours.map((h) => ({
        text: `${h} h`,
        value: h,
      })),
    },
    {
      name: 'Minutes',
      options: this.minutes.map((m) => ({ text: `${m} m`, value: m })),
    },
  ];

  constructor(
    private pickerCtrl: PickerController,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.formControl.setValue(this.formControl.value ?? '0 h 0 m');
  }

  async openPicker() {
    const picker = await this.pickerCtrl.create({
      columns: this.pickerColumns,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Done',
          handler: (value) =>
            this.handlePickerDismiss(value.Hours, value.Minutes),
        },
      ],
    });

    await picker.present();
  }

  handlePickerDismiss(hours, minutes) {
    if (hours >= 24) {
      this.formControl.setValue('24 h 0 m');
    } else {
      this.formControl.setValue(`${hours.text} ${minutes.text}`);
    }
    this.cd?.detectChanges();
  }
}
