<!--  [ngStyle]="{ display: !isBigDevice ? 'inline-flex' : null }" -->
<div class="file" style="margin-top: 10px">
  <div
    style="margin: auto; height: 200px; width: 100%"
    [ngStyle]="{ border: isBigDevice ? 'none' : '1px solid' }"
  >
    <img
      *ngIf="url"
      alt="image"
      style="height: 100%; width: 100%"
      [src]="previewUrl"
    />
  </div>

  <ion-button
    expand="block"
    style="margin-top: 50px"
    class="ion-margin"
    *ngIf="!url"
  >
    <input
      type="file"
      id="file"
      name="file"
      class="file-input"
      (change)="onDrop($any($event?.target))"
      accept="image/*"
      [ngClass]="!isBigDevice ? 'smallButtonClass' : ''"
    />

    <ion-icon name="camera" class="ion-padding-end"></ion-icon>
    <label for="file">
      {{ isBigDevice ? 'or choose a picture…' : 'Choose a picture…' }}</label
    >
  </ion-button>

  <ion-button
    *ngIf="url && showCancelButton"
    class="ion-margin"
    color="warning"
    expand="block"
    style="margin-left: auto; margin-right: auto"
    (click)="actionTheSelectFile('cancelAddingPicture')"
    >{{ isBigDevice ? 'cancel' : 'cancel adding this picture' }}
  </ion-button>
  <!--  (click)="actionTheSelectFile('enablePreview')"-->
</div>
