<ion-toolbar>
  <ion-title>{{ isNew ? 'Add Address' : 'Edit Address' }}</ion-title>

  <ion-buttons slot="end">
    <ion-button size="small" color="danger" (click)="closeModal()">
      <ion-icon name="close"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>

<ion-content>
  <div [formGroup]="$any(address)">
    <ion-list style="text-align: center; padding-bottom: 0; padding-top: 0">
      <ion-radio-group
        formControlName="addressType"
        name="addressType"
        value="business"
      >
        <ion-button
          fill="clear"
          (click)="address?.get('addressType')?.setValue('residence')"
        >
          <ion-label class="ion-padding-start" slot="end">Residence</ion-label>
          <ion-radio value="residence"></ion-radio>
        </ion-button>
        <ion-button
          fill="clear"
          (click)="address?.get('addressType')?.setValue('business')"
        >
          <ion-label class="ion-padding-start" slot="end">Business</ion-label>
          <ion-radio value="business"></ion-radio>
        </ion-button>
      </ion-radio-group>
    </ion-list>

    <ion-item>
      <!--            <input-->
      <!--              type="text"-->
      <!--              radiumGooglePlace-->
      <!--              class="google-place-input"-->
      <!--              (selectHere)="setAddress($event)"-->
      <!--              placeholder="Type to search..."-->
      <!--              formControlName="streetAddress"-->
      <!--              [class.invalid]="-->
      <!--                !address?.get('streetAddress')?.valid &&-->
      <!--                address?.get('streetAddress')?.dirty-->
      <!--              "-->
      <!--            />-->

      <input
        #placesRef="ngx-places"
        ngx-gp-autocomplete
        formControlName="streetAddress"
        [options]="{
          componentRestrictions: { country: 'za' },
          fields: ['address_components', 'geometry', 'icon', 'name'],
          strictBounds: false,
          types: ['address']
        }"
        (onAddressChange)="getFormattedAddress($event)"
        [class.invalid]="
          !address?.get('streetAddress')?.valid &&
          address?.get('streetAddress')?.dirty
        "
        placeholder="Type to search..."
        style="
          min-width: 100%;
          background: transparent;
          height: 37px;
          padding: 7px;
        "
      />

      <radium-form-error-display
        [control]="$any(address)?.get('streetAddress')"
      ></radium-form-error-display>
    </ion-item>

    <ion-item>
      <ion-label position="floating"> Complex / Building (Optional)</ion-label>
      <ion-input formControlName="complex" type="text"></ion-input>
    </ion-item>
    <ion-item>
      <ion-label position="floating">
        Suburb

        <ion-text color="danger">*</ion-text>
      </ion-label>
      <ion-input required formControlName="suburb" type="text"></ion-input>
      <radium-form-error-display
        [control]="$any(address)?.get('suburb')"
      ></radium-form-error-display>
    </ion-item>
    <ion-item>
      <ion-label position="floating">
        City / Town
        <ion-text color="danger">*</ion-text>
      </ion-label>
      <ion-input required formControlName="city" type="text"></ion-input>
      <radium-form-error-display
        [control]="$any(address).get('city')"
      ></radium-form-error-display>
    </ion-item>
    <ion-item>
      <ion-label position="floating">
        Province
        <ion-text color="danger">*</ion-text>
      </ion-label>
      <ion-input required formControlName="province" type="text"></ion-input>
      <radium-form-error-display
        [control]="$any(address)?.get('province')"
      ></radium-form-error-display>
    </ion-item>
    <ion-item>
      <ion-label position="floating">
        Postal Code
        <ion-text color="danger">*</ion-text>
      </ion-label>
      <ion-input required formControlName="postalCode" type="text"></ion-input>
      <radium-form-error-display
        [control]="$any(address)?.get('postalCode')"
      ></radium-form-error-display>
    </ion-item>
  </div>

  <ion-row>
    <ion-col>
      <ion-button expand="block" color="danger" (click)="closeModal()">
        cancel
      </ion-button>
    </ion-col>
    <ion-col>
      <ion-button
        color="primary"
        [disabled]="address?.invalid"
        (click)="closeModal(true)"
        expand="block"
      >
        {{ isNew ? 'Add ' : 'Update ' }} Address
      </ion-button>
    </ion-col>
  </ion-row>
</ion-content>
