<ion-text slot="start"> Uploading ...</ion-text>
<div *ngIf="percentage | async as pct">
  <ion-label>
    <ion-progress-bar [value]="pct.progress / 100"></ion-progress-bar>
    <ion-text slot="end">{{ pct.progress | number : '.1-2' }}%</ion-text>
  </ion-label>
</div>

<div *ngIf="snapshot | async as snap">
  <p class="ion-text-center">
    {{ snap?.bytesTransferred / 1000 | number : '2.1-2' }} KB of
    {{ snap?.totalBytes / 1000 | number : '2.1-2' }} KB
  </p>

  <ion-row>
    <ion-col>
      <ion-button
        expand="full"
        color="secondary"
        (click)="task?.pause()"
        [disabled]="!isActive(snap)"
        >Pause
      </ion-button>
    </ion-col>
    <ion-col>
      <ion-button
        expand="full"
        color="danger"
        (click)="cancel($any(task))"
        [disabled]="!isActive(snap)"
        >Cancel
      </ion-button>
    </ion-col>
    <ion-col>
      <ion-button
        expand="full"
        (click)="task?.resume()"
        [disabled]="!(snap?.state === 'paused')"
      >
        Resume
      </ion-button>
    </ion-col>
  </ion-row>
</div>
