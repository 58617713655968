import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { TableConfig } from '../../modals';

@Component({
  selector: 'radium-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() tableConfig: TableConfig;
  @Input() isLoading = false;

  @Output() tableEvent = new EventEmitter<any>();

  displayedColumns!: string[];
  tableDataSource = new MatTableDataSource<any>([]);
  private dataChangeSub!: Subscription;

  constructor() {}

  ngOnInit() {
    this.isLoading = true;
    setTimeout(async () => {
      this.tableConfig?.columns.push(
        {
          type: 'text',
          heading: 'Created At',
          key: 'createdAt',
          numeric: false,
          timestamp: true,
        },
        {
          type: 'text',
          heading: 'Updated At',
          key: 'updatedAt',
          numeric: false,
          timestamp: true,
        }
      );

      this.displayedColumns = this.tableConfig?.columns?.map(
        (col_config) => col_config.key
      );
      this.displayedColumns?.push('actions');
      this.dataChangeSub = this.tableConfig.sourceData$?.subscribe((data) => {
        console.log({ data });
        this.tableDataSource.data = data;
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
      });
      this.isLoading = false;
    }, 3000);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.tableDataSource.filter = filterValue.trim().toLowerCase();

    if (this.tableDataSource.paginator) {
      this.tableDataSource.paginator.firstPage();
    }
  }

  doAction(type: 'edit' | 'delete' | 'add' | 'view', tableData?: any) {
    this.tableEvent.emit({ type, tableData });
  }

  ngOnDestroy(): void {
    if (!!this.dataChangeSub) {
      this.dataChangeSub.unsubscribe();
    }
  }
}
