import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../../../../utils';
import { Subscription } from 'rxjs';

@Component({
  selector: 'radium-user-basic-info',
  templateUrl: './user-basic-info.component.html',
  styleUrls: ['./user-basic-info.component.scss'],
})
export class UserBasicInfoComponent implements OnInit, OnDestroy {
  @Input() header = 'Basic Info';
  @Input() includeDisplayName = true;
  @Input() isOrg = false;
  userForm?: FormGroup;
  sub?: Subscription;
  sub1?: Subscription;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.userForm = this.fb.group({
      email: [
        '',
        Validators.compose([Validators.required, CustomValidators.email]),
      ],
      contactNumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(20),
          Validators.minLength(4),
        ]),
      ],
      whatsappNumber: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(20),
          Validators.minLength(4),
        ]),
      ],
      ...(this.includeDisplayName
        ? {
            displayName: ['', Validators.required],
          }
        : {}),
    });

    this.sub = this.userForm
      ?.get('contactNumber')
      ?.valueChanges.subscribe((x: string) => {
        let newX = x;

        if (x !== undefined && x?.substr(0, 3) !== '+27') {
          newX = '+27' + x;
          this.userForm?.get('contactNumber')?.setValue(newX);
        }

        if (this.userForm?.get('whatsappNumber')?.pristine) {
          this.userForm?.get('whatsappNumber')?.setValue(newX);
        }
      });

    this.sub1 = this.userForm
      ?.get('whatsappNumber')
      ?.valueChanges.subscribe((x: string) => {
        let newX = x;

        if (x !== undefined && x?.substr(0, 3) !== '+27') {
          newX = '+27' + x;
          this.userForm?.get('whatsappNumber')?.setValue(newX);
        }
      });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.sub1) {
      this.sub1.unsubscribe();
    }
  }
}
