<header>Available formats:</header>

<mat-selection-list
  #selectedFormats
  (selectionChange)="onSelectionChange($event)"
>
  <mat-list-option
    *ngFor="let format of formatsAvailable"
    [selected]="isEnabled(format)"
    [value]="format"
    [checkboxPosition]="'before'"
  >
    {{ formatNames[format] }}
  </mat-list-option>
</mat-selection-list>

<mat-dialog-actions>
  <button (click)="close()" color="primary" mat-flat-button>Done</button>
</mat-dialog-actions>
