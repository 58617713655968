<h2 class="ion-text-center" style="margin: 2px">
  {{ header }}
</h2>

<form novalidate [formGroup]="$any(userForm)">
  <ion-item *ngIf="includeDisplayName">
    <ion-label position="floating">FULL NAME:</ion-label>
    <ion-input formControlName="displayName"></ion-input>
    <radium-form-error-display
      [control]="$any(userForm).controls['displayName']"
    ></radium-form-error-display>
  </ion-item>
  <ion-item>
    <ion-label style="max-width: 40px">
      <ion-icon size="large" name="call-outline"></ion-icon>
    </ion-label>

    <ion-input
      formControlName="contactNumber"
      [maxlength]="20"
      type="tel"
    ></ion-input>
  </ion-item>
  <radium-form-error-display
    [control]="$any(userForm)?.controls['contactNumber']"
  ></radium-form-error-display>

  <ion-item>
    <ion-label style="max-width: 40px">
      <ion-icon size="large" name="logo-whatsapp"></ion-icon>
    </ion-label>

    <ion-input
      formControlName="whatsappNumber"
      [maxlength]="20"
      type="tel"
    ></ion-input>
  </ion-item>
  <radium-form-error-display
    [control]="$any(userForm)?.controls['whatsappNumber']"
  ></radium-form-error-display>

  <ion-item>
    <ion-label position="floating">EMAIL:</ion-label>
    <ion-input formControlName="email"></ion-input>
    <radium-form-error-display
      [control]="$any(userForm)?.controls['email']"
    ></radium-form-error-display>
  </ion-item>
</form>
