<ion-card>
  <ion-card-content>
    <p>
      <span
        class="ion-text-end ion-no-padding ion-no-margin"
        style="max-width: 100px; font-size: 11px"
      >
        {{ order?.createdAt?.seconds * 1000 | date : 'MM-dd-yyyy' }}
      </span>

      <ion-badge
        *ngIf="order?.isPile"
        class="ion-margin-horizontal"
        color="danger"
        style="font-size: 14px; float: right; margin: 0 0 0 10px"
      >
        pile
      </ion-badge>

      <ion-badge
        class="ion-margin-horizontal"
        slot="end"
        style="font-size: 14px; float: right; margin: 0"
      >
        ID: {{ order?.['id'] }}
      </ion-badge>
    </p>

    <ion-item lines="none">
      <ion-label> {{ order?.recipientName }}</ion-label>
      <ion-buttons class="ion-no-margin" slot="end">
        <ion-button (click)="orderChanged.emit(order)" fill="outline"
          >View
        </ion-button>
      </ion-buttons>
    </ion-item>

    <!--            <ion-item lines="none">-->
    <!--              <ion-label> Delivery Method: {{order.deliveryMethod | uppercase}} </ion-label>-->
    <!--            </ion-item>-->
  </ion-card-content>
</ion-card>
