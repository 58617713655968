import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ObjectKeys' })
export class ObjectKeysPipe implements PipeTransform {
  transform(value: Record<string, unknown> | any[]) {
    if (value !== null && value !== undefined) {
      return Object.keys(value);
    } else {
      return [];
    }
  }
}
