<mat-form-field
  appearance="fill"
  style="width: 80%; position: relative; left: 20%"
>
  <ion-note>{{ date }}</ion-note>
  <input
    matInput
    hidden
    [matDatepicker]="dp3"
    disabled
    [(ngModel)]="date"
    [ngModelOptions]="{ standalone: true, updateOn: 'change' }"
    (dateChange)="setDate($event)"
  />
  <mat-datepicker-toggle matIconSuffix [for]="dp3"></mat-datepicker-toggle>
  <mat-datepicker #dp3 disabled="false"></mat-datepicker>
</mat-form-field>
