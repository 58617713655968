<ion-header>
  <ion-toolbar>
    <ion-title>
      {{ title }}
    </ion-title>
    <ion-buttons *ngIf="canDismiss" slot="end">
      <ion-button (click)="close('Cross click')">
        <ion-icon color="danger" slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <form *ngIf="show" [formGroup]="form">
    <formly-form [form]="form" [fields]="_fields" [model]="model"></formly-form>
  </form>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot="end" class="ion-margin-end">
      <ion-button
        [disabled]="!canDismiss"
        (click)="close('Close click')"
        class="ion-margin-end"
      >
        Close
      </ion-button>
      <ion-button
        *ngIf="show"
        [disabled]="form?.invalid"
        color="primary"
        (click)="save()"
        fill="solid"
        >Save
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
