import { Address, BankDetails, Image } from '@radium/ui';
import { Timestamp } from '@angular/fire/firestore';

export interface Org {
  uid: string;
  owners: string[];
  orgName?: string;
  webName?: string;
  orgType?: string;
  websiteActive: boolean;
  orgNumber?: string;
  vatNumber?: string;
  description?: string;
  subTitle?: string;
  defaultUrl?: string;
  avatar?: string;
  email: string;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  disabled?: boolean;
  contactPersonName?: string;
  contactNumber?: string;
  whatsappNumber?: string;
  images?: Image[];
  addresses?: Address[];
  infoComplete?: boolean;
  bankDetails?: BankDetails;
  dueDate?: boolean;
}

export interface OrgFinanceDetails {
  bankName?: string;
  accountNumber?: number;
  branchCode?: number;
  branchName?: string;
  accountHolderName?: string;
  accountType?: string;
  vatNumber?: string;
  companyRegistrationNumber?: string;
  vatRate?: number;
  financialYearEndDate?: string;
  deleteBankReconDoneDays?: number;
  periodShownOnStatement?: string;
  numberOfStatementMonthsShown?: number;
  memberRegistrationFee?: number;
  chargeRegistrationFeePerAccount?: boolean;
  ageAnalysisOnStatement?: boolean;
  allowSelectMultipleServicesOnRegistration?: boolean;
  sendWelcomeEmailToParent?: boolean;
  showRegistration?: boolean;
  statementType?: string;
}

export interface OrgAccount {
  uid?: string;
  accountNumber?: number;
  active?: boolean;
  name?: string;
  description?: string;
  images?: Image[];
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  billedUntil?: Timestamp;
  balance?: number;
  tcAccepted?: boolean;
  discount?: number;
  discountType?: string;
  email?: string;
  comment?: string;
  vatNumber?: string;
  companyRegistrationNumber?: string;
  exposureToUs?: string;
  nextOfKinName?: string;
  nextOfKinCellNumber?: string;
  cellNumber?: string;
  sponsor?: boolean;
}

export interface OrgDeposit {
  uid: string;
  accountUid: string;
  amount: number;
  createdAt?: Timestamp;
  description?: string;
}

export interface OrgCorrespondence {
  uid: string;
  accountUid: string;
  createdAt?: Timestamp;
  description?: string;
  name: string;
  surname: string;
  recipientEmail: string;
  subject: string;
  message: string;
  attachments?: string[];
  status: string;
  error?: string;
}

export interface OrgRegistrationField {
  // canDelete: boolean;
  // canEdit: boolean;
  details?: string;
  label: string;
  description: string;
  state: 'Required' | 'Optional' | 'Hidden';
  multiple?: boolean;
  type:
    | 'input'
    | 'number'
    | 'textarea'
    | 'checkbox'
    | 'radio'
    | 'select'
    | 'datetime'
    | 'date'
    | 'range'
    | 'phone'
    | 'email'
    | 'datetimepicker'
    | 'toggle';
  options?: string[];
  defaultValue?: any;
}

export interface OrgVenue {
  name: string;
  uid: string;
  active: boolean;
  description?: string;
  address?: Address;
  images?: Image[];
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
}

// schoools/classes/groups
export interface OrgClass {
  name: string;
  uid: string;
  active: boolean;
  description?: string;
  images?: Image[];
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
}

export interface OrgService {
  name: string;
  uid: string;
  active: boolean;
  description?: string;
  images?: Image[];
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  groupSizeMax?: number;
  duration?: string;
  serviceFee?: number;
  serviceRegFee?: number;
  billingCategory?: billingCategory;
  hideServiceOnRegistration?: boolean;
}

export type billingCategory =
  | 'Add-Hoc'
  | 'Monthly'
  | 'Semester'
  | 'Annually'
  | 'Quarterly'
  | 'Event'
  | 'Other';

export interface OrgStaffMember {
  name: string;
  surname: string;
  uid: string;
  active: boolean;
  description?: string;
  images?: Image[];
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  cellNumber?: string;
  email?: string;
  role?: string;
  accessLevel?: string;
}

export interface OrgAttendee {
  uid: string;
  owner: string;
  status?: 'active' | 're-register' | 'suspended';
  name?: string;
  surname?: string;
  email?: string;
  cellNumber?: string;
  gender?: 'male' | 'female' | 'other';
  class: string;
  service: string;
  serviceStatus: 'active' | 'no service' | 'registered' | 'suspended';
  staffList?: string[];
  birthDate?: string;
  medicalConditions?: string;
  languages?: string[];
  occupation?: string;
  competency?: string;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  idNumber?: string;
  comments?: string;
  address?: Address;
  schoolGrade?: string;
  transportRequired?: boolean;
  medicalAidDetail?: string;

  [key: string]: any;
}

export interface OrgEvent {
  endTime: Date;
  endTimezone?: any;
  isAllDay: boolean;
  startTime: Date;
  uid: string;
  id: number;
  subject: string;
  description?: string;
  location: { text: string; value: string };
  service: { text: string; value: string };
  staff: { text: string; value: string }[];
  class: { text: string; value: string }[];
  eventType?: any;
  recurrenceRule?: any;
  startTimezone?: any;
}

export interface OrgChartOfAccount {
  uid: string;
  name: string;
  description?: string;
  type?: 'asset' | 'liability' | 'equity' | 'income' | 'expense';
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  chartAccountNumber: string;
  balanceSheetSubType?: string;
  active?: boolean;
  toIncrease?: string;
  balance: number;
}

export interface OrgLedgerEntry {
  uid: string;
  amount: number;
  date?: string;
  description?: string;
  debitAccount?: { name: string; uid: string };
  creditAccount?: { name: string; uid: string };
  singleEntry?: boolean;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
}

export interface OrgPayment {
  uid: string;
  accountId: string;
  amount: number;
  date?: string;
  description?: string;
  comment?: string;
  paymentMethod?: 'EFT' | 'cash' | 'creditCard' | 'other';
  paymentReference?: string;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
}

export interface OrgDebitNote {
  uid: string;
  accountId: string;
  category:
    | 'Cash Received Fix'
    | 'EFT Received Fix'
    | 'Deposit Request'
    | 'Opening Balance Debit'
    | 'Refund Bank EFT'
    | 'Refund Petty Cash';
  amount: number;
  date?: string;
  description?: string;
  comment?: string;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
}

export interface OrgCreditNote {
  uid: string;
  accountId: string;
  category:
    | 'Credit Note'
    | 'Refund Bank EFT'
    | 'Refund Petty Cash'
    | 'Write Off';
  amount: number;
  date?: string;
  description?: string;
  comment?: string;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
}

export interface OrgInvoiceLineItem {
  uid: string;
  invoiceId: string;
  accountId: string;
  amount: number;
  description?: string;
  comment?: string;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  category:
    | 'Invoice Normal'
    | 'Invoice Discount'
    | 'Invoice Other'
    | 'Invoice Registration';
  fromDateInDescription: string;
  toDateInDescription: string;
  service: string;
  attendee: string;
}

export interface OrgInvoice {
  uid: string;
  accountId: string;
  total: number;
  date?: string;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  lineItems?: OrgInvoiceLineItem[];
}

// https://formly.dev/docs/examples/other/input-file
// export interface OrgEmailNote {
//   uid: string;
//   accountNumber: number;
//   email: string;
//   balance: number;
//   includeAttachment?: boolean;
//   surname: string;
//   subject: string;
//   message: string;
//   createdAt?: Timestamp;
//   fileName?: string;
//   fileUrl?: string;
// }
