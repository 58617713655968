<ion-content fullscreen="true">
  <ion-grid
    fixed="true"
    [ngStyle]="{ 'max-height': isBigDevice ? '110%' : '50%' }"
  >
    <!--      <div *ngIf="!loading && emailVerified">-->
    <div *ngIf="showAddressAndBasicUserInfo">
      <ion-row style="height: 80%" *ngIf="!isError; else error">
        <ion-col>
          <radium-user-basic-info #basicInfo></radium-user-basic-info>

          <!--        <ngx-auth-firebaseui-user></ngx-auth-firebaseui-user>-->
          <br />
          <ion-button
            color="primary"
            [disabled]="basicInformation?.userForm?.invalid"
            (click)="confirmContinue(true)"
            expand="block"
          >
            {{ action }} Account
          </ion-button>

          <!-- Location -->

          <radium-location-details
            #locationDetails
            heading="Addresses"
            (addAddress)="addAddress($event)"
          ></radium-location-details>
        </ion-col>
      </ion-row>
    </div>
    <div *ngIf="showFireBaseUIUser" style="margin: 5px">
      <ngx-auth-firebaseui-user
        class="ion-margin"
        (onAccountEdited)="confirmContinue(true, true)"
      ></ngx-auth-firebaseui-user>
    </div>

    <ng-template #error>
      <p class="ion-text-center ion-padding">
        An error occurred. You do not have permission to view this page.
      </p>
    </ng-template>
  </ion-grid>

  <!--  <radium-footer></radium-footer>-->
</ion-content>
