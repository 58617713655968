<button
  mat-icon-button
  [matMenuTriggerFor]="beforeMenu"
  aria-label="context menu"
  style="background: transparent"
>
  <mat-icon>more_vert</mat-icon>
</button>

<!-- TODO: the below has alot of repetition, button info can be passed in as a config -->
<mat-menu #beforeMenu="matMenu" xPosition="before">
  <ion-list>
    <ion-item>
      <ion-button (click)="onCustomAction('invoice')" fill="none">
        <ion-icon class="ion-padding" name="send-outline"></ion-icon>
        Send Invoice
      </ion-button>
    </ion-item>
    <ion-item>
      <ion-button (click)="onCustomAction('payment')" fill="none">
        <ion-icon class="ion-padding" name="cash-outline"></ion-icon>
        Record Payment
      </ion-button>
    </ion-item>
    <ion-item>
      <ion-button (click)="onCustomAction('credit-note')" fill="none">
        <ion-icon class="ion-padding" name="document-text-outline"></ion-icon>
        Credit Note
      </ion-button>
    </ion-item>
    <ion-item>
      <ion-button (click)="onCustomAction('debit-note')" fill="none">
        <ion-icon class="ion-padding" name="document-text-outline"></ion-icon>
        Debit Note
      </ion-button>
    </ion-item>
    <ion-item>
      <ion-button (click)="onCustomAction('send-email')" fill="none">
        <ion-icon class="ion-padding" name="mail-outline"></ion-icon>
        Send Email
      </ion-button>
    </ion-item>
    <ion-item>
      <ion-button (click)="onCustomAction('send-statement')" fill="none">
        <ion-icon class="ion-padding" name="document-text-outline"></ion-icon>
        Send Statement
      </ion-button>
    </ion-item>
    <ion-item>
      <ion-button (click)="onCustomAction('dowload-statement')" fill="none">
        <ion-icon class="ion-padding" name="download-outline"></ion-icon>
        Download Statement
      </ion-button>
    </ion-item>
    <ion-item>
      <ion-button (click)="onCustomAction('export-excel')" fill="none">
        <ion-icon class="ion-padding" name="document-text-outline"></ion-icon>
        Export Excel
      </ion-button>
    </ion-item>
    <ion-item>
      <ion-button (click)="onCustomAction('profile')" fill="none">
        <ion-icon class="ion-padding" name="person-outline"></ion-icon>
        Account Profile
      </ion-button>
    </ion-item>
    <ion-item>
      <ion-button (click)="onCustomAction('attendees')" fill="none">
        <ion-icon class="ion-padding" name="people-outline"></ion-icon>
        View Attendees
      </ion-button>
    </ion-item>

    <ion-item>
      <ion-button (click)="onCustomAction('cancel-account')" fill="none">
        <ion-icon class="ion-padding" name="people-outline"></ion-icon>
        cancel account
      </ion-button>
    </ion-item>
    <ion-item>
      <ion-button (click)="onCustomAction('re-register')" fill="none">
        <ion-icon class="ion-padding" name="people-outline"></ion-icon>
        re-register account
      </ion-button>
    </ion-item>
    <ion-item>
      <ion-button (click)="onCustomAction('re-activate')" fill="none">
        <ion-icon class="ion-padding" name="people-outline"></ion-icon>
        re-activate account
      </ion-button>
    </ion-item>
    <ion-item>
      <ion-button (click)="onCustomAction('delete-account')" fill="none">
        <ion-icon class="ion-padding" name="people-outline"></ion-icon>
        delete account
      </ion-button>
    </ion-item>
  </ion-list>
  <!--  <button (click)="onCustomAction($event)" mat-menu-item>-->
  <!--    <ion-icon name="umbrella-outline"></ion-icon>-->
  <!--    <span>Disable alerts</span>-->
  <!--  </button>-->
</mat-menu>
