import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastController } from '@ionic/angular';
import {
  ActionEventArgs,
  EventClickArgs,
  EventSettingsModel,
  ScheduleComponent,
  View,
} from '@syncfusion/ej2-angular-schedule';
import { Subscription } from 'rxjs';
import { FirebaseJobService, LoadingService } from '@radium/core';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { map, skipWhile, take } from 'rxjs/operators';

@Component({
  selector: 'radium-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss'],
})
export class SchedulerComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('scheduleObj')
  public scheduleObj?: ScheduleComponent;
  public currentView: View = 'Week';
  slotSelected?: boolean;
  @Output() instance = new EventEmitter();
  @Input() authorId = 'test';
  @Input() jobId = 'test';
  @Input() servicesAmount = 0;
  appointmentForm?: FormGroup;

  public data?: {
    EndTime: Date;
    IsAllDay: boolean;
    StartTime: Date;
    Id: number;
    Subject: string;
  }[] = [];
  //     = [
  //   {
  //     Id: 3,
  //     Subject: "Testing",
  //     StartTime: new Date(2020, 11, 20, 8, 0),
  //     EndTime: new Date(2020, 11, 20, 10, 0),
  //     IsAllDay: false,
  //   },
  // ];

  eventSettings?: EventSettingsModel;
  sub?: Subscription;
  sub2?: Subscription;
  month?: any;
  // selectedAppointment: {
  //   Subject: string;
  //   StartTime: any;
  //   EndTime: any;
  //   authorId: string;
  //   jobId: string;
  //   confirmed: boolean;
  //   selectedColor?: string;
  // };
  endHour = '17:00';
  subSet?: boolean;
  sub90?: Subscription;
  currentAppointmentId?: string;

  //TODO if there is more than one sp, set to true
  twoSPs = false;

  constructor(
    private cd: ChangeDetectorRef,
    public fireDBService: FirebaseJobService,
    // @Inject('FireDBService') private fireDBService: FirebaseJobService,
    @Inject('environment') private env: any,
    private toastCtrl: ToastController,
    private loader: LoadingService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    // this.scheduleObj.selectedDateChange;
    this.appointmentForm = this.formBuilder.group({
      StartTime: ['', Validators.required],
      EndTime: ['', Validators.required],
      authorId: ['', Validators.required],
      jobId: ['', Validators.required],
      confirmed: ['', Validators.required],
      Id: ['', Validators.required],
    });

    dayjs.extend(isSameOrAfter);
    dayjs.extend(isSameOrBefore);

    this.sub90 = this.appointmentForm.valueChanges
      .pipe(skipWhile((x) => !x))
      .subscribe((y) => {
        console.log('data: ', this.data);
        if (y.Id && y?.Id !== this.currentAppointmentId) {
          this.currentAppointmentId = y.Id;
          console.log('called: ', y.Id);
          this.fireDBService
            .getAppointment(y.Id)
            .pipe(take(1))
            .subscribe((myApp: any) => {
              myApp['selectedColor'] = '#47bb76';
              this.data?.push(myApp as any);
              this.scheduleObj?.refreshEvents();
            });
        }
      });
  }

  ngOnChanges() {
    setTimeout(() => {
      this.scheduleObj?.refreshEvents();
    }, 2000);
  }

  dataBinding(): void {
    this.eventSettings = {
      dataSource: this.data,
    };
  }

  onActionBegin(args: ActionEventArgs) {
    this.scheduleObj.minDate = new Date();

    if (this.servicesAmount === 2) {
      this.endHour = '14:00';
    } else if (this.servicesAmount === 3) {
      this.endHour = '11:00';
    }

    if (args?.requestType === 'toolbarItemRendering') {
      args?.items?.splice(3);
    }

    if (args.requestType === 'eventRemove') {
      this.slotSelected = false;
    }

    // if (
    //   ((args.requestType === "eventCreate" ||
    //     args.requestType === "eventChange") &&
    //     (args.data as object[]).length > 0) ||
    //   !isNullOrUndefined(args.data)
    // ) {
    //   const eventData: any = args.data as any;
    //   const eventField: EventFieldsMapping = this.scheduleObj.eventFields;
    //   const startDate: Date = ((args.data as object[]).length > 0
    //     ? eventData[0][eventField.startTime]
    //     : eventData[eventField.startTime]) as Date;
    //   const endDate: Date = ((args.data as object[]).length > 0
    //     ? eventData[0][eventField.endTime]
    //     : eventData[eventField.endTime]) as Date;
    //   args.cancel = !this.scheduleObj.isSlotAvailable(startDate, endDate);
    // }
  }

  setAppointment(e: any) {
    const StartTime = e.startTime ?? e.StartTime;
    const EndTime = e.endTime ?? e.EndTime;
    const hours = EndTime.getHours() + (this.servicesAmount - 1) * 3;
    EndTime.setHours(hours);
    if (e.isAllDay) {
      return;
    }

    const isBeforeToday = (date: any) =>
      new Date(date).valueOf() < new Date().valueOf();

    const today = new Date().toLocaleString().substring(0, 8);

    if (StartTime.toLocaleString().substring(0, 8) === today) {
      this.callToast('Cannot add appointments for today').catch();
      e.cancel = true;
      return;
    }

    if (isBeforeToday(StartTime)) {
      this.callToast('Cannot add appointments in the past').catch();
      e.cancel = true;
      return;
    }

    if (this.appointmentForm?.invalid) {
      if (this.jobId) {
        this.loader
          .loadingPresent('Adding Appointment', 'setAppointment')
          .catch();
        const selectedAppointment = {
          StartTime,
          EndTime,
          authorId: this.authorId,
          jobId: this.jobId,
          confirmed: false,
        };
        this.fireDBService.addAppointment(selectedAppointment)?.then(() => {
          this.appointmentForm?.setValue(selectedAppointment);
          this.slotSelected = true;
          this.scheduleObj?.refreshEvents();
          this.loader.loadingDismiss('setAppointment').then(() => {
            this.callToast('Appointment added!').catch();
          });
        });
      }
    } else {
      this.callToast('You have already selected a slot!').catch();
    }
    e.cancel = true;
  }

  removeFromData(Id: any) {
    this.data?.splice(this.data.indexOf(this.data.find((x) => x.Id === Id)), 1);
    this.scheduleObj?.refreshEvents();
  }

  eventClick(e: EventClickArgs) {
    e.cancel = true;
    if (e?.event?.['authorId'] === this.authorId && !e.event?.['IsReadonly']) {
      this.deleteAppointment(e?.event?.['Id'] as any)?.catch();
    }
  }

  deleteAppointment(id: any) {
    const toBeDel: any = this.data?.find((x) => x.Id === id);

    return this.fireDBService.deleteAppointment(id).then(() => {
      this.data?.splice(this.data?.indexOf(toBeDel), 1);
      this.slotSelected = false;
      this.appointmentForm?.reset();
      this.fireDBService.updateJob(this.jobId, { appointment: {} }).catch();
      this.loader.loadingDismiss('deleteAppointment').then(() => {
        this.callToast('Appointment removed!').catch();
        console.log(this.data);
        this.scheduleObj?.refreshEvents();
      });
    });
  }

  selectedDateChange(date: any) {
    const month = date.getMonth();

    if (this.month !== month) {
      // setTimeout(() => {
      //   this.loader
      //     .loadingPresent("Getting Appointments", "Appointments updated")
      //     .catch();
      // }, 500);

      this.month = date.getMonth();
      const year = date.getFullYear();
      const fromDate = dayjs(new Date().setHours(0, 0)).add(1, 'day').toDate();
      const toDate = dayjs(new Date().setHours(0, 0)).add(2, 'month').toDate();

      if (!this.subSet) {
        this.sub = this.fireDBService
          .getAppointments(fromDate, toDate)
          .pipe(
            // map((item) => item.filter((i) => i.confirmed)),
            skipWhile((x) => !x || x.length === 0),
            map((apps: any[]) => {
              return apps && apps?.map && apps.length > 0
                ? apps.map((a: any) => {
                    const clone = { ...a };
                    if (
                      a.jobId !== this.jobId ||
                      dayjs().isSameOrAfter(a.StartTime)
                    ) {
                      clone['IsReadonly'] = true;
                    }
                    if (
                      a.jobId === this.jobId &&
                      a.authorId === this.authorId &&
                      dayjs().isSameOrBefore(a.StartTime)
                    ) {
                      clone['selectedColor'] = '#47bb76';
                      console.log({ clone });
                      return clone;
                    }

                    if (this.servicesAmount === 3) {
                      clone.StartTime.setHours(8);
                    }

                    if (this.servicesAmount === 2) {
                      if (clone.StartTime.getHours() === 11) {
                        clone.StartTime.setHours(8);
                      }

                      if (clone.StartTime.getHours() === 8) {
                        clone.EndTime.setHours(14);
                      }
                    }
                    this.scheduleObj?.refreshEvents();
                    return clone;
                  })
                : [];
            })
          )
          .subscribe((a: any) => {
            const p = new Promise((res) => {
              this.data = a;
              res(this.data === a);
            });
            // setTimeout(() => {
            //   this.loader.loadingDismiss("Appointments updated").catch();
            // }, 500);
            p.then(() => this.scheduleObj?.refreshEvents());
          });
      }

      this.subSet = true;
    }
  }

  async callToast(message?: any, duration?: any, position?: any) {
    const toast = await this.toastCtrl.create({
      message,
      duration: duration || 2000,
      position: position || 'top',
      buttons: [{ icon: 'close' }],
    });

    return toast.present();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.sub2) {
      this.sub2.unsubscribe();
    }
    if (this.sub90) {
      this.sub90.unsubscribe();
    }
  }
}
