import { Component, OnDestroy } from '@angular/core';

import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'radium-select-add-dropdown',
  templateUrl: './select-add-dropdown.component.html',
  styleUrls: ['./select-add-dropdown.component.scss'],
})
export class SelectAddDropdownComponent
  extends FieldType<FieldTypeConfig>
  implements OnDestroy
{
  newOption: string;

  constructor() {
    super();
  }

  addOption() {
    if (
      this.newOption &&
      !(<any>this.props.options)?.includes(this.newOption)
    ) {
      (<any>this.props.options)?.push({
        label: this.newOption,
        value: this.newOption,
      });
      this.formControl.setValue(this.newOption);
      this.newOption = ''; // Clear the input field
    }
  }

  ngOnDestroy() {
    // this.formControl.
  }
}
