import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense(
  'Mgo+DSMBaFt+QHFqUUdrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRcQllhTH9Wc0BiW3dXd3U=;Mgo+DSMBPh8sVXJ1S0d+WFBPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXpRc0RmXHpfcn1URmE=;ORg4AjUWIQA/Gnt2VFhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5XdEBiXHpbcXZcRGRd;MTgzOTU3MkAzMjMxMmUzMTJlMzQzMWJqMFZGZ2FnY0VCYjlDRTE1a0FwL1JLTUt3dHN0eklIVmtTN1kyakJJc2s9;MTgzOTU3M0AzMjMxMmUzMTJlMzQzMWQzL1AycnRtSzFES0xUZFZHeGsyZWFHU1pzbUowSXJQMGZDRUkwRlV0c0k9;NRAiBiAaIQQuGjN/V0d+XU9Ad1RDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TckdmWHpbdXVXT2leUA==;MTgzOTU3NUAzMjMxMmUzMTJlMzQzMVhlb3ozYmNrOFpHTHdxRjF2eEhzVURZSGZMdUJnMXZGNHE1aTV6d2YxaTg9;MTgzOTU3NkAzMjMxMmUzMTJlMzQzMVBRZWg5ZUVQNXJUWmNMNmhoQ2JUMnVqSTdFUmhPR2pjeitMWDlDV3NxUTg9;Mgo+DSMBMAY9C3t2VFhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5XdEBiXHpbcXdVQGdd;MTgzOTU3OEAzMjMxMmUzMTJlMzQzMWJIMG4yQmpnVkY1QWhVNDJod1F6S2xtWFArRnNpN0RoM3hMSjkxeTJYc289;MTgzOTU3OUAzMjMxMmUzMTJlMzQzMU1ueW8xVEgzUVZ1MEFiaTFoNzZxa1hPcG1iQngveW1uTnVRTTRra1BZVDQ9;MTgzOTU4MEAzMjMxMmUzMTJlMzQzMVhlb3ozYmNrOFpHTHdxRjF2eEhzVURZSGZMdUJnMXZGNHE1aTV6d2YxaTg9'
);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
