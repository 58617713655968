<mat-horizontal-stepper #stepper linear>
  <!-- Location -->
  <mat-step
    [completed]="!isDelivery || ld?.addressesFormArray?.valid"
    [label]="isPile ? 'Pile' : isDelivery ? 'Delivery Address' : 'Collect'"
    [stepControl]="ld?.addressesFormArray"
  >
    <radium-location-details
      #locationDetails
      (addAddress)="addAddress($event)"
      [margin]="{ marginTop: '20px' }"
      [show]="isDelivery"
    ></radium-location-details>

    <div *ngIf="!isPile">
      <div *ngIf="isDelivery" class="ion-margin-top">
        <ion-label class="ion-text-center" color="success">
          Delivery is free for orders above R700
        </ion-label>
      </div>

      <div *ngIf="!isDelivery" class="ion-margin-top">
        <h4 class="ion-text-center">
          Your order will be available for collection at:
        </h4>

        <table style="width: 80%; margin-left: 10px">
          <tr style="text-align: left; border-bottom: solid 1px">
            <th>Place</th>
            <th>Date</th>
          </tr>

          <tr>
            <td>
              <p style="padding-top: 5px">
                {{ clientAddress }}
                <!--                  24 Ashley Road-->
                <!--                  <br />-->
                <!--                  Homestead Park-->
                <!--                  <br />-->
                <!--                  Johannesburg-->
                <!--                  <br />-->
                <!--                  2092-->
              </p>
            </td>
            <td style="white-space: nowrap">{{ collectDate | date }}</td>
          </tr>
        </table>
      </div>

      <ion-button
        (click)="setDelivery()"
        class="ion-margin-vertical"
        color="warning"
        expand="block"
        size="small"
        >{{
          isDelivery
            ? 'I prefer to collect this order myself'
            : 'I want this ' + 'order to be delivered'
        }}
      </ion-button>
    </div>

    <div *ngIf="isPile">
      <ion-item style="margin: 20px 0 40px 0">
        <ion-label>This cart is a pile.</ion-label>

        <ion-button (click)="setCartPile()" *ngIf="isPile">Un-pile</ion-button>
      </ion-item>
    </div>

    <ion-button (click)="setCartPile()" *ngIf="pileAllowed && !isPile"
      >convert cart to pile
    </ion-button>

    <div style="right: 40%; position: relative">
      <!--        <button-->
      <!--          [disabled]="ld?.addressesFormArray?.invalid && isDelivery"-->
      <!--          color="primary"-->
      <!--          mat-raised-button-->
      <!--          matStepperNext-->
      <!--        >-->
      <!--          (click)="updateJob('address')"-->
      <!--          Next-->
      <!--        </button>-->
      <radium-stepper-buttons
        [disabled]="ld?.addressesFormArray?.invalid && isDelivery"
        [isFirst]="true"
        [nextButtonText]="'Next'"
      ></radium-stepper-buttons>
    </div>
  </mat-step>

  <!-- contact details -->
  <mat-step
    [completed]="basicInformation?.userForm.valid"
    [label]="'Info'"
    [stepControl]="basicInformation?.userForm"
  >
    <radium-user-basic-info #basicInfo></radium-user-basic-info>
    <br />
    <radium-stepper-buttons
      [disabled]="basicInformation?.userForm?.invalid"
      [isFirst]="false"
      [nextButtonText]="'Next'"
    ></radium-stepper-buttons>
  </mat-step>

  <!-- Review -->
  <mat-step #step label="Review your order">
    <h2 class="ion-text-center" style="margin-top: 10px; margin-bottom: 0">
      Order summary
    </h2>

    <ion-grid fixed style="border: solid 1px">
      <ion-row
        class="ion-no-padding ion-padding-horizontal"
        style="border-bottom: solid 1px"
      >
        <ion-col size="9"> Item</ion-col>
        <ion-col> Price</ion-col>
      </ion-row>

      <!--          <hr style="border: solid 1px; margin-top: 0" />-->
      <ion-row *ngFor="let p of productsInCart">
        <ion-col size="9">
          {{
            p?.title.length > 17
              ? (p?.title | slice : 0 : 17) + ' ...'
              : p?.title
          }}
        </ion-col>
        <ion-col> R {{ p?.price }} x {{ p?.quantity }}</ion-col>
      </ion-row>
      <ion-row *ngIf="isDelivery">
        <ion-col size="9"> Delivery Rate</ion-col>
        <ion-col> R {{ deliveryCost }}</ion-col>
      </ion-row>
      <hr style="border: solid 1px" />
      <ion-row>
        <ion-col size="9"><strong>TOTAL</strong></ion-col>
        <ion-col> R {{ finalTotal }}</ion-col>
      </ion-row>
      <!--          <hr style="border: solid 1px" />-->
    </ion-grid>

    <div *ngIf="!isPile">
      <div *ngIf="isDelivery; else collect">
        <h2 class="ion-text-center" style="margin-top: 10px; margin-bottom: 0">
          Shipping to
        </h2>

        <p *ngIf="basicInformation?.userForm?.value as details">
          <ion-icon name="person-circle-outline" slot="start"></ion-icon>
          {{ details?.displayName | titlecase }} <br />
          <ion-icon name="call-outline" slot="start"></ion-icon>
          {{ details?.contactNumber ?? 'No Phone Number' }} <br />
          <ion-icon name="mail-outline" slot="start"></ion-icon>
          {{ details?.email ?? 'No-Email' }}
        </p>

        <div *ngIf="!deliverToPostnet" style="margin-left: 10px">
          <span *ngIf="addressInUse?.streetAddress"
            >{{ addressInUse?.streetAddress }}<br
          /></span>

          <span *ngIf="addressInUse?.complex"
            >{{ addressInUse?.complex }} <br
          /></span>

          <span *ngIf="addressInUse?.suburb"
            >{{ addressInUse?.suburb }}<br
          /></span>

          <span *ngIf="addressInUse?.city">{{ addressInUse?.city }}<br /></span>

          <span *ngIf="addressInUse?.province"
            >{{ addressInUse?.province }}<br
          /></span>

          <span *ngIf="addressInUse?.postalCode"
            >{{ addressInUse?.postalCode }}<br
          /></span>

          <span *ngIf="addressInUse?.country">{{ addressInUse?.country }}</span>
        </div>
      </div>

      <ng-template #collect>
        <div class="ion-margin">
          <p *ngIf="basicInformation?.userForm?.value as details">
            <ion-icon name="person-circle-outline" slot="start"></ion-icon>
            {{ details?.displayName | titlecase }} <br />
            <ion-icon name="call-outline" slot="start"></ion-icon>
            {{ details?.contactNumber || 'No Phone Number' }} <br />
            <ion-icon name="mail-outline" slot="start"></ion-icon>
            {{ details?.email || 'No-Email' }}
          </p>
          <p>
            No delivery. Please collect your order on
            <strong>{{ collectDate | date }}</strong
            >, from {{ clientAddress }}
          </p>
        </div>
      </ng-template>
    </div>

    <div *ngIf="isPile">
      <div class="ion-margin">
        <p *ngIf="basicInformation?.userForm?.value as details">
          <ion-icon name="person-circle-outline" slot="start"></ion-icon>
          {{ details?.displayName | titlecase }} <br />
          <ion-icon name="call-outline" slot="start"></ion-icon>
          {{ details?.contactNumber ?? 'No Phone Number' }} <br />
          <ion-icon name="mail-outline" slot="start"></ion-icon>
          {{ details?.email ?? 'No-Email' }}
        </p>
        <p>
          <strong>This order is part of a pile.</strong>
        </p>
      </div>
    </div>

    <ion-text
      *ngIf="deliverToPostnet && isDelivery"
      class="ion-text-center"
      color="danger"
    >
      Please note that we will only be able to deliver your order to the closest
      PostNet store (to the address you have provided).
    </ion-text>

    <radium-stepper-buttons
      (clickEmit)="placeOrder()"
      [disabled]="ld?.addressesFormArray?.invalid && isDelivery"
      [isFinal]="true"
      [nextButtonText]="'Place Order'"
    ></radium-stepper-buttons>
  </mat-step>
</mat-horizontal-stepper>
