<ion-toolbar>
  <ion-title *ngIf="data?.type === 'share'"
    >Share a link to this business
  </ion-title>
  <ion-title *ngIf="data?.type === 'report'">Report abuse</ion-title>
  <ion-title *ngIf="data?.type === 'contact'">Contact Us:</ion-title>
  <ion-title *ngIf="data?.type === 'images'">Images:</ion-title>
  <ion-title *ngIf="data?.type === 'checkout'">Checkout:</ion-title>
  <ion-title *ngIf="data?.type === 'order'">Order:</ion-title>
  <ion-title *ngIf="data?.type === 'cancelJob'">Cancel Job:</ion-title>
  <ion-title *ngIf="data?.type === 'accept/cancel'">Warning:</ion-title>
  <ion-badge
    class="ion-margin-horizontal"
    slot="end"
    style="font-size: 14px; float: right; margin: 0"
    *ngIf="data?.isPile"
    color="danger"
  >
    Pile
  </ion-badge>

  <ion-buttons *ngIf="!data?.noCloseButton" slot="end">
    <ion-button size="small" color="danger" (click)="closeModal()">
      <ion-icon name="close"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>

<ion-content class="ion-padding">
  <div>
    <!-- Report -->
    <radium-textarea-form
      *ngIf="data?.type === 'report'"
      (submitValue)="submit($event)"
      textareaLabel="Description"
      submitButtonText="report"
    ></radium-textarea-form>

    <!-- Cancel Job -->
    <radium-textarea-form
      *ngIf="data?.type === 'cancelJob'"
      (submitValue)="submit($event)"
      textareaLabel="Please provide a reason:"
      submitButtonText="Cancel Job"
      submitButtonColor="danger"
    ></radium-textarea-form>

    <!-- Contact -->
    <radium-contact-us
      *ngIf="data?.type === 'contact'"
      [data]="data"
      [clientName]="data?.clientName"
      (contactUsEvent)="contact($event)"
    ></radium-contact-us>

    <!-- View Images -->
    <div *ngIf="data?.type === 'images'">
      <radium-preview-images
        (previewAction)="setUploading($event)"
        (imageAction)="clickedImage($event)"
        [files]="$any(images)"
      ></radium-preview-images>
    </div>

    <!--  accept / cancel   -->
    <div *ngIf="data?.type === 'accept/cancel'">
      <h4 class="ion-text-center">{{ data?.header }}</h4>
      <ion-row>
        <ion-col>
          <ion-button
            expand="block"
            (click)="closeModal()"
            [color]="data?.cancelColor"
          >
            {{ data?.cancelText }}
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button
            expand="block"
            [color]="data?.acceptColor"
            (click)="
              handleEvent({
                data: 'continue'
              })
            "
          >
            {{ data?.acceptText }}
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
  </div>

  <!-- View Order -->
  <div *ngIf="data?.type === 'order'">
    <div>
      Order Number: {{ data?.id }} <br />
      Status: {{ data?.status | uppercase }} <br />
      Products:
      <p *ngFor="let x of data?.products | keyvalue">
        <span>ID: {{ x?.key }} -</span>
        <span> Quantity: {{ $any(x)?.value?.quantity }}</span>
      </p>
      Created Date:
      {{ data?.createdAt?.seconds * 1000 | date : 'MM-dd-yyyy' }} <br />
      Delivery Method: {{ data?.deliveryMethod | uppercase }} <br />
      <span *ngIf="data?.deliveryMethod === 'collect'"
        >Collection Date: {{ data.collectionDate | date : 'MM-dd-yyyy' }}</span
      >
      <br />
      Recipient Name: {{ data?.recipientName | titlecase }} <br />
      Recipient Email: {{ data?.recipientEmail }} <br />
      <span *ngIf="data?.deliveryMethod !== 'collect'"
        >Delivery Address:
        <pre>{{ data?.deliveryAddress | json }}</pre>
      </span>
      <span
        *ngIf="
          data?.deliveryMethod !== 'collect' && data?.fastwayCollectionDate
        "
        >Fastway Collection Date: {{ data?.fastwayCollectionDate }}</span
      >

      User ID: {{ data?.uid }} <br />

      <br />
    </div>
    <ion-button
      expand="block"
      (click)="handleEvent({ event: 'close', from: 'order', data: data.id })"
    >
      mark as complete
    </ion-button>
  </div>
</ion-content>
