<ejs-listview
  id="servicesListView"
  #listView
  [dataSource]="$any(services$) | async"
  [fields]="fields"
  [showHeader]="true"
  [headerTitle]="headerTitle"
  (select)="select($event)"
  (actionComplete)="actionComplete($event)"
  cssClass="customListView"
  [ngStyle]="{ margin: margin }"
  *ngIf="$any(servicesForm)?.length < 11; else servicesLimit"
>
  <!--    *ngIf="!serviceHasBeenSelected"-->
  <ng-template #template let-data>
    <div
      class="settings"
      *ngIf="data?.isHeader; then innerHeader; else itemTemplate"
    ></div>

    <ng-template #innerHeader>
      <div class="innerHeader" style="text-align: center">{{ data.text }}</div>
    </ng-template>

    <ng-template #itemTemplate>
      <span>{{ data.text }}</span>

      <ejs-tooltip
        #tooltip
        *ngIf="data.details"
        id="showTooltip"
        opensOn="Click"
        [content]="data.details"
        position="BottomCenter"
      >
        <ion-button
          style="margin-top: 0; margin-bottom: 10px"
          size="small"
          fill="clear"
          (click)="showInfo($event, data.details)"
        >
          <ion-icon
            color="success"
            name="information-circle-outline"
          ></ion-icon>
        </ion-button>
      </ejs-tooltip>

      <div
        *ngIf="data.price && data.price > 0"
        style="display: inline; float: right; color: darkblue; font-weight: 500"
      >
        R{{ data.price }}
      </div>
    </ng-template>
  </ng-template>
</ejs-listview>

<ng-template #servicesLimit>
  <p class="ion-text-center" style="color: red">
    A max of 10 services are allowed per job
  </p>
</ng-template>

<div
  *ngIf="$any(servicesForm).length > 0"
  style="margin-left: 5px; margin-top: 20px; border: solid 2px limegreen"
>
  <h3 style="margin-bottom: 0; margin-left: 5px; font-weight: bold">
    Selected service<span *ngIf="$any(servicesForm).length > 1">s</span>:
  </h3>
  <table
    style="
      width: 100%;
      margin-left: 1px;
      font-size: 13px;
      border: none;
      border-left: solid 1px;
    "
  >
    <tr>
      <th style="border-left: none">Category</th>
      <th>Service</th>
      <th style="min-width: 65px">Qty</th>
      <th style="min-width: 55px">Price</th>
    </tr>
    <tr *ngFor="let control of $any(servicesForm).controls; index as i">
      <td style="border-left: none">
        {{
          control?.get('category')?.value === 'Root'
            ? '---'
            : control?.get('category')?.value
        }}
      </td>
      <td>{{ control.get('text')?.value }}</td>
      <td>
        <radium-incr-decr
          [size]="'small'"
          [hasPadding]="false"
          [canEdit]="control?.get('quantityCanEdit')?.value === true"
          (incrDecrEvent)="incrDecr($event, i)"
          [amount]="control?.get('quantity')?.value"
          [disableDecrement]="control?.get('quantity')?.value === 1"
        ></radium-incr-decr>
      </td>
      <td>R {{ control?.get('price')?.value }}</td>
      <td style="border: none; padding: 0">
        <ion-row>
          <ion-col class="ion-text-center ion-no-padding">
            <ion-button
              class="ion-no-padding ion-no-padding"
              color="clear"
              size="small"
              shape="round"
              (click)="removeService(i, control.value)"
            >
              <ion-icon
                name="trash-outline"
                size="small"
                class="ion-no-margin"
                color="danger"
              ></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
      </td>
    </tr>
    <tr style="font-size: 13px">
      <th colspan="1" style="border: 1px solid white; padding: 0">
        <!--        <ion-button-->
        <!--          slot="start"-->
        <!--          size="small"-->
        <!--          shape="round"-->
        <!--          (click)="addService()"-->
        <!--          [disabled]="$any(servicesForm).length > 2"-->
        <!--          style="font-size: 10px"-->
        <!--        >-->
        <!--          <ion-icon slot="start" name="add-circle-outline"></ion-icon>-->
        <!--          Add another service-->
        <!--        </ion-button>-->
      </th>
      <th
        style="
          border: 1px solid white;
          text-align: right;
          background-color: aliceblue;
        "
      >
        TOTAL:
      </th>
      <th
        style="border: none; text-align: center; background-color: aliceblue"
        colspan="2"
      >
        R {{ total }}
      </th>
    </tr>
  </table>

  <div
    *ngIf="loungeTitle"
    style="
      color: red;
      margin: auto;
      font-size: 12px;
      padding-top: 5px;
      text-align: center;
    "
  >
    Prices exclude scatter cushions. Please add a "Scatter Cushion" service if
    needed.
  </div>
</div>
