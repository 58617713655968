import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'radium-incr-decr',
  templateUrl: './incr-decr.component.html',
  styleUrls: ['./incr-decr.component.scss'],
})
export class IncrDecrComponent {
  @Input() amount?: number;
  @Input() disableDecrement?: boolean;
  @Input() disableIncrement?: boolean;
  @Input() canEdit = true;
  @Input() hasPadding = true;
  @Input() size = 'large';
  @Output() incrDecrEvent = new EventEmitter();

  incrDecrAction(type: any) {
    this.incrDecrEvent.emit(type);
  }
}
