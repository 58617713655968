<ion-item class="ion-no-padding" style="font-size: 13px" lines="none">
  <ion-label
    style="margin: 0; padding: 0 0 0 12%; font-size: 18px; font-weight: bold"
    >{{ heading }}:
  </ion-label>
  <ion-select
    class="ion-no-padding ion-padding-horizontal ion-no-margin"
    style="border: solid 1px lightgrey; width: 200px; padding: 5px"
    interface="popover"
    (ionChange)="onChange($event)"
    [value]="$any(dataList?.[0])"
    [selectedText]="dataList?.length === 1 ? dataList?.[0] : null"
  >
    <ion-select-option *ngFor="let dataItem of dataList"
      >{{ dataItem }}
    </ion-select-option>
  </ion-select>
</ion-item>
