import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Auth, authState, signInWithCredential } from '@angular/fire/auth';
import { Performance, trace } from '@angular/fire/performance';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { firstValueFrom, Observable, skipWhile, Subscription } from 'rxjs';
import { Cart, User } from '@radium/ui';

import {
  FirebaseBookService,
  FirebaseBusinessService,
  FirebaseUserService,
  LoadingService,
} from '@radium/core';

@Component({
  selector: 'radium-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  fragment$: Observable<string>;
  anonUserUid?: string;
  displayName: string | null;
  loading$: Observable<boolean>;

  isBigDevice: boolean;
  isAnonymous = true;
  sub: Subscription;
  message?: string;
  getParamsSub: Subscription;
  segments: string[] = [];
  anonCartDoc: Cart;
  data: any;
  extraParams: { [key: string]: any };
  private sub1: Subscription;
  private anonUserDoc: User;
  private anonymousUser;
  public redirectUrl = 'home';
  private loginTrace;
  @Input() hasCart = true;
  @Input() logoUrl: string;
  @Input() orgName: string;
  @Input() tosUrl: string;
  @Input() privacyPolicyUrl: string;

  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
    private afAuth: Auth,
    public fireBusService: FirebaseBusinessService,
    public fireUser: FirebaseUserService,
    public toast: ToastController,
    public loadingService: LoadingService,
    private perf: Performance,
    public fireBookService: FirebaseBookService,
    @Inject('environment') public env: any
  ) {}

  async ngOnInit() {
    this.fireUser.isBigDevice().subscribe((x) => (this.isBigDevice = !x));
    setTimeout(() => {
      this.checkURLParams();
      this.initTrace().catch();
    }, 2000);

    const res = await firstValueFrom(authState(this.afAuth));
    console.log('res: ', res);
    if (res !== null) {
      this.anonUserUid = res.uid;
      this.anonymousUser = res;
      if (res.isAnonymous) {
        this.anonUserDoc = await firstValueFrom(
          this.fireUser.getUserDoc(res.uid)
        );

        if (this.hasCart) {
          this.anonCartDoc = await firstValueFrom(
            this.fireBusService.getCart(res.uid)
          );
        }
      }
    } else {
      setTimeout(async () => {
        const res = await firstValueFrom(
          authState(this.afAuth).pipe(skipWhile((x) => !x))
        );
        console.log('res 2: ', res);

        this.anonUserUid = res.uid;
        this.anonymousUser = res;
        if (res.isAnonymous) {
          this.anonUserDoc = await firstValueFrom(
            this.fireUser.getUserDoc(res.uid)
          );

          if (this.hasCart) {
            this.anonCartDoc = await firstValueFrom(
              this.fireBusService.getCart(res.uid)
            );
          }
        }
      }, 3000);
    }

    this.fragment$ = this.activeRoute?.fragment;

    setTimeout(() => {
      console.log('logoUrl: ', this.logoUrl);
    }, 3000);
  }

  async initTrace() {
    this.loginTrace = await trace(this.perf, 'userLogin');
    this.loginTrace.start();
  }

  async successCallback(user, signIn?: boolean) {
    console.log('successCallback: ', user);
    const { uid, email, displayName, contactNumber, isAnon, ...rest } =
      this.anonUserDoc || {};
    console.log('anonUserUid: ', uid);

    if (signIn) {
      if (this.hasCart) {
        if (
          this.anonCartDoc?.products &&
          Object.values(this.anonCartDoc.products).length > 0
        ) {
          // TODO fix types here

          this.fireBusService
            .updateCart(user.uid, {
              products: this.anonCartDoc.products as any,
            })
            .then(() => {
              // this.fireBusService.deleteCartDoc(this.anonUserUid).catch();
            });
        } else {
          // this.fireBusService.deleteCartDoc(this.anonUserUid).catch();
        }
      }

      this.loginTrace.putAttribute(
        'old_user_verified',
        `${user.emailVerified}`
      );
      this.loginTrace.stop();

      // call callable firebase function deleteAnonUser to delete anon user
      if (uid) {
        this.fireUser
          .deleteAnonUser(uid)
          .then((x) => console.log({ x }))
          .catch();
      }

      this.navigate(user);
    } else {
      await this.fireUser.updateUserDoc(
        user.uid,
        {
          ...{
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            isAnonymous: false,
          },
          ...rest,
        },
        this.orgName && { client: this.orgName }
      );

      if (this.hasCart) {
        const sanitizedCartData = this.sanitizeCartData(this.anonCartDoc || {});
        sanitizedCartData.isAnonymous = false;
        sanitizedCartData.products = sanitizedCartData.products || {};

        try {
          await this.fireBusService.createCartWithData(
            user.uid,
            sanitizedCartData
          );

          // this is being handled in the firebase function
          // if (this.anonUserUid) {
          //   await this.fireBusService.deleteCartDoc(this.anonUserUid);
          // }
        } catch (error) {
          console.error('Error handling cart:', error);
        }
      }

      try {
        if (uid) {
          await this.fireUser.deleteAnonUser(uid);
        }
      } catch (error) {
        console.error('Error deleting anonymous user:', error);
      }

      this.navigate(user);
      this.loginTrace.putAttribute('anon_verified', `${user.emailVerified}`);
      this.loginTrace.stop();
    }
  }

  // Add this new method to sanitize the cart data
  private sanitizeCartData(cartData: any): any {
    if (typeof cartData !== 'object' || cartData === null) {
      return cartData;
    }

    const sanitized = { ...cartData };

    Object.keys(sanitized).forEach((key) => {
      if (typeof sanitized[key] === 'function') {
        delete sanitized[key];
      } else if (sanitized[key] === undefined) {
        delete sanitized[key];
      } else if (
        typeof sanitized[key] === 'object' &&
        sanitized[key] !== null
      ) {
        sanitized[key] = this.sanitizeCartData(sanitized[key]);
        if (Object.keys(sanitized[key]).length === 0) {
          delete sanitized[key];
        }
      }
    });

    return sanitized;
  }

  navigate(user) {
    if (this.redirectUrl === 'checkout') {
      this.router
        .navigate([this.segments[0], user.uid], {
          replaceUrl: true,
          queryParams: this.extraParams,
        })
        .then(() => this.wrapUpLoading())
        .catch(() => this.wrapUpLoading());
    } else if (this.redirectUrl === 'about' || this.redirectUrl === 'add-org') {
      this.router
        .navigate([this.redirectUrl, this.segments[1]], {
          queryParams: this.extraParams,
        })
        .then(() => this.wrapUpLoading())
        .catch(() => this.wrapUpLoading());
    } else {
      console.log('redirectUrl: ', this.redirectUrl);
      this.router
        .navigate([this.redirectUrl], {
          replaceUrl: true,
          queryParams: this.extraParams,
        })
        .then(() => this.wrapUpLoading())
        .catch(() => this.wrapUpLoading());
    }
  }

  async errorCallback(error) {
    console.warn('errorCallback', error);
    this.toasty(error.message).catch();

    this.loginTrace.putAttribute('errorCode', error.code);
    this.loginTrace.stop();

    if (error.code !== 'firebaseui/anonymous-upgrade-merge-conflict') {
      return Promise.resolve();
    } else {
      // The credential the user tried to sign in with.
      const cred = error.credential;

      // Hold a reference to the anonymous current user.
      const anonymousUser = await this.afAuth.currentUser;
      signInWithCredential(this.afAuth, cred).then((val: any) => {
        const { uid, email, displayName, contactNumber, ...rest } =
          this.anonUserDoc;

        this.fireUser
          .updateUserDoc(
            val.user.uid,
            {
              ...{
                uid: val.user.uid,
                email: val.user.email,
                displayName: val.user.displayName,
                olduid: anonymousUser.uid,
              },
              ...rest,
            },
            this.orgName && { client: this.orgName }
          )
          .then(() => {
            // call callable firebase function deleteAnonUser to delete anon user
            this.fireUser
              .deleteAnonUser(uid)
              .then((x) => console.log({ x }))
              .catch();
          });

        this.fireBusService
          .createCartWithData(anonymousUser.uid, this.anonCartDoc as any)
          .then(() => {
            // this.fireBusService.deleteCartDoc(anonymousUser.uid).catch();
          });

        this.router.navigate([this.redirectUrl], {
          replaceUrl: true,
          state: { data: history.state.data },
          queryParams: this.extraParams,
        });
      });
    }
  }

  async toasty(message) {
    const toast = await this.toast.create({
      duration: 15000,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
          handler: () => {
            console.log('Close clicked');
          },
        },
      ],
      position: 'bottom',
      header: message,
    });

    await toast.present();
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.sub1) {
      this.sub1.unsubscribe();
    }
    if (this.getParamsSub) {
      this.getParamsSub.unsubscribe();
    }
  }

  private checkURLParams() {
    this.getParamsSub = this.activeRoute.queryParams.subscribe((params) => {
      console.log({ params });
      this.logoUrl = params.logoUrl ?? this.logoUrl;

      // TODO: Implement getParameterByName()

      // Get the redirectUrl
      if (params.redirectUrl) {
        const { redirectUrl, segments, becomeAffiliate, ...extraParams } =
          params;

        this.redirectUrl = redirectUrl;
        this.segments = segments;
        this.extraParams = extraParams;

        console.log('f: ', redirectUrl.slice(0, 5));

        switch (redirectUrl.slice(0, 5)) {
          case '/home':
            this.message = `You need to be signed in to continue. Please login or create a new free account.`;
            break;
          case 'home':
            this.message = `You need to be signed in to continue. Please login or create a new free account.`;
            break;
          case '/regi':
            this.message = `You need to be signed in to register. Please login or create a new free account.`;
            break;
          case 'add-b':
            this.message = `You need to be signed in to add a business. Please login or create a new free account.`;
            break;
          case 'add-m':
            this.message = `You need to be signed in to add a Masjid. Please login or create a new free account.`;
            break;
          case 'add-o':
            this.message = `You need to be signed in to add an Organization. Please login or create a new free account.`;
            break;
          case 'add-j':
            this.message = `You need to be signed in to add a job. Please login or create a new free account.`;
            break;
          case 'jobs':
            this.message = `You need to be signed in to view your jobs. Please login or create a new free account.`;
            break;
          case 'check':
            this.message = `You need to be signed in to checkout your cart. Please login or create a new free account.`;
            break;
          case 'accou':
            if (becomeAffiliate) {
              this.message = `You need to be signed in to become an affiliate. Please login or create a new free account.`;
            } else {
              this.message = `You need to be signed in to view your account. Please login or create a new free account.`;
            }
            break;
        }
      } else {
        this.message = `Welcome to ${this.env?.clientName}. Please login or create a new free account.`;
      }
    });
  }

  private wrapUpLoading() {
    return setTimeout(() => {
      if (this.loadingService.isLoading$.getValue() === true) {
        this.loadingService.loadingDismiss('login').catch();
      }
      this.loading$ = this.loadingService.isLoading$;
    }, 1000);
  }

  getTos(e) {
    if (e?.path?.[0]?.id === 'mat-mdc-checkbox-1-input' && e?.offsetX > 200) {
      console.log('open tcs modal');
    }
    if (e?.path?.[0]?.id === 'mat-mdc-checkbox-2-input' && e?.offsetX > 200) {
      console.log('open policy modal');
    }
  }
}
