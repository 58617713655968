import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'radium-textarea-form',
  templateUrl: './textarea-form.component.html',
  styleUrls: ['./textarea-form.component.scss'],
})
export class TextareaFormComponent implements OnInit {
  textareaForm?: FormGroup;

  @Output() submitValue = new EventEmitter();
  @Input() submitButtonText?: string;
  @Input() submitButtonColor = 'primary';
  @Input() textareaLabel?: string;

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.textareaForm = this.formBuilder.group({
      textarea: [''],
    });
  }

  submit() {
    this.submitValue.emit(this.textareaForm?.get('textarea')?.value);
  }
}
