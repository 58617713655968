<ion-grid
  fixed
  class="ion-margin-bottom"
  style="border: solid 1px lightgray; padding: 5px"
  [ngStyle]="files?.length === 1 && { width: '400px' }"
>
  <div style="min-height: 170px">
    <ion-row *ngIf="files?.length > 0; else noImages">
      <ion-col
        [size]="files?.length === 1 ? 12 : 6"
        *ngFor="let file of files; index as i"
        style="border: solid 1px lightgrey"
      >
        <div>
          <ion-icon
            slot="end"
            name="close"
            style="
              color: #ff0000;
              position: absolute;
              right: 5px;
              top: 5px;
              border: solid 1px;
              border-radius: 8px;
            "
            (click)="actionTheImage('delete', i)"
            *ngIf="canDelete"
          ></ion-icon>
          <ion-img
            [src]="file?.downloadURL"
            (click)="actionTheImage('viewImage', i)"
            ionImgViewer
            [text]="file?.imageCategory"
            [title]="file?.title"
            (ionError)="imgError = true"
            (ionImgDidLoad)="imgLoading = false"
            (ionImgWillLoad)="imageLoadStart($event)"
          ></ion-img>

          <ion-spinner
            style="margin: auto; width: 100%"
            *ngIf="imgLoading && !imgError"
          ></ion-spinner>
          <p *ngIf="imgError">error loading image</p>
        </div>
      </ion-col>
    </ion-row>

    <ng-template #noImages>
      <p class="ion-text-center ion-padding ion-margin">
        No images found.
        <span *ngIf="canAddImage">Click on the button below to add images</span>
      </p>
    </ng-template>
  </div>
</ion-grid>

<ion-fab
  *ngIf="canAddImage"
  vertical="center"
  horizontal="center"
  slot="fixed"
  [ngStyle]="cameraButtonPosition ? { top: cameraButtonPosition } : null"
>
  <ion-fab-button
    color="secondary"
    (click)="actionThePreview('disablePreview')"
  >
    <ion-icon name="camera"></ion-icon>
  </ion-fab-button>
</ion-fab>

<!--style="top: 165px"-->
