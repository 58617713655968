import { Injectable } from '@angular/core';

import { AlertController } from '@ionic/angular';

// import { ConfirmationComponent } from './confirmation.component';

export const CanDeactivateState = {
  defendAgainstBrowserBackButton: false,
  edited: false,
};

@Injectable()
export class CanDeactivateGuard {
  can = false;

  constructor(readonly alertCtrl: AlertController) {}

  async canDeactivate(component: any) {
    if (!CanDeactivateState.edited) {
      history.pushState('home/library', '', '');
      return true;
    } else {
      await this.presentAlert().catch();
      if (!this.can && CanDeactivateState.defendAgainstBrowserBackButton) {
        history.pushState('home/library', '', '');
        return true;
      }
      return false;
    }
  }

  async presentAlert() {
    const alert = await this.alertCtrl.create({
      header: 'You have unsaved changes!',
      subHeader: 'What would you like to do?',
      backdropDismiss: false,
      buttons: [
        {
          text: 'cancel',
          handler: () => {
            this.can = true;
          },
        },
        {
          text: 'Go to Home Page',
          handler: () => {
            this.can = false;
          },
        },
      ],
    });
    await alert.present();

    return alert.onDidDismiss();
  }
}
