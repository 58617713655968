import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { IonicModule } from '@ionic/angular';
import { TagInputModule } from 'ngx-chips';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, IonicModule, TagInputModule, FormsModule, RouterLink],
  providers: [],
  exports: [HeaderComponent],
})
export class HeaderModule {}
