import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingService } from '@radium/core';
import { Observable, Subscription } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { AddEditAddressComponent } from '../add-edit-address';

@Component({
  selector: 'radium-location-details',
  templateUrl: './location-details.component.html',
  styleUrls: ['./location-details.component.scss'],
})
export class LocationDetailsComponent implements OnInit, OnDestroy {
  @Input() uid?: string;
  @Input() heading?: string;
  @Input() isBigDevice?: boolean;
  @Input() show = true;
  @Input() margin?: { [key: string]: string };
  @Output() addAddress = new EventEmitter();

  addressesFormArray = new FormArray([], [Validators.required]);
  addressesGroup = new FormGroup({
    addresses: this.addressesFormArray,
  });

  loading$?: Observable<boolean>;
  addingAddress?: boolean;
  initAlreadyCalled?: boolean;
  sub?: Subscription;

  // private position: Partial<FirePoint> = { geohash: 'not set' };

  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.loading$ = this.loadingService.isLoading$;
  }

  initializeAddresses(addresses: any[]) {
    if (addresses?.length > 0 && !this.initAlreadyCalled) {
      addresses.forEach((address, index) => {
        this.createNewAddressForm()
          .then(() => {
            this.addressesFormArray.at(index).patchValue(address);
          })
          .catch();
      });
    }

    if (addresses?.length === 0 && !this.initAlreadyCalled) {
      this.addEditAddress().catch();
    }

    this.initAlreadyCalled = true;
  }

  removeAddress(index: any, updateAlso?: any) {
    const isInUse = this.addressesFormArray.at(index).get('inUse')?.value;

    setTimeout(() => {
      this.addressesFormArray.removeAt(index);
    }, 100);

    if (updateAlso) {
      setTimeout(() => {
        if (isInUse) {
          this.setInUse(null, this.addressesFormArray.length - 1);
        } else {
          this.addAddress.emit(this.addressesFormArray.value);
        }
      }, 200);
    }
  }

  createNewAddressForm() {
    return new Promise((res) => {
      this.addressesFormArray.push(
        this.fb.group({
          addressType: ['residence', Validators.required],
          streetAddress: ['', Validators.required],
          complex: [' '],
          suburb: ['', Validators.required],
          city: ['', Validators.required],
          province: ['', Validators.required],
          postalCode: ['', Validators.required],
          position: [' '],
          country: [''],
          countryCode: [''],
          latitude: [''],
          locationId: [''],
          longitude: [''],
          requiresValidation: [''],
          inUse: [''],
        })
      );
      res(this.addressesFormArray);
    });
  }

  async addEditAddress(address?: any, isNew?: any) {
    if (!address) {
      this.createNewAddressForm().catch();
    }

    const index =
      this.addressesFormArray.controls.length === 0
        ? 0
        : this.addressesFormArray.controls.length - 1;

    const lastAddress = this.addressesFormArray.controls[index];

    const modal = await this.modalCtrl.create({
      component: AddEditAddressComponent,
      backdropDismiss: false,
      cssClass: 'wideModal',
      componentProps: {
        isNew,
        address: lastAddress,
      },
    });
    await modal.present();
    modal.onDidDismiss().then((val: any) => {
      console.log('onDidDismiss: ', val);
      if (!val.data && isNew) {
        this.removeAddress(index, false);
      } else if (val.data?.streetAddress) {
        this.setInUse(null, index);
      }
    });
  }

  setInUse(e: any, i: number) {
    if (e?.target?.nodeName === 'ION-BUTTON') {
      return;
    }

    this.addressesFormArray.controls.forEach((c) => {
      c.get('inUse')?.patchValue(false);
    });

    setTimeout(() => {
      this.addressesFormArray.at(i)?.get('inUse')?.patchValue(true);
      this.addAddress.emit(this.addressesFormArray.value);
    }, 200);
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
