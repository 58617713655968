import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ErrorService implements ErrorHandler {
  constructor(private injector: Injector, private toastCtrl: ToastController) {}

  handleError(error: any) {
    console.log(error.stack);
    console.log({ error });

    // setTimeout(() => {
    //   if (this.loadingService.isLoading) {
    //     this.loadingService.loadingDismiss('error').catch();
    //     this.toast(error?.message).catch();
    //   }
    // }, 5000);

    if (error?.stack?.includes('gapi is not defined')) {
      window.location.reload();
    }

    // const router = this.injector.get(Router);
    // if (Error instanceof HttpErrorResponse) {
    //   console.log('httpError :', error.stack);
    // }

    // const router = this.injector.get(Router);
    // if (Error instanceof HttpErrorResponse) {
    //   console.log("httpError", { error });
    // }

    // this.toast(error).catch();
    // router
    //   .navigate(["error"], {
    //     queryParams: {
    //       error
    //     }
    //   })
    //   .catch();
  }

  async toast(message: any) {
    const toast = await this.toastCtrl.create({
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
          handler: () => {
            console.log('Close clicked');
          },
        },
      ],
      position: 'bottom',
      header: 'Error',
      message,
      color: 'danger',
    });

    await toast.present();
  }
}
