import { catchError, map, take } from 'rxjs/operators';
import { Appointment, Job, JobBasic, Like, Service, State } from '@radium/ui';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
// Options to reproduce firestore queries consistently
import * as moment from 'moment';
import { Platform } from '@ionic/angular';

import { HttpClient } from '@angular/common/http';
import {
  arrayRemove,
  arrayUnion,
  collection,
  collectionChanges,
  collectionData,
  CollectionReference,
  deleteDoc,
  doc,
  docData,
  DocumentReference,
  FieldPath,
  Firestore,
  increment,
  orderBy,
  OrderByDirection,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from '@angular/fire/firestore';

// import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";

@Injectable({ providedIn: 'root' })
export class FirebaseJobService {
  // Source data
  LOADING = new BehaviorSubject(false);
  // private query: QueryConfig;

  jobColl: CollectionReference<Job>;
  jobBasicColl: CollectionReference<JobBasic>;
  appointmentsColl: CollectionReference<Appointment>;
  servicesColl: CollectionReference<Service>;

  constructor(
    public afs: Firestore,
    public platform: Platform,
    private http: HttpClient,
    @Inject('environment') private env: any
  ) {
    this.jobColl = collection(this.afs, 'jobs') as CollectionReference<Job>;
    this.jobBasicColl = collection(
      this.afs,
      'jobs_basic'
    ) as CollectionReference<JobBasic>;
    this.appointmentsColl = collection(
      this.afs,
      'appointments'
    ) as CollectionReference<Appointment>;
    this.servicesColl = collection(
      this.afs,
      'services'
    ) as CollectionReference<Service>;
  }

  // JOB METHODS

  getJob(jobDocId: string) {
    const jobRef = doc(this.afs, `jobs/${jobDocId}`) as DocumentReference<Job>;

    return docData(jobRef, { idField: 'id' as any }).pipe(
      catchError((x: any) => of(x))
    );
  }

  getAllJobDocs() {
    return collectionData<Job>(
      query<Job>(this.jobColl, orderBy('createdAt', 'desc'))
    ).pipe(catchError((x) => of(x)));
  }

  getJobDocs() {
    return collectionData<Job>(
      query<Job>(this.jobColl, where('approved', '==', true))
    ).pipe(catchError((x) => of(x)));
  }

  getFilteredJobDocs(
    key?: string | FieldPath,
    operator?: any,
    value?: any,
    order?: OrderByDirection
  ) {
    return collectionData<Job>(
      query<Job>(
        this.jobColl,
        key && operator && value && where(key, operator, value),
        where('state', 'not-in', [State.job_cancelled, State.job_completed]),
        orderBy('state'),
        orderBy('createdAt', 'desc')
      )
    ).pipe(catchError((x) => of(x)));
  }

  getAllUserJobDocs(
    key: string | FieldPath,
    operator: any,
    value: any,
    order?: OrderByDirection
  ) {
    return collectionData<Job>(
      query<Job>(
        this.jobColl,
        where(key, operator, value),
        orderBy('createdAt', 'desc')
      )
    ).pipe(catchError((x) => of(x)));
  }

  jobExists(uid: string, type: string): any {
    const begin = moment().startOf('month').format();

    return collectionChanges<Job>(
      query<Job>(
        this.jobColl,
        where('authorId', '==', uid),
        where('createdAt', '>', new Date(begin)),
        where('type', '==', type)
      )
    ).pipe(
      map((arr) => {
        const values = arr.map((snap) => {
          return snap?.doc?.exists;
        });
        return values;
      }),
      catchError((x) => of(x))
    );
  }

  //
  // getMarketPlaceJobDocs(category: number, sanhaOnly: boolean) {
  //   return this.afs
  //     .collection<Job>("jobs", (ref) =>
  //       sanhaOnly
  //         ? ref
  //             .where("jobInfo.jobCategory", "==", category)
  //             .where("jobInfo.certifiedBy", "==", "SANHA")
  //             .where("approved", "==", true)
  //         : ref
  //             .where("jobInfo.jobCategory", "==", category)
  //             .where("approved", "==", true)
  //     )
  //     .snapshotChanges()
  //     .pipe(
  //       map((arr) => {
  //         this.LOADING.next(true);
  //         return arr.map((snap: any) => {
  //           const data: Job = snap.payload.doc.data();
  //           const doc = snap.payload.doc;
  //           return {
  //             id: doc.id,
  //             title: data.jobInfo?.jobName,
  //           };
  //         });
  //       }),
  //       catchError((x) => of(x))
  //     );
  // }

  createJob(
    authorId: string,
    authorType = 'user',
    type = '',
    services: Service[]
    // appointment?: any,
    // address?: Address,
    // jobHours: { [day: string]: string },
    // bankDetails: BankDetails,
    // images?: Image[],
    // acceptAgreement?: boolean,
    // email?: string,
    // contactPersonName?: string,
    // contactPersonNumber?: string,
    // whatMatters?: string,
    // packageName?: "gold" | "silver" | "bronze"
  ) {
    const document = doc(this.jobColl);

    const data: Job = {
      id: document.id,
      acceptAgreement: false,
      address: {} as any,
      appointment: {},
      contactPersonName: '',
      contactPersonNumber: '',
      email: '',
      createdAt: Timestamp.now(),
      authorId,
      authorType,
      viewedByAdmin: false,
      // images,
      likes: [],
      views: 0,
      type,
      services,
      // jobHours,
      // bankDetails,
      isDraft: true,
      approved: false,
      // packageName,
      whatMatters: '',
      state: State.services_selected,
      payment_method: 'eft',
      history: {
        state: State.services_selected,
        updatedAt: Timestamp.now(),
      },
    };

    return setDoc(document, data, { merge: true }).then(() => document.id);

    // let document;
    // let documentUuid;

    // if (jobId === "0") {
    //   // Generate "locally" a new document in a collection
    //   document = this.jobColl.ref.doc();
    //   documentUuid = document.id;
    // } else {
    //   document = this.jobBasicColl.doc(jobId);
    //   documentUuid = jobId;
    // }

    // return document.set(data, { merge: true }).then((j) => {
    //   const basicData: JobBasic = {
    //     position: address.position,
    //     approved: false,
    //     jobId: documentUuid,
    //     authorId,
    //     authorType,
    //     packageName,
    //   };
    //
    //   this.jobBasicColl
    //     .doc(documentUuid)
    //     .set(basicData, { merge: true })
    //     .catch();
    //   return j;
    // });
  }

  async createAddhocJob(
    authorId: string,
    authorType = 'user',
    adhocMsg,
    contactPersonName,
    contactPersonNumber,
    email
  ) {
    const document = doc(this.jobColl);

    const data: Job = {
      id: document.id,
      acceptAgreement: false,
      address: {} as any,
      appointment: {},
      contactPersonName,
      contactPersonNumber,
      email,
      adhocMsg,
      createdAt: Timestamp.now(),
      authorId,
      authorType,
      viewedByAdmin: false,
      // images,
      likes: [],
      views: 0,
      type: '',
      services: [],
      isDraft: true,
      approved: false,
      whatMatters: '',
      state: State.services_selected,
      payment_method: 'eft',
      history: {
        state: State.services_selected,
        updatedAt: Timestamp.now(),
      },
    };

    await setDoc(document, data, { merge: true });
    return document.id;
  }

  getJobDocsByCategory(categoryID: number): any {
    return collectionData<Job>(
      query<Job>(this.jobColl, where('jobInfo.jobCategory', '==', categoryID))
    ).pipe(catchError((x) => of(x)));
  }

  tempCreate(id: any, basicData: any) {
    const jobRef = doc(this.afs, `jobs_basic/${id}`);
    return setDoc(jobRef, basicData, { merge: true }).catch();
  }

  // async updateAllJobInfo(uid: string, data: Job) {
  //   console.log(data);
  //
  //   const job: Partial<Job> = {
  //     jobId: data.jobId,
  //     authorId: data.authorId,
  //     viewed_by_admin: data.viewed_by_admin,
  //     createdAt: data.createdAt,
  //     views: data.views
  //   };
  //
  //   const cleanedObj = this.cleanAnObject(job);
  //
  //   await this.updateJob(uid, cleanedObj);
  // }

  updateJob(docId: string, data: Partial<Job>) {
    if (data?.state) {
      data.history = arrayUnion({
        state: data?.state,
        updatedAt: Timestamp.now(),
      });

      // Delete appointment if job is cancelled
      if (data?.state === State.job_cancelled) {
        data.appointment = {};
        this.getJob(docId)
          .pipe(take(1))
          .subscribe((j) => {
            if (j.appointment?.Id)
              this.deleteAppointment(j.appointment.Id).catch();
          });
      } else if (
        data?.state === State.submitted ||
        data?.state === State.quote_accepted
      ) {
        console.log('here');

        const spId = this.env.clientSpUids[0];

        this.http
          .get(
            `https://us-central1-${this.env.firebaseConfig.projectId}.cloudfunctions.net/sendPushMessage?spId=${spId}&state=${data?.state}&link=${this.env.url}`
          )
          .pipe(take(1))
          .subscribe((s) => console.log({ s }));
      }
    }
    const jobRef = doc(this.afs, `jobs/${docId}`);
    return updateDoc(jobRef, data).catch();
  }

  updateJobBasic(docId: string, data: Partial<JobBasic>) {
    const jobBasicRef = doc(this.afs, `jobs_basic/${docId}`);
    return updateDoc(jobBasicRef, data).catch();
  }

  updateJobViews(jobId: string) {
    this.updateJob(jobId, { views: increment(1) as any }).catch();
  }

  addJobLike(jobId: string, uid: any) {
    return this.updateJob(jobId, {
      likes: arrayUnion({
        authorId: uid,
        createdAt: Timestamp.now(),
      }) as any,
    }).catch();
  }

  removeJobLike(jobId: string, like: Like) {
    return this.updateJob(jobId, {
      likes: arrayRemove(like) as any,
    }).catch();
  }

  addAppointment(data: any) {
    const document = doc(this.appointmentsColl);
    data.Id = document.id;
    return setDoc(document, data, { merge: true }).then(() => document.id);
  }

  updateAppointment(id: any, data: any) {
    const appointmentRef = doc(this.afs, `appointments/${id}`);
    return updateDoc(appointmentRef, data).catch();
  }

  async addService(data: Partial<Service>) {
    const document = doc(this.servicesColl);
    data.id = document.id;
    await setDoc(document, data, { merge: true });
    return document.id;
  }

  async updateService(data: Partial<Service>) {
    const serviceRef = doc(this.afs, `services/${data.id}`);
    return updateDoc(serviceRef, data);
  }

  async deleteService(id: string) {
    const serviceRef = doc(
      this.afs,
      `services/${id}`
    ) as DocumentReference<Service>;
    return deleteDoc(serviceRef);
  }

  getServiceDocs(): Observable<Service[]> {
    console.log('called');

    return collectionData(this.servicesColl, { idField: 'id' }).pipe(
      map((arr) => {
        const values = arr.map((data) => {
          const {
            _ft_updatedBy,
            _ft_updatedAt,
            updatedBy,
            updatedAt,
            ...rest
          } = data;

          return { ...rest };
        });
        return values;
      }),
      catchError((x) => of(x))
    );
  }

  getAppointments(fromDate: any, toDate: any): Observable<Appointment[]> {
    return collectionData<Appointment>(
      query<Appointment>(
        this.appointmentsColl,
        where('confirmed', '==', true),
        where('StartTime', '>=', fromDate),
        where('StartTime', '<', toDate)
      )
    ).pipe(
      map((arr) => {
        this.LOADING.next(true);
        return arr.map((data) => {
          data.StartTime = data.StartTime.toDate();
          data.EndTime = data.EndTime.toDate();
          return data;
        });
      }),
      catchError((x) => of(x))
    );
  }

  deleteAppointment(id: string) {
    const appointmentRef = doc(
      this.afs,
      `appointments/${id}`
    ) as DocumentReference<Appointment>;
    return deleteDoc(appointmentRef).catch();
  }

  getAppointment(id: string): any {
    const appointmentRef = doc(
      this.afs,
      `appointments/${id}`
    ) as DocumentReference<Appointment>;
    return docData<Appointment>(appointmentRef, { idField: 'id' as any }).pipe(
      map((data) => {
        const d = JSON.parse(JSON.stringify(data));
        d.StartTime = data?.StartTime?.toDate();
        d.EndTime = data?.EndTime?.toDate();
        return d;
      }),
      catchError((x) => of(x))
    );
  }

  addNoteToJob(jobId: string, message: string) {
    return this.updateJob(jobId, {
      notes: arrayUnion({
        createdAt: Timestamp.now(),
        message,
      }) as any,
    });
  }
}
