import { Storage } from '@ionic/storage-angular';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth, authState } from '@angular/fire/auth';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class IiraabWelcomeGuard {
  constructor(
    public storage: Storage,
    public router: Router,
    private afAuth: Auth
  ) {}

  async canLoad(): Promise<boolean> {
    return authState(this.afAuth)
      .pipe(take(1))
      .toPromise()
      .then((res) => {
        console.log({ res });
        if (!res || res.isAnonymous) {
          console.log('user not found so go to welcome page');
          return true;
        } else {
          console.log('user found so go to home page');
          this.router.navigate(['home']).catch();
          return false;
        }
      });
  }
}
