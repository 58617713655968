import { catchError, map, skipWhile, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { Address, User } from '@radium/ui';
import { Platform } from '@ionic/angular';
import { getFunctions, httpsCallable } from '@angular/fire/functions';

import {
  Auth,
  createUserWithEmailAndPassword,
  getIdTokenResult,
} from '@angular/fire/auth';
import {
  arrayRemove,
  arrayUnion,
  collection,
  collectionData,
  CollectionReference,
  deleteDoc,
  doc,
  docData,
  DocumentReference,
  Firestore,
  getDoc,
  query,
  Query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from '@angular/fire/firestore';

@Injectable({ providedIn: 'root' })
export class FirebaseUserService {
  usersCollection;

  constructor(
    private afs: Firestore,
    public platform: Platform,
    private auth: Auth
  ) {
    this.usersCollection = collection(
      this.afs,
      'users'
    ) as CollectionReference<User>;
  }

  // const notesRef = collection(this.firestore, 'notes');
  // return addDoc(notesRef, note);

  async createNewUserDoc(
    email: string,
    password: string,
    data
  ): Promise<string> {
    const { user } = await createUserWithEmailAndPassword(
      this.auth,
      email,
      password
    );
    const userRef = doc(this.afs, 'users', user.uid) as DocumentReference<User>;
    return await setDoc(userRef, data).then(() => userRef.id);
  }

  async createUserDoc(
    uid: string,
    data: User,
    extraData?: Record<string, any>
  ) {
    const userRef = doc(this.afs, 'users', uid) as DocumentReference<User>;
    return setDoc(
      userRef,
      {
        ...data,
        ...(extraData ? { clients: [extraData.client] } : {}),
      },
      { merge: true }
    );
  }

  async updateUserDoc(
    uid: string,
    data: Partial<User>,
    extraData?: Record<string, any>
  ) {
    const userRef = doc(this.afs, 'users', uid) as DocumentReference<User>;

    return await setDoc(userRef, {
      ...data,
      ...(extraData ? { clients: arrayUnion(extraData.client) } : {}),
    } as any);
  }

  getUserDoc(uid: string): Observable<User> {
    const userRef = doc(this.afs, `users/${uid}`);

    return docData(userRef, { idField: 'id' }).pipe(
      catchError((x: any) => of(x))
    ) as Observable<User>;
  }

  async userExists(uid: string): Promise<boolean> {
    const userRef = doc(this.afs, `users/${uid}`);
    const getD = await getDoc(userRef);
    return getD.exists();
  }

  getUserType(uid: string) {
    return this.getUserDoc(uid).pipe(
      skipWhile((x) => !x),
      map((x: any) => x.type)
    );
  }

  deleteUserDoc(uid: string) {
    const userRef = doc(this.afs, `users/${uid}`);
    return deleteDoc(userRef).catch();
  }

  // delete anon user
  deleteAnonUser(uid) {
    // call callable firebase function
    const functions = getFunctions();
    const deleteAnonUser = httpsCallable(functions, 'deleteAnonUser');
    return deleteAnonUser({ uid });
  }

  getAllUserDocs(): Observable<User[]> {
    return collectionData<User>(query<User>(this.usersCollection), {
      idField: 'id' as any,
    }).pipe(catchError((x) => of(x)));
  }

  createAnonUserDoc(uid: string, extraData?: Record<string, any>) {
    const data: User = {
      uid,
      type: 'normalUser',
      approved: false,
      infoComplete: false,
      displayName: 'Anonymous User',
      role: 'user',
      acceptAgreement: false,
      createdAt: Timestamp.now(),
      contactNumber: '',
      disabled: false,
      email: '',
      isAnon: true,
      addresses: [],
    };

    return this.createUserDoc(uid, data, extraData).then(() => {
      console.log('Anonymous user created!');
      return data; // Return the created user data
    });
  }

  addAddress(uid: string, address: Address) {
    this.updateUserDoc(uid, {
      addresses: arrayUnion(address) as any,
    }).catch();
  }

  removeAddress(uid: string, address: Address) {
    this.updateUserDoc(uid, {
      addresses: arrayRemove(address) as any,
    }).catch();
  }

  async isAdmin(): Promise<boolean> {
    const idTokenResult = await getIdTokenResult(this.auth.currentUser, true);
    console.log(idTokenResult);
    return !!idTokenResult?.claims?.isAdmin;
  }

  async isSp(): Promise<boolean> {
    const idTokenResult = await getIdTokenResult(this.auth.currentUser, true);
    return !!idTokenResult?.claims?.serviceProvider;
  }

  isBigDevice() {
    return of(
      (this.platform.is('mobile') &&
        !this.platform.is('ipad') &&
        !this.platform.is('tablet')) ||
        this.platform.is('iphone') ||
        this.platform.is('android')
    ).pipe(take(1));
  }

  isBigTouchDevice() {
    return this.platform.is('ipad') ?? this.platform.is('tablet');
  }

  async getUserDocByEmail(email: string) {
    const q: Query<User> = query(
      this.usersCollection,
      where('email', '==', email)
    );
    return firstValueFrom(
      collectionData(q, { idField: 'id' as any }).pipe(map((x) => x[0]))
    );
  }

  // async getUserDocByEmail(
  //   email: string
  // ): Promise<{ id: string; data: User } | undefined> {
  //   const q: Query<User> = query(
  //     this.usersCollection,
  //     where('email', '==', email)
  //   );
  //   const querySnapshot = await getDocs(q);
  //   if (querySnapshot.empty) {
  //     return undefined;
  //   } else {
  //     const docSnapshot = querySnapshot.docs[0];
  //     const docId = docSnapshot.id;
  //     const docData = docSnapshot.data();
  //     return { id: docId, data: docData };
  //   }
  // }
}
