import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'radium-ts-and-cs',
  templateUrl: './ts-and-cs.component.html',
  styleUrls: ['./ts-and-cs.component.scss'],
})
export class TsAndCsComponent {
  @Input() tcsForm?: FormGroup;
  @Input() type?: string;
  @Input() isBigDevice?: boolean;
}
