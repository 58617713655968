import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { sort } from 'ramda';

@Component({
  selector: 'radium-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss'],
})
export class FormModalComponent implements OnInit, OnDestroy {
  @Input() title = 'Add Field';
  @Input() canDismiss = true;
  @Input() incomingData: any;
  @Input() ignoreFields: string[] = [];
  @Input() form = new FormGroup({});
  @Input() model = {};
  @Input() fields: FormlyFieldConfig[] = [];

  @ViewChild('optionInput') optionInput: any;

  formSub: Subscription;
  show = true;
  _fields: FormlyFieldConfig[] = [];

  constructor(public modalCtrl: ModalController) {}

  ngOnInit() {
    // sorted so that required fields are at the top
    const diff = (a, b) => a.props?.required && !b.props?.required && -1;

    const sortedFields = sort(diff, this.fields);

    this._fields = [...sortedFields];

    setTimeout(() => {
      this.formSub = this.form.get('type')?.valueChanges.subscribe((val) => {
        console.log({ val });
        this.show = false;
        if (val === 'select' || val === 'radio' || val === 'multiselect') {
          this._fields.push({
            key: 'options',
            type: 'tags',
            props: {
              label: 'Field Options',
              placeholder: 'Add Field Options',
              required: true,
              _once: true,
              once: true,
              tagPlaceholder: '+Option',
            },
          });
          setTimeout(() => (this.show = true), 300);
        } else {
          this._fields = this._fields.filter((f) => f.key !== 'options');
          setTimeout(() => (this.show = true), 300);
        }
      });
    }, 1000);

    console.log(this.incomingData);

    if (this.incomingData) {
      const clone = JSON.parse(JSON.stringify(this.incomingData));
      console.log(this.ignoreFields);
      for (const prop of this.ignoreFields) delete clone[prop];

      const { uid, defaultValue, options, type, ...rest } = clone;

      setTimeout(() => {
        this.form.patchValue({
          ...rest,
        });

        this.show = false;
        if (type === 'select' || type === 'radio' || type === 'multiselect') {
          this._fields.push({
            key: 'options',
            type: 'tags',
            defaultValue: options,
            props: {
              label: 'Field Options',
              placeholder: 'Add Field Options',
              required: true,
              _once: true,
              once: true,
              tagPlaceholder: '+Option',
            },
          });
          setTimeout(() => (this.show = true), 300);
        } else {
          this._fields = this._fields.filter((f) => f.key !== 'options');
          setTimeout(() => (this.show = true), 300);
        }
      }, 500);
    }
  }

  save() {
    this.modalCtrl
      ?.dismiss({ ...this.form.value, ...{ id: this.incomingData?.id } })
      .catch();
  }

  close(val?) {
    this.modalCtrl?.dismiss().catch();
  }

  ngOnDestroy() {
    this.formSub?.unsubscribe();
    this.form.reset();
  }
}
