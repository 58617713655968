// import { FirePoint } from 'geofirex';

import { GeoPoint } from 'firebase/firestore';

export interface Address {
  province: string;
  city: string;
  addressId: string;
  addressType: addressType;
  verificationChannel?: string;
  specialInstructions?: string;
  requiresValidation: boolean;
  locationId?: string;
  contactNumber: string;
  longitude: number;
  complex?: string;
  businessName?: string;
  suburb: string;
  streetAddress: string;
  streetNumber: string;
  postalCode: string;
  countryCode: string;
  latitude: number;
  recipient: string;
  createdAt?: any;
  updatedAt?: any;
  country: string;
  position: GeoPoint;
  inUse?: boolean;
}

export enum addressType {
  'Business' = 1,
  'Residence',
}
