import { createStore } from 'zustand/vanilla';
import { devtools } from 'zustand/middleware';
import { Injectable } from '@angular/core';
import { ZustandBaseService } from 'ngx-zustand';
import { StateCreator } from 'zustand';

export interface OrgState {
  isAdmin: boolean;
  webName: string;
  isClientSite: boolean;
  orgId: string;
  orgContactNumber: string;
  urlParams: Record<string, any>;
  action: string;
  setAction: (action: string) => void;
}

@Injectable({
  providedIn: 'root',
})
export class ZustandService extends ZustandBaseService<OrgState> {
  initStore(): StateCreator<OrgState> {
    return (set) => ({
      isAdmin: false,
      webName: 'Radium',
      isClientSite: false,
      orgId: 'new',
      orgContactNumber: '',
      urlParams: {},
      setWebName: () => set((state) => ({ webName: state.webName })),
      setIsClientSite: () =>
        set((state) => ({ isClientSite: state.isClientSite })),
      setOrgId: () => set((state) => ({ orgId: state.orgId })),
      // decrement: () => set((state) => ({ counter: state.counter - 1 })),
      action: '',
      setAction: (action) => set({ action }),
    });
  }

  override createStore() {
    return createStore(devtools(this.initStore()));
  }
}
