import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'radium-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  @Input() submitButtonText?: string;
  @Input() data?: any;
  @Input() clientName?: string;
  contactForm?: FormGroup;

  @Output() contactUsEvent = new EventEmitter();

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      typeOfInquiry: [''],
      name: [this.data?.displayName],
      typeOfInquirer: [''],
      email: [this.data?.email],
      phoneNo: [''],
      location: [''],
      howHear: [''],
      message: [''],
    });
  }

  submit() {
    this.contactUsEvent.emit(this.contactForm?.value);
  }

  adhocJobEvent(e: { type: string; data?: string }) {
    if (e.type === 'closeModal') {
      this.contactUsEvent.emit();
    } else if (e.type === 'addJob') {
      this.contactUsEvent.emit(e.data);
    }
  }
}
