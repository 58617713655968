import { Storage } from '@ionic/storage-angular';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth, authState } from '@angular/fire/auth';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LoggedInGuard {
  constructor(
    public storage: Storage,
    public router: Router,
    private afAuth: Auth
  ) {}

  async canLoad(): Promise<boolean> {
    return authState(this.afAuth)
      .pipe(take(1))
      .toPromise()
      .then((res) => {
        if (res && !res.isAnonymous) {
          // the user is authenticated, so redirect to the home page
          this.router.navigate(['/home']);
          return false;
        } else {
          return true;
        }
      });
  }
}
