import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'radium-payfast-return',
  templateUrl: './payfast-return.component.html',
  styleUrls: ['./payfast-return.component.css'],
})
export class PayfastReturnComponent implements OnInit {
  @Input() paymentSucceeded: boolean;

  @Input() payfastComplete: boolean;

  @Input() paymentId: any;

  @Input() fromPayfast: boolean;

  @Output() eventFromPayfastComp = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
