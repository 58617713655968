import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Job, State } from '../../models';

@Component({
  selector: 'radium-job-preview',
  templateUrl: './job-preview.component.html',
  styleUrls: ['./job-preview.component.scss'],
})
export class JobPreviewComponent {
  @Input() canEdit!: boolean;
  @Input() job!: Job;
  @Output() jobPreviewAction: EventEmitter<{
    type: 'open' | 'cancel';
    id: string;
    data?: any;
  }> = new EventEmitter();

  states = State;

  doAction(type: 'open' | 'cancel', id: string, data?: any) {
    console.log('doAction', type, id, data);
    this.jobPreviewAction.emit({ type, id, data });
  }
}
