import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'radium-stepper-buttons',
  templateUrl: './stepper-buttons.component.html',
  styleUrls: ['./stepper-buttons.component.scss'],
})
export class StepperButtonsComponent implements OnInit {
  @Input() disabled?: boolean;
  @Input() disabledBack?: boolean;
  @Input() nextButtonText?: string;
  @Input() isFinal?: boolean;
  @Input() isFirst?: boolean;
  @Input() pushLeft = true;
  @Input() customBackButtonStyles = {};
  @Input() customForwardButtonStyles = {};

  backButtonStyles;
  forwardButtonStyles;

  @Output() clickEmit = new EventEmitter();

  ngOnInit() {
    this.forwardButtonStyles = {
      ...(this.isFinal
        ? { width: '70%' }
        : this.isFirst
        ? {
            marginTop: '10px',
            marginBottom: '20px',
            marginLeft: '70%',
          }
        : null),
      ...(this.customForwardButtonStyles || {}),
    };

    this.backButtonStyles = {
      ...(this.isFinal ? { margin: '10px' } : { margin: '5px' }),
      ...(this.customBackButtonStyles || {}),
    };
  }
}
