// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// import { version } from "../../package.json";

export const environment = {
  // appVersion: version + "-dev",
  production: false,
  clientId:
    '855877753006-pfob72cd6k4v42p7fh0fiupltv9seud1.apps.googleusercontent.com',
  firebaseConfig: {
    apiKey: 'AIzaSyC8ENFU4APz-CbiiR5RQ5ixjNtnXScfBpE',
    authDomain: 'hungerhasnoreligion-dev.firebaseapp.com',
    projectId: 'hungerhasnoreligion-dev',
    storageBucket: 'hungerhasnoreligion-dev.appspot.com',
    messagingSenderId: '52048934890',
    appId: '1:52048934890:web:584d923dd4ab15d8616493',
    measurementId: 'G-4FN63YJ4G7',
  },
  storageConfig: {
    customMetadata: {
      app: 'Hunger Has No Religion',
    },
    bucketName: 'lazImages',
  },
  url: 'https://laz.web.app',
  fn_url: 'https://us-central1-laz.cloudfunctions.net/',
  clientName: 'Hunger Has No Religion',
  clientAddress: '24 Ashley Road, Homestead Park, Johannesburg, 2092',
  clientSpUids: ['5vWIFkqS8LW9hHmiqRHBebDMcCS2'],
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
