<ion-content class="auth-page">
  <!-- TODO: add messages on login screen -->

  <!--  <ion-grid style="min-height: 100%">-->
  <!--      <p-->
  <!--        *ngIf="message && !orgName"-->
  <!--        [ngClass]="isBigDevice ? 'bigClass' : ''"-->
  <!--        class="ion-padding-horizontal ion-padding-top ion-text-center"-->
  <!--        style="font-size: 25px; position: relative; left: 0; right: 0"-->
  <!--      >-->
  <!--        {{ message }}-->
  <!--      </p>-->
  <!--    <p-->
  <!--      *ngIf="orgName"-->
  <!--      [ngClass]="isBigDevice ? 'bigClass' : ''"-->
  <!--      class="ion-padding-horizontal ion-padding-top ion-text-center"-->
  <!--      style="-->
  <!--        font-size: 30px;-->
  <!--        position: absolute;-->
  <!--        left: 0;-->
  <!--        right: 0;-->
  <!--        line-height: normal;-->
  <!--      "-->
  <!--    >-->
  <!--      Welcome to {{ orgName | titlecase }}-->
  <!--    </p>-->

  <!--    <br />-->

  <!--      [titleText]="-->
  <!--        orgName-->
  <!--          ? 'You need to be signed in to continue. Please login or create a new free account'-->
  <!--          : message && !orgName-->
  <!--          ? message-->
  <!--          : 'Create a free account'-->
  <!--      "-->
  <div class="auth-providers" *ngIf="isAnonymous">
    <ion-text class="ion-padding">
      Or {{ isAnonymous ? 'sign up' : 'sign in' }} with:
    </ion-text>
    <ngx-auth-firebaseui-providers
      layout="column"
      [providers]="['google']"
    ></ngx-auth-firebaseui-providers>
  </div>
  <ngx-auth-firebaseui-register
    [logoUrl]="logoUrl"
    (onError)="errorCallback($event)"
    (onLoginRequested)="isAnonymous = false"
    (onSuccess)="successCallback($event)"
    *ngIf="isAnonymous"
    emailErrorPatternText="Email address invalid, also make sure there are no spaces!"
    messageOnAuthSuccess="Registration successful"
    [tosUrl]="tosUrl"
    [privacyPolicyUrl]="privacyPolicyUrl"
    [ngClass]="redirectUrl === '/register' ? 'noPointer' : ''"
    (click)="getTos($event)"
  >
  </ngx-auth-firebaseui-register>

  <ngx-auth-firebaseui-login
    [logoUrl]="logoUrl"
    (onCreateAccountRequested)="isAnonymous = true"
    (onError)="errorCallback($event)"
    (onSuccess)="successCallback($event, true)"
    *ngIf="!isAnonymous"
    [providers]="['google']"
    [resetPasswordEnabled]="true"
    emailErrorPatternText="Email address invalid, also make sure there are no spaces!"
    messageOnAuthSuccess="Login successful"
  >
  </ngx-auth-firebaseui-login>
  <!--  </ion-grid>-->
</ion-content>
