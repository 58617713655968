import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable, of, Subscription } from 'rxjs';
import { LoadingService } from '@radium/core';
import { Service } from '@radium/ui';
import { FieldSettingsModel, ListView } from '@syncfusion/ej2-angular-lists';
import { Tooltip } from '@syncfusion/ej2-angular-popups';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'radium-service-add-edit',
  templateUrl: './service-add-edit.component.html',
  styleUrls: ['./service-add-edit.component.scss'],
})
export class ServiceAddEditComponent implements OnInit, OnDestroy {
  @ViewChild('listView') listView?: ListView;
  @ViewChild('tooltip') tooltip?: Tooltip;
  @Input() margin: string;
  @Input() services$?: Observable<Service[]>;

  @Output() serviceSelected = new EventEmitter();
  @Output() serviceRemoved = new EventEmitter();

  _dataManager: Observable<{ [p: string]: Service }[]>;

  categoryGroups: { [key: string]: Service[] } = {};
  fields: FieldSettingsModel = {
    tooltip: 'name',
  };
  headerTitle = 'Services';
  loungeTitle = false;
  sub?: Subscription;

  constructor(private loadingService: LoadingService) {}

  ngOnInit() {
    this.loadingService
      .loadingPresent('Getting services ...', 'services')
      .then(() => {
        this._dataManager = this.services$?.pipe(
          map((services: Service[]) => {
            console.log({ services });
            const categories: Set<string> = new Set(
              services.map((service) => service['category'] ?? 'Root')
            );

            categories.forEach(
              (category) => (this.categoryGroups[category] = [])
            );

            services.forEach((service) =>
              service.category
                ? this.categoryGroups[service.category]?.push(service)
                : this.categoryGroups['Root']?.push(service)
            );

            Object.entries(this.categoryGroups).map(
              ([key, val]) =>
                (this.categoryGroups[key] = val.sort((a: any, b: any) =>
                  a?.position < b?.position
                    ? -1
                    : a?.position > b?.position
                    ? 1
                    : 0
                ))
            );
            setTimeout(
              () => this.loadingService.loadingDismiss('services').catch(),
              500
            );
            return this.categoryGroups['Root'];
          }),
          catchError((x) => {
            this.loadingService.loadingDismiss('services').catch();
            return of(x);
          })
        );
      })
      .catch();
  }

  select(e: any) {
    if (this.categoryGroups[e.data?.text]) {
      e.data.child = this.categoryGroups[e.data.text];
    }

    if (e.event.defaultPrevented) {
      delete e.data;
      e.isInteracted = false;
      e.item.classList.remove('e-active');
      e.item.classList.remove('e-has-child');
      return;
    } else if (e.data.child) {
      e.item.classList.add('e-has-child');
    }
    this.loungeTitle = e.data.category?.includes('Lounge');

    this.headerTitle = 'Services';

    setTimeout(() => {
      if (!e.data.child && !e.data.text?.includes('COMING SOON')) {
        // this.openModal({ selectedJob: e.data }).catch();
        console.log({ e });

        // this.listView?.hideItem(e);
        this.listView?.refresh();
        this.serviceSelected.emit(e.data);
      }
    }, 20);
  }

  actionComplete(event: any) {
    // this.selectedService.emit({ selectedService: undefined });
  }

  showInfo(e: any, details: any) {
    e.preventDefault();
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }
}
