import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { UiModule } from '@radium/ui';
import { ScreensModule } from '@radium/screens';
import { MaterialModule } from '@radium/material';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicStorageModule } from '@ionic/storage-angular';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import {
  enableIndexedDbPersistence,
  getFirestore,
  provideFirestore,
} from '@angular/fire/firestore';
import {
  getRemoteConfig,
  provideRemoteConfig,
} from '@angular/fire/remote-config';
import {
  getAnalytics,
  provideAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getPerformance, providePerformance } from '@angular/fire/performance';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { Loader } from '@googlemaps/js-api-loader';

// import { SplashScreenModule } from './components';
// import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
// import { Geolocation } from '@ionic-native/geolocation/ngx';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }),
    AppRoutingModule,
    NgxAuthFirebaseUIModule.forRoot(environment.firebaseConfig, () => '', {
      enableFirestoreSync: true, // enable/disable autosync users with firestore
      toastMessageOnAuthSuccess: false, // whether to open/show a snackbar message on auth success - default : true
      toastMessageOnAuthError: false, // whether to open/show a snackbar message on auth error - default : true
      authGuardFallbackURL: '/login', // url for unauthenticated users - to use in combination with canActivate feature on a route
      authGuardLoggedInURL: '/home', // url for authenticated users - to use in combination with canActivate feature on a route
      passwordMaxLength: 60, // `min/max` input parameters in components should be within this range.
      passwordMinLength: 8, // Password length min/max in forms independently of each component min/max.
      // Same as password but for the name
      nameMaxLength: 50,
      nameMinLength: 2,
      // If set, sign-in/up form is not available until email has been verified.
      // Plus protected routes are still protected even though user is connected.
      guardProtectedRoutesUntilEmailIsVerified: false,
      enableEmailVerification: false,
    }),
    IonicStorageModule.forRoot(),
    BrowserAnimationsModule,
    MatPasswordStrengthModule.forRoot(),
    // AgmCoreModule.forRoot({
    //   apiKey: environment.firebaseConfig.apiKey,
    //   libraries: ['places'],
    //   region: 'za',
    // }),
    NgxGpAutocompleteModule,
    UiModule.forRoot(environment),
    ScreensModule.forRoot(environment),
    MaterialModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    provideFirestore(() => {
      const firestore = getFirestore();
      enableIndexedDbPersistence(firestore).catch();
      return firestore;
    }),

    provideRemoteConfig(() => getRemoteConfig()),
    provideAnalytics(() => getAnalytics()),
    provideMessaging(() => getMessaging()),
    providePerformance(() => getPerformance()),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),

    provideStorage(() => {
      return getStorage();
    }),
    provideFunctions(() => {
      return getFunctions();
    }),
    // SplashScreenModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: environment.production,
    // }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    // Geolocation,
    ScreenTrackingService,
    UserTrackingService,
    // SwUpdate,
    // {
    //   provide: ErrorHandler,
    //   useClass: ErrorService
    // },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: environment.firebaseConfig.apiKey,
        libraries: ['places'],
        region: 'za',
        language: 'en',
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
