import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Image } from '../../models';

@Component({
  selector: 'radium-preview-images',
  templateUrl: './preview-images.component.html',
  styleUrls: ['./preview-images.component.scss'],
})
export class PreviewImagesComponent {
  @Input() files: Image[] = [];
  @Input() isBigDevice?: boolean;
  @Input() canAddImage?: boolean;
  @Input() canDelete?: boolean;
  @Output() imageAction = new EventEmitter();
  @Output() previewAction = new EventEmitter();

  imgError?: boolean;
  imgLoading?: boolean;
  @Input() cameraButtonPosition?: string;

  actionTheImage(action: string, index?: any) {
    this.imageAction.emit({ action, index });
  }

  actionThePreview(action: string) {
    this.previewAction.emit({ action });
  }

  imageLoadStart(e: Event) {
    console.log({ e });
    this.imgLoading = true;
  }
}
