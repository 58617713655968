<ion-header>
  <ion-toolbar color="primary">
    <!--  menu  -->
    <ion-buttons *ngIf="!isBigDevice && showMenuButton" slot="start">
      <ion-menu-button color="light" [autoHide]="false"></ion-menu-button>
    </ion-buttons>

    <!--  back   -->
    <ion-buttons
      *ngIf="
        (isBigDevice && showBackButton) || (!isBigDevice && showBackButton)
      "
      slot="start"
    >
      <ion-back-button color="light" defaultHref="home"></ion-back-button>
    </ion-buttons>

    <!--  img   -->
    <ion-buttons
      *ngIf="showImg && img"
      slot="start"
      class="ion-padding-start ion-margin-start"
    >
      <ion-img style="width: 70%; background: white" [src]="img"></ion-img>
    </ion-buttons>

    <!--  filter   -->
    <ion-button
      slot="end"
      fill="none"
      *ngIf="showFilterIcon && !showSearch && !showFilter"
      (click)="showFilter = true"
    >
      <ion-icon name="filter" color="dark"></ion-icon>
    </ion-button>

    <!--    search -->
    <ion-button
      slot="end"
      fill="none"
      *ngIf="showSearchIcon && !showSearch && !showFilter"
      (click)="showSearch = true"
    >
      <ion-icon name="search" color="dark"></ion-icon>
    </ion-button>

    <!--    pageName -->
    <ion-title
      slot="start"
      class="ion-no-padding ion-padding-start"
      *ngIf="isBigDevice || (!isBigDevice && !showSearch && !showFilter)"
    >
      <p
        style="font-size: 15px; margin-bottom: 0"
        class="ion-no-margin ion-no-padding"
      >
        {{ pageName }}
      </p>
    </ion-title>

    <!--   other buttons with links -->
    <ion-buttons slot="end" *ngIf="isBigDevice && buttons.length > 0">
      <ion-button
        [color]="button.color ?? 'clear'"
        *ngFor="let button of buttons"
        [fill]="button.fill ?? 'solid'"
        [routerLink]="button.link"
        [disabled]="button.disabled"
        (click)="button.onClick()"
        class="ion-padding-horizontal"
      >
        {{ button.text }}
      </ion-button>
    </ion-buttons>

    <!--    searchbar -->
    <ion-searchbar
      showCancelButton="focus"
      color="light"
      (ionCancel)="cancelSearch()"
      *ngIf="showSearch"
      (ionChange)="searchQuery.emit($event)"
      debounce="500"
    ></ion-searchbar>

    <!--    filter by tags-->
    <tag-input
      *ngIf="showFilter"
      secondaryPlaceholder="Select a filter"
      placeholder="add filter"
      [ngModel]="incomingFilters"
      (ngModelChange)="selectedFilters($event)"
      [ngModelOptions]="{ standalone: true }"
      [maxItems]="2"
      [editable]="false"
      theme="dark"
      [onlyFromAutocomplete]="true"
    >
      <tag-input-dropdown
        [focusFirstElement]="true"
        [showDropdownIfEmpty]="true"
        [autocompleteItems]="filterItems"
      >
      </tag-input-dropdown>
    </tag-input>

    <!--    cancel search -->
    <ion-button
      slot="end"
      fill="none"
      *ngIf="showFilter"
      (click)="cancelSearch()"
    >
      <ion-icon name="close" color="dark"></ion-icon>
    </ion-button>

    <!--   toggle -->
    <ion-toggle
      *ngIf="showToggle"
      slot="end"
      [enableOnOffLabels]="true"
      (ionChange)="headerEvent.emit($event)"
      color="dark"
    ></ion-toggle>

    <!--  cart -->
    <ion-menu-toggle
      *ngIf="!showFilter && showCart"
      auto-hide="false"
      class="ion-padding-end"
      slot="end"
    >
      <ion-menu-button class="ion-no-padding ion-no-margin" menu="cart-menu">
        <ion-icon name="cart-outline"></ion-icon>

        <ion-badge *ngIf="count > 0" style="padding: 2px" color="tertiary">
          {{ count }}
        </ion-badge>
      </ion-menu-button>
    </ion-menu-toggle>

    <!-- Profiles section -->
    <!--  this should be an avatar, which has a dropdown of actions -->
    <ion-buttons slot="end" *ngIf="showProfile">
      <ion-button>
        <ion-icon name="person-circle-outline"></ion-icon>
        <ion-menu-button menu="profile-menu"></ion-menu-button>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
