import { Component } from '@angular/core';
import { ZustandService } from '@radium/core';

@Component({
  selector: 'radium-actions-dropdown',
  templateUrl: './actions-dropdown.component.html',
  styleUrls: ['./actions-dropdown.component.scss'],
})
export class ActionsDropdownComponent {
  rowData: any;
  value: string | number;

  constructor(private zust: ZustandService) {}

  onCustomAction(action: any) {
    this.zust.getState().setAction(action);
  }
}
