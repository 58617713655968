import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { LoadingService } from '@radium/core';

@Injectable({ providedIn: 'root' })
export class ImageProcessingService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private sanitizer: DomSanitizer,
    private LS: LoadingService
  ) {}

  async resizeImage(file: File): Promise<File> {
    if (file.size > 500000) {
      await this.LS.loadingPresent('...', 'resizeImage').catch();

      return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = async () => {
          const result = await this.onNewImg(img.height, img.width, img, file);
          this.LS.loadingDismiss('resizeImage').catch();
          resolve(result);
        };

        img.onerror = (error) => {
          this.LS.loadingDismiss('resizeImage').catch();
          reject(error);
        };
      });
    }
    return file;
  }

  private onNewImg(
    height: number,
    width: number,
    img:
      | HTMLImageElement
      | SVGImageElement
      | HTMLVideoElement
      | HTMLCanvasElement
      | ImageBitmap,
    file: File
  ): Promise<File> {
    console.log('before resize: ', (file.size / (1024 * 1024)).toFixed(2));
    return new Promise((resolve: any) => {
      const canvas: HTMLCanvasElement = document.createElement('canvas');
      const ctx: any = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const MAX_WIDTH = 1000;
      const MAX_HEIGHT = 600;
      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }
      canvas.width = width;
      canvas.height = height;
      const ctx1: any = canvas.getContext('2d');
      ctx1.drawImage(img, 0, 0, width, height);
      const newImage = canvas.toDataURL('image/png');
      const blobBin = atob(newImage.split(',')[1]);
      const array = [];
      for (let i = 0; i < blobBin.length; i++) {
        array.push(blobBin.charCodeAt(i));
      }
      const newBlob = new File([new Uint8Array(array)], file.name, {
        type: 'image/png',
      });
      const newFile = this.createFile(newBlob, file);
      console.log('after resize: ', (newFile.size / (1024 * 1024)).toFixed(2));
      resolve(newFile.rawFile);
    });
  }

  public createFile(image: File, file: File) {
    return {
      name: file.name,
      rawFile: image,
      size: image.size,
      type: file.type,
      validationMessage: '',
      statusCode: '1',
      status: 'Ready to Upload',
    };
  }

  public promptForImage(): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      // make file input element in memory
      const fileInput: HTMLInputElement | any = this.document.createElement(
        'input'
      );
      fileInput.type = 'file';
      fileInput.accept = 'image/*';
      fileInput.setAttribute('capture', 'camera');
      // fileInput['capture'] = 'camera';
      fileInput.addEventListener('error', (event) => {
        reject(event?.error);
      });
      fileInput.addEventListener('change', () => {
        resolve(fileInput?.files[0]);
      });
      // prompt for image file
      fileInput.click();
    });
  }

  public genThujjjmb(imageFile: Blob): Promise<any[]> {
    const image: HTMLImageElement = this.document.createElement('img');
    const canvas: HTMLCanvasElement = this.document.createElement('canvas');
    const context: CanvasRenderingContext2D = canvas.getContext('2d') as any;
    return new Promise<any[]>((resolve, reject) => {
      canvas.addEventListener('error', reject);
      image.addEventListener('error', reject);

      image.addEventListener('canplay', (event) => {
        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(image, 0, 0, image.width, image.height);
        resolve([canvas.toDataURL()]);
      });

      if (imageFile.type) {
        image.setAttribute('type', imageFile.type);
      }

      image.src = window.URL.createObjectURL(imageFile);
    });
  }
}
