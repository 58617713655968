<div class="table" *ngIf="tableConfig?.columns">
  <mat-form-field>
    <mat-label>Filter</mat-label>
    <input
      matInput
      (keyup)="applyFilter($event)"
      placeholder="Ex. Mia"
      #input
    />
  </mat-form-field>
  <table
    mat-table
    [dataSource]="tableDataSource"
    class="table_grid"
    matSort
    [ngStyle]="{ display: tableConfig?.columns?.length > 3 && 'block' }"
  >
    <ng-container
      *ngFor="let column of tableConfig.columns; index as i"
      [matColumnDef]="column.key"
      [sticky]="column?.sticky"
    >
      <td
        mat-cell
        *matCellDef="let tableRow"
        [ngClass]="{ 'numeric-col': !!column.numeric }"
      >
        <span
          [title]="tableRow[column.key]"
          *ngIf="column.type !== 'checkbox' && !column.timestamp"
        >
          {{ tableRow[column.key] }}
        </span>

        <span
          [title]="tableRow[column.key]?.toMillis?.() | date:'yyyy/MM/dd HH:mm'"
          *ngIf="column.timestamp"
        >
          {{ tableRow[column.key]?.toMillis?.() | date:'yy/MM/dd HH:mm' }}
        </span>

        <mat-checkbox
          *ngIf="column.type === 'checkbox'"
          [checked]="tableRow[column.key]"
          [disabled]="true"
        ></mat-checkbox>
      </td>
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [ngClass]="{ 'numeric-col': !!column.numeric }"
      >
        {{ column.heading }}
      </th>
    </ng-container>

    <!-- Edit Column -->
    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef>
        {{ tableConfig?.actions?.columnTitle ?? 'Actions' }}
      </th>
      <td mat-cell *matCellDef="let tableRow" class="row-action-column">
        <button
          mat-icon-button
          matRipple
          class="table_action-button"
          style="border-left: solid 1px grey; border-radius: 0"
          matTooltip="Edit Row"
          matTooltipPosition="left"
          aria-label="Edit Row"
          (click)="doAction('edit', tableRow)"
          [disabled]="!tableConfig?.actions?.edit"
          color="primary"
        >
          <ion-icon size="small" name="pencil"></ion-icon>
        </button>
        <button
          mat-icon-button
          matRipple
          class="table_action-button"
          matTooltip="Delete Row"
          matTooltipPosition="left"
          aria-label="Delete Row"
          color="warn"
          [disabled]="!tableConfig?.actions?.delete"
          (click)="doAction('delete', tableRow)"
        >
          <ion-icon size="small" name="trash"></ion-icon>
        </button>

        <button
          mat-icon-button
          matRipple
          class="table_action-button"
          matTooltip="View Row"
          matTooltipPosition="left"
          aria-label="View Row"
          color="primary"
          *ngIf="tableConfig?.actions?.view"
          [disabled]="!tableConfig?.actions?.view"
          (click)="doAction('view', tableRow)"
        >
          <ion-icon size="small" name="eye"></ion-icon>
        </button>
        <radium-actions-dropdown></radium-actions-dropdown>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns; let i = index"
    ></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td
        class="mat-cell"
        colspan="4"
        style="padding: 15px; border: none"
        *ngIf="!isLoading"
      >
        No data matching the filter
      </td>
    </tr>
  </table>

  <mat-card
    *ngIf="isLoading"
    style="display: flex; justify-content: center; align-items: center"
  >
    <mat-card-content>
      <mat-progress-spinner diameter="30" color="primary" mode="indeterminate">
      </mat-progress-spinner>
    </mat-card-content>
  </mat-card>

  <div *ngIf="!!tableConfig?.actions?.add" class="table_add">
    <button
      mat-fab
      extended
      class="table_action-button"
      matRipple
      matTooltip="Add New Row"
      matTooltipPosition="left"
      aria-label="Add New Row"
      (click)="doAction('add')"
    >
      {{ tableConfig?.addButtonContent }}
      <ion-icon name="add"></ion-icon>
    </button>
  </div>
  <mat-paginator
    *ngIf="tableConfig?.pager?.display"
    [pageSizeOptions]="[tableConfig?.pager?.perPage]"
    aria-label="Select page"
    showFirstLastButtons
  ></mat-paginator>
</div>
