import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Book } from '@radium/ui';

@Component({
  selector: 'radium-book-card',
  templateUrl: './book-card.component.html',
  styleUrls: ['./book-card.component.css'],
})
export class BookCardComponent implements OnInit {
  @Input() book: Book;

  @Input() isBigDevice: boolean;
  @Input() isEditing = false;

  @Output() openItem = new EventEmitter();

  @Output() eventFromBookCard = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
