import { Component, Input } from '@angular/core';

@Component({
  selector: 'radium-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input() startYear = 2019;
  @Input() name = 'Detailers';
  @Input() color = 'primary';
}
