<form
  *ngIf="!clientName?.toLowerCase()?.includes('laz')"
  [formGroup]="contactForm"
  (ngSubmit)="submit()"
>
  <ion-item>
    <ion-label position="floating"
      >Type of Inquiry:
      <ion-text color="danger">*</ion-text>
    </ion-label>

    <ion-select
      interface="popover"
      required
      formControlName="typeOfInquiry"
      placeholder="Select Inquiry"
    >
      <ion-select-option value="inquiry">General inquiry</ion-select-option>
    </ion-select>
  </ion-item>
  <ion-item>
    <ion-label position="floating"
      >E-Mail:
      <ion-text color="danger">*</ion-text>
    </ion-label>
    <ion-input required formControlName="email" type="text"></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="floating"
      >Phone number:
      <ion-text color="danger">*</ion-text>
    </ion-label>
    <ion-input required formControlName="phoneNo" type="text"></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="floating"
      >Location:
      <ion-text color="danger">*</ion-text>
    </ion-label>
    <ion-input required formControlName="location" type="text"></ion-input>
  </ion-item>

  <ion-item>
    <ion-label position="floating"
      >How did you hear about us?
      <ion-text color="danger">*</ion-text>
    </ion-label>

    <ion-select
      interface="popover"
      required
      formControlName="howHear"
      placeholder="Select"
    >
      <ion-select-option value="email">Email</ion-select-option>
      <ion-select-option value="sms">SMS</ion-select-option>
      <ion-select-option value="whatsapp">WhatsApp</ion-select-option>
      <ion-select-option value="fb">Facebook</ion-select-option>
      <ion-select-option value="instagram">Instagram</ion-select-option>
      <ion-select-option value="wordOfMouth">Word of Mouth</ion-select-option>
      <ion-select-option value="other">Other</ion-select-option>
    </ion-select>
  </ion-item>

  <ion-item>
    <ion-label position="floating"
      >Message:
      <ion-text color="danger">*</ion-text>
    </ion-label>
    <ion-input required formControlName="message" type="text"></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="floating"
      >Full Name:
      <ion-text color="danger">*</ion-text>
    </ion-label>
    <ion-input required formControlName="name" type="text"></ion-input>
  </ion-item>
  <ion-item>
    <ion-label position="floating"
      >Company or Organization:
      <ion-text color="danger">*</ion-text>
    </ion-label>
    <ion-input
      required
      formControlName="typeOfInquirer"
      type="text"
    ></ion-input>
  </ion-item>
  <ion-button
    expand="full"
    color="primary"
    type="submit"
    [disabled]="contactForm?.invalid"
  >
    Submit
  </ion-button>
</form>

<radium-adhoc-job
  (adhocJobEvent)="adhocJobEvent($event)"
  *ngIf="clientName.toLowerCase().includes('laz')"
></radium-adhoc-job>
