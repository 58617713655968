import { Pipe, PipeTransform } from '@angular/core';
import { isString } from '../../../../../utils';

@Pipe({ name: 'SpaceSeparated' })
export class SpaceSeparatedPipe implements PipeTransform {
  transform(input: string, chars?: string): string;
  transform(input: any, chars?: string): any;

  transform(text: any, chars: string = '\\s'): string {
    return isString(text)
      ? text
          .trim()
          .replace(/\s+/g, '')
          .replace(/[A-Z]/g, (c: string, k: any) => {
            return k ? ` ${c.toLowerCase()}` : c.toLowerCase();
          })
      : text;
  }
}
