import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { thumbnail } from './thumbnail';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClassroomService {
  // private classroomActiveSubject$ = new BehaviorSubject<boolean>(false);
  // classroomActive$ = this.classroomActiveSubject$.asObservable();
  boundary = '-------314159265358979323846';
  delimiter = '\r\n--' + this.boundary + '\r\n';
  close_delim = '\r\n--' + this.boundary + '--';
  // classroomActive$: Observable<boolean>;

  classroomActive = new BehaviorSubject(false);

  constructor(public http: HttpClient, public platform: Platform) {
    // Listen for sign-in state changes.

    setTimeout(
      () =>
        gapi?.auth2
          ?.getAuthInstance()
          ?.isSignedIn?.listen((x: any) => this.classroomActive.next(x)),
      1000
    );
  }

  getBasicProfile() {
    return gapi.auth2?.getAuthInstance()?.currentUser?.get()?.getBasicProfile();
  }

  // gapiIsSignedIn() {
  //   return gapi.auth2.getAuthInstance().isSignedIn.get();
  // }

  // gapiAfterSignIn(val) {
  //   console.log("Gapi Signin changed to ", val);
  //   this.classroomActive.next(val);
  // }

  getCourses(): Promise<{ [key: string]: any }[]> {
    return gapi?.client?.classroom?.courses
      ?.list({
        courseStates: ['ACTIVE'],
      })
      .then((result: { result: { courses?: { [key: string]: any }[] } }) => {
        if (result?.result?.courses) {
          return result.result.courses;
        } else {
          return [];
        }
      });
  }

  getStudentCourses(): Promise<{ [key: string]: any }[]> {
    return gapi?.client?.classroom?.courses
      .list({
        courseStates: ['ACTIVE'],
        studentId: 'me',
      })
      .then((result: { result: { courses?: { [key: string]: any }[] } }) => {
        if (result?.result?.courses) {
          return result.result.courses;
        } else {
          return [];
        }
      });
  }

  getTeacherCourses() {
    return gapi?.client?.classroom.courses
      .list({
        courseStates: ['ACTIVE'],
        teacherId: 'me',
      })
      .then((result: any) => result?.result?.courses ?? []);
  }

  // {
  //   "courses": [
  //     {
  //       "id": "9829624442",
  //       "name": "JAN 2018 (1st Year)",
  //       "descriptionHeading": "JAN 2018 (1st Year)",
  //       "ownerId": "108773539428074171216",
  //       "creationTime": "2017-12-25T09:26:15.053Z",
  //       "updateTime": "2018-01-26T15:46:34.231Z",
  //       "enrollmentCode": "hw4weg0",
  //       "courseState": "ACTIVE",
  //       "alternateLink": "http://classroom.google.com/c/OTgyOTYyNDQ0Mlpa",
  //       "teacherGroupEmail": "JAN2018_teachers_14e69ed0@classroom.google.com",
  //       "courseGroupEmail": "JAN2018_eb6b9244@classroom.google.com",
  //       "teacherFolder": {
  //         "id": "0ByRItPCt33UefktRQTlrR2E0c1VySEE1c2pHRHh1U19Dc1VzUVVpSnJSMTZuUHNxODRaTU0",
  //         "title": "JAN2018",
  //         "alternateLink":
  //         "https://drive.google.com/drive/folders/0ByRItPCt33UefktRQTlrR2E0c1VySEE1c2pHRHh1U19Dc1VzUVVpSnJSMTZuUHNxODRaTU0"
  //       },
  //       "courseMaterialSets": [
  //         {
  //           "title": "Audio renditions of all chapters of Qasas Part 1 (Story of Ebrahim A.S)",
  //           "materials": [
  //             {
  //               "link": {
  //                 "url": "https://www.dropbox.com/sh/vy2mm4zokc8wls2/AAAchZHPShW7OfCISlqH9md1a?dl=0",
  //                 "title": "Dropbox - Ebrahim (A.S) - Simplify your life",
  //                 "thumbnailUrl":
  //                 "https://www.google.com/webpagethumbnail
  //                 ?c=73&s=105:70&f=0&d=
  //                 https://www.dropbox.com/
  //                 sh/vy2mm4zokc8wls2/AAAchZHPShW7OfCISlqH9md1a?dl%3D0&a=AIYkKU8ZFVwF9H5LlkhIh7gaj2SiGWWDyg"
  //               }
  //             }
  //           ]
  //         },
  //         {
  //           "title": "A list of vocab for Qasas Part 1 (Story of Ebrahim A.S.)",
  //           "materials": [
  //             {
  //               "link": {
  //                 "url": "https://www.dropbox.com/s/8j7aryc1jylqkv2/Vocab%20%28Ebrahim%20%28A.S%29%29.pdf?dl=0",
  //                 "title": "Vocab (Ebrahim (A.S)).pdf",
  //                 "thumbnailUrl":
  //                 "https://www.google.com/webpagethumbnail?c=73&s=105:70&f=0&d=https:
  // www.dropbox.com/s/8j7aryc1jylqkv2/Vocab%2520%2528Ebrahim%2520%2528A.S%2529%2529.pdf?dl%3D0&a=AIYkKU-OJ03D9ni1iVu3HL5JuvO-rJuRJA"
  //               }
  //             }
  //           ]
  //         }
  //       ],
  //       "guardiansEnabled": false,
  //       "calendarId": "classroom101962106918951545580@group.calendar.google.com"
  //     }
  //   ]
  // }

  async getCourseWork(
    courseId: string,
    isCallback?: boolean,
    canvasID?: string
  ) {
    return gapi?.client?.classroom?.courses?.courseWork
      ?.list({
        courseId,
        courseWorkStates: ['PUBLISHED'],
        pageSize: 30,
      })
      .then((result: any) => result?.result?.courseWork ?? [])
      .catch((err: any) => {
        console.log({ err });
        if (
          err?.result?.error?.code === 401 ||
          err?.result?.error?.code === 403
        ) {
          gapi.auth2.getAuthInstance().signOut();
          setTimeout(() => {
            gapi.auth2
              .getAuthInstance()
              .signIn()
              .then(() => {
                if (isCallback) {
                  this.getCurrentAssigment(courseId, canvasID).catch();
                } else {
                  this.getCourseWork(courseId);
                }
              });
          }, 1500);
        }
      });
  }

  async getCurrentAssigment(courseId: string, canvasID: any) {
    let courseWorks;
    try {
      courseWorks = await this.getCourseWork(courseId, true, canvasID);
    } catch (err) {
      console.log({ err }); // TypeError: failed to fetch
    }
    if (courseWorks) {
      const courseworksWithMaterials = courseWorks.filter((c) => c?.materials);
      const reducer = (acc: any, courseWork: any) => {
        const links = courseWork.materials.filter((x: any) => x?.link);
        const courseWorkWithLink = links.map((x: any) => ({
          url: x?.link?.url,
          ...courseWork,
        }));
        return [...acc, ...courseWorkWithLink];
      };
      const courseWorksWithLink = courseworksWithMaterials.reduce(reducer, []);
      // const links = coursesWithMaterials.map(m => m["link"]);
      return courseWorksWithLink.find((x: any) => x?.url?.includes(canvasID));
    }
  }

  getStudentSubmissions(courseId: string, courseWorkId: string) {
    return gapi?.client?.classroom.courses.courseWork.studentSubmissions
      .list({
        courseId,
        courseWorkId,
        states: ['RECLAIMED_BY_STUDENT', 'CREATED'],
        userId: 'me',
      })
      .then((result) => {
        if (
          result.result?.studentSubmissions?.[0].assignmentSubmission
            .attachments
        ) {
          return result.result.studentSubmissions[0];
        } else {
          return [];
        }
      })
      .catch((err: any) => {
        return err;
      });
  }

  getStSubmissions(courseId: string, courseWorkId: string) {
    return gapi?.client?.classroom.courses.courseWork.studentSubmissions.list({
      courseId,
      courseWorkId,
    });
  }

  addCanvasAttachment(
    courseId: string,
    courseWorkId: string,
    driveFileId: any,
    stSubId: string
  ) {
    return gapi?.client?.classroom.courses.courseWork.studentSubmissions.modifyAttachments(
      {
        courseId,
        courseWorkId,
        id: stSubId,
        resource: {
          addAttachments: [
            {
              driveFile: {
                id: driveFileId,
              },
            },
          ],
        },
      }
    );
  }

  turnInAssignment(courseId: string, courseWorkId: string, stSubId: string) {
    return gapi?.client?.classroom.courses.courseWork.studentSubmissions.turnIn(
      {
        courseId,
        courseWorkId,
        id: stSubId,
        resource: {},
      }
    );
  }

  createAssignment(data: { courseId: any; resource: any }) {
    return gapi?.client?.classroom.courses.courseWork.create(data);
  }

  createClass(name: string) {
    return gapi?.client?.classroom.courses.create({
      resource: {
        name: name,
        ownerId: 'me',
      },
    });
  }

  // Drive methods

  async createFileWithJSONContent(name: string, data: string) {
    const parent =
      (await this.getIIraabDriveFolder()) ?? (await this.createDriveFolder());
    const contentType = 'application/json';
    const metadata = {
      name: name,
      mimeType: contentType,
      contentHints: {
        thumbnail: thumbnail,
      },
      parents: [parent],
    };

    return (
      this.delimiter +
      'Content-Type: application/json\r\n\r\n' +
      JSON.stringify(metadata) +
      this.delimiter +
      'Content-Type: ' +
      contentType +
      '\r\n\r\n' +
      data +
      this.close_delim
    );
  }

  async createDriveFolder(): Promise<any> {
    const fileMetadata = {
      name: 'IIRAAB',
      mimeType: 'application/vnd.google-apps.folder',
    };

    let folder: any;
    try {
      folder = await gapi?.client?.drive.files.create({
        resource: fileMetadata,
        fields: 'id',
      });
    } catch (err) {
      return await err;
    }
    return await folder.result.id;
  }

  async getIIraabDriveFolder(): Promise<any> {
    let folder;
    try {
      folder = await gapi?.client?.drive.files.list({
        q: "mimeType = 'application/vnd.google-apps.folder'",
        fields: 'files(id, name)',
        spaces: 'drive',
      });
    } catch (err) {
      return await err;
    }
    const folderDetails = folder?.result?.files?.find(
      (x) => x.name === 'IIRAAB'
    );
    return folderDetails?.id;
  }

  async postToDrive(body: string) {
    return gapi?.client?.request({
      path: '/upload/drive/v3/files',
      method: 'POST',
      params: {
        uploadType: 'multipart',
      },
      headers: {
        'Content-Type': "multipart/related; boundary='" + this.boundary + "'",
      },
      body,
    });
  }

  async createImg(name: string, data: string) {
    const parent =
      (await this.getIIraabDriveFolder()) ?? (await this.createDriveFolder());
    const newData = data.replace(/^data:image\/(png|jpg);base64,/, '');

    const contentType = 'image/png';

    const metadata = {
      name: name,
      mimeType: contentType,
      parents: [parent],
    };

    return (
      this.delimiter +
      'Content-Type: application/json\r\n\r\n' +
      JSON.stringify(metadata) +
      this.delimiter +
      'Content-Type: ' +
      contentType +
      '\r\n' +
      'Content-Transfer-Encoding: base64\r\n' +
      '\r\n' +
      newData +
      this.close_delim
    );
  }

  downloadFile(id: string) {
    return gapi?.client?.drive.files.get({
      fileId: id,
      alt: 'media',
    } as any);
  }

  updateFile(fileId: string, file: string) {
    return gapi?.client?.request({
      path: '/upload/drive/v3/files/' + fileId,
      method: 'PATCH',
      params: {
        uploadType: 'media',
      },
      body: file,
    });
  }

  signOut() {
    if (gapi?.auth2?.getAuthInstance()?.isSignedIn) {
      gapi.auth2.getAuthInstance().signOut();
    }
  }

  signIn() {
    return gapi?.auth2
      ?.getAuthInstance()
      ?.signIn()
      .then((x: any) => {
        this.classroomActive.next(true);
      })
      .catch((err: any) => {
        console.log('error: ', err);
        this.classroomActive.next(false);
      });
  }
}
