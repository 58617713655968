<ejs-schedule
  cssClass="schedule-cell-dimension-mobile schedule-event-template"
  [ngClass]="twoSPs ? 'custom-class' : 'custom-class-2'"
  startHour="08:00"
  [endHour]="endHour"
  #scheduleObj
  [currentView]="currentView"
  (cellClick)="setAppointment($event)"
  (eventClick)="eventClick($event)"
  [eventSettings]="eventSettings"
  (dataBound)="dataBinding()"
  (actionBegin)="onActionBegin($event)"
  [timeScale]="{ interval: 180, slotCount: 1 }"
  (selectedDateChange)="selectedDateChange($event)"
  style="margin-left: 5px"
>
  <e-views>
    <e-view option="Week">
      <ng-template #eventTemplate let-data>
        <div
          class="template-wrap"
          [style.background]="data.selectedColor"
          [style.height]="'100%'"
        >
          <ion-icon color="danger" name="trash"></ion-icon>
        </div>
      </ng-template>
    </e-view>
  </e-views>
</ejs-schedule>

<div *ngIf="appointmentForm?.valid" style="margin-left: 10px">
  <h3 style="margin-bottom: 0; font-weight: bold">Summary:</h3>
  <h4 class="ion-text-start">
    You have selected
    {{ appointmentForm?.get('StartTime')?.value?.toLocaleString() }}
  </h4>
</div>
