<!--<h5 class="ion-text-center ion-no-margin">Masjid Information</h5>-->
<!--<p style="font-size: 12px" class="ion-text-center">-->
<!--  Enter details of the account to which payment will be made-->
<!--</p>-->

<ion-grid fixed class="ion-padding-bottom">
  <form novalidate [formGroup]="$any(contactDetailsForm)">
    <ion-item>
      <ion-label position="floating"
        >CONTACT PERSON'S NAME:
        <ion-text color="danger">*</ion-text>
      </ion-label>
      <ion-input formControlName="contactPersonName"></ion-input>
      <radium-form-error-display
        [control]="$any(contactDetailsForm)?.controls['contactPersonName']"
      ></radium-form-error-display>
    </ion-item>
    <ion-item>
      <ion-label style="max-width: 40px">
        <ion-icon size="large" name="call-outline"></ion-icon>
      </ion-label>

      <ion-input
        formControlName="contactPersonNumber"
        [maxlength]="20"
        type="tel"
      ></ion-input>
    </ion-item>
    <radium-form-error-display
      [control]="$any(contactDetailsForm)?.controls['contactPersonNumber']"
    ></radium-form-error-display>

    <ion-item>
      <ion-label style="max-width: 40px">
        <ion-icon size="large" name="logo-whatsapp"></ion-icon>
      </ion-label>

      <ion-input
        formControlName="whatsappNumber"
        [maxlength]="20"
        type="tel"
      ></ion-input>
    </ion-item>
    <radium-form-error-display
      [control]="$any(contactDetailsForm)?.controls['whatsappNumber']"
    ></radium-form-error-display>
    <ion-item>
      <ion-label position="floating"
        >CONTACT EMAIL:
        <ion-text color="danger">*</ion-text>
      </ion-label>
      <ion-input formControlName="email"></ion-input>
      <radium-form-error-display
        [control]="$any(contactDetailsForm)?.controls['email']"
      ></radium-form-error-display>
    </ion-item>

    <ion-item>
      <ion-label position="floating">DETAILS:</ion-label>
      <ion-input formControlName="whatMatters"></ion-input>
      <radium-form-error-display
        [control]="$any(contactDetailsForm)?.controls['whatMatters']"
      ></radium-form-error-display>
    </ion-item>
  </form>
</ion-grid>
