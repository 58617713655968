<ion-toolbar [color]="color">
  <ion-grid>
    <ion-row>
      <ion-col>
        <ion-row>
          <ion-col>
            <hr />
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col>
            <ng-content></ng-content>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col class="copyright ion-text-center">
            Copyright &copy; {{ startYear }},
            <script>
              new Date().getFullYear() > 2010 &&
                document.write('-' + new Date().getFullYear());
            </script>
            All rights reserved, {{ name }}

            <!--    , developed by      <a href="" target="_blank">Abdullah Abrahams</a>-->
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-toolbar>
