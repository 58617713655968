import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { ImageProcessingService } from '@radium/core';

@Directive({
  selector: '[radiumDropzone]',
})
export class DropzoneDirective {
  @Output() dropped = new EventEmitter<{ file: File; url: string }>();
  @Output() hovered = new EventEmitter<boolean>();

  constructor(private ips: ImageProcessingService) {}

  @HostListener('drop', ['$event'])
  async onDrop($event: any) {
    $event.preventDefault();
    const files = $event.dataTransfer.files;
    const file = (await this.ips.resizeImage(files[0])) as File;
    const url = URL.createObjectURL(file);

    this.dropped.emit({ file, url });
    this.hovered.emit(false);
  }

  @HostListener('dragover', ['$event'])
  onDragOver($event: any) {
    $event.preventDefault();
    this.hovered.emit(true);
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave($event: any) {
    $event.preventDefault();
    this.hovered.emit(false);
  }
}
