import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  collectionChanges,
  collectionData,
  CollectionReference,
  deleteDoc,
  doc,
  docData,
  DocumentReference,
  Firestore,
  getDoc,
  increment,
  limitToLast,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  WithFieldValue,
} from '@angular/fire/firestore';
import { AbuseReport, Cart, Order, Review } from '@radium/ui';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { deleteField, Timestamp } from 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class FirebaseBusinessService {
  orderColl: CollectionReference<Order>;
  // private query: QueryConfig;
  cartColl: CollectionReference<Cart>;
  completedCartColl: CollectionReference<Cart>;
  reviewColl: CollectionReference<Review>;
  abuseReportsCollection: CollectionReference<AbuseReport>;
  queriesCollection: CollectionReference<any>;
  quotesColl: CollectionReference<any>;
  invoiceColl: CollectionReference<any>;
  // Source data
  private LOADING = new BehaviorSubject(false);

  constructor(private afs: Firestore, private http: HttpClient) {
    this.orderColl = collection(
      this.afs,
      'orders'
    ) as CollectionReference<Order>;
    this.cartColl = collection(this.afs, 'carts') as CollectionReference<Cart>;
    this.completedCartColl = collection(
      this.afs,
      'completedCarts'
    ) as CollectionReference<Cart>;
    this.reviewColl = collection(
      this.afs,
      'reviews'
    ) as CollectionReference<Review>;
    this.abuseReportsCollection = collection(
      this.afs,
      'abuse_reports'
    ) as CollectionReference<AbuseReport>;
    this.queriesCollection = collection(
      this.afs,
      'queries'
    ) as CollectionReference<any>;
    this.quotesColl = collection(
      this.afs,
      'quotes'
    ) as CollectionReference<any>;
    this.invoiceColl = collection(
      this.afs,
      'invoices'
    ) as CollectionReference<any>;
  }

  createAbuseReport(reporterId: string, entityId: string, message: string) {
    const data: AbuseReport = {
      createdAt: Timestamp.now(),
      reporterId,
      entityId,
      message,
      resolved: false,
    };

    return addDoc(this.abuseReportsCollection, data).catch();
  }

  addQuery(data: any) {
    return addDoc(this.queriesCollection, data).catch();
  }

  createCart(id: string) {
    console.log('createCart');
    const cartRef = doc(this.afs, 'carts', id) as DocumentReference<Cart>;
    return setDoc(cartRef, {
      products: {},
      isAnonymous: true,
      isPile: false,
    } as any)
      .then(() => console.log('Empty Cart created!'))
      .catch();
  }

  async createCartWithData(id: string, data: Partial<Cart>) {
    const cartRef = doc(this.afs, 'carts', id) as DocumentReference<Cart>;
    const sanitizedCartData = {
      ...data,
      uid: id,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    };

    // Remove any undefined values
    Object.keys(sanitizedCartData).forEach((key) => {
      if (sanitizedCartData[key] === undefined) {
        delete sanitizedCartData[key];
      }
    });

    // Final check to remove any functions
    Object.keys(sanitizedCartData).forEach((key) => {
      if (typeof sanitizedCartData[key] === 'function') {
        delete sanitizedCartData[key];
      }
    });

    return setDoc(cartRef, sanitizedCartData as WithFieldValue<Cart>)
      .then(() => console.log('Cart created with data!'))
      .catch((error) => console.error('Error creating cart:', error));
  }

  getCart(uid: string) {
    // return this.cartColl
    //   .doc<Cart>(uid)
    //   .snapshotChanges()
    //   .pipe(
    //     <any>map((arr: any) => arr.payload.data()),
    //     <any>catchError((x) => of(x))
    //   );

    const cartRef = doc(this.afs, 'carts', uid) as DocumentReference<Cart>;
    return docData(cartRef, { idField: 'id' as any }).pipe(
      catchError((x) => of(x))
    );
  }

  updateCart(uid: string, data: Partial<Cart>) {
    const cartRef = doc(this.afs, 'carts', uid) as DocumentReference<Cart>;
    return updateDoc(cartRef, data as any).catch();
  }

  deleteCartDoc(uid: string) {
    const cartRef = doc(this.afs, 'carts', uid) as DocumentReference<Cart>;
    return deleteDoc(cartRef).catch();
  }

  findCompletedCart(uid: string): Observable<Cart[]> {
    return collectionData<Cart>(
      query<Cart>(
        this.completedCartColl,
        where('uid', '==', uid),
        orderBy('PFPaymentId'),
        limitToLast(1)
      ),
      { idField: 'id' as any }
    ).pipe(catchError((x) => of(x)));
  }

  productsInCartCount(uid: string): Observable<number> {
    return this.getCart(uid).pipe(
      map((c) => {
        if (c) {
          return Object.keys(c?.products).length;
        } else {
          return 0;
        }
      })
    );
  }

  incrementQty(id: string, productId: any) {
    const cartRef = doc(this.afs, 'carts', id) as DocumentReference<Cart>;

    return setDoc(
      cartRef,
      {
        products: {
          [`${productId}`]: {
            quantity: increment(1),
          },
        },
      } as any,
      { merge: true }
    );
  }

  decrementQty(id: any, productId: any) {
    const cartRef = doc(this.afs, 'carts', id) as DocumentReference<Cart>;

    return setDoc(
      cartRef,
      {
        products: {
          [`${productId}`]: {
            quantity: increment(-1) as any,
          },
        },
      } as any,
      { merge: true }
    );
  }

  deleteItemFromCart(id: string, productId: any) {
    const cartRef = doc(this.afs, 'carts', id) as DocumentReference<Cart>;

    return setDoc(
      cartRef,
      {
        products: {
          [`${productId}`]: deleteField(),
        },
      } as any,
      { merge: true }
    );
  }

  async isCartProcessed(PFPaymentId: string) {
    const cartRef = doc(
      this.completedCartColl,
      PFPaymentId
    ) as DocumentReference<Cart>;
    const x = await getDoc(cartRef);

    return x.exists();
  }

  getOrderDocs() {
    return collectionChanges<Order>(
      query<Order>(this.orderColl, where('status', '==', 'incomplete'))
    ).pipe(
      map((arr) => {
        const values = arr.map((snap) => {
          const data: Order = snap.doc.data();
          const doc = snap.doc;
          return { ...data, id: doc.id };
        });
        return values;
      }),
      catchError((x) => of(x))
    );
  }

  // updateOrder(docId: string, data: Partial<Order>) {
  //   const orderRef = doc(this.afs, 'orders', docId) as DocumentReference<Order>;
  //   return updateDoc(orderRef, data).catch();
  // }

  updateOrder(uid: string, data: Partial<Order>) {
    const orderRef = doc(this.afs, 'orders', uid) as DocumentReference<
      Partial<Order>
    >;
    return updateDoc(orderRef, data as any).catch();
  }

  // addReview(
  //   entityId: string,
  //   bookName: string,
  //   authorId: string,
  //   authorName: string,
  //   message: string,
  //   authorAvatar: string
  // ) {
  //   this.reviewColl.add({
  //     bookId,
  //     bookName,
  //     authorId,
  //     authorName,
  //     createdAt: firestore.Timestamp.now(),
  //     authorAvatar,
  //     message
  //   });
  // }

  removeReview(reviewId: string) {
    const reviewRef = doc(
      this.afs,
      'reviews',
      reviewId
    ) as DocumentReference<Review>;
    return deleteDoc(reviewRef).catch();
  }

  getReviews(entityId: string, type: string): any {
    return collectionData<Review>(
      query<Review>(this.reviewColl, where('entityId', '==', entityId)),
      { idField: 'id' as any }
    ).pipe(catchError((x) => of(x)));
  }

  getMyReviews(uid: string): any {
    return collectionData<Review>(
      query<Review>(this.reviewColl, where('authorId', '==', uid)),
      { idField: 'id' as any }
    ).pipe(catchError((x) => of(x)));
  }

  createQuote(uid: string, data: any) {
    const quoteRef = doc(this.afs, 'quotes', uid) as DocumentReference<any>;
    return setDoc(quoteRef, data).catch();
  }

  getQuote(uid: string): any {
    // return this.quotesColl.doc(id).get();
    const quoteRef = doc(this.afs, 'quotes', uid) as DocumentReference<any>;

    return docData(quoteRef, { idField: 'id' as any }).pipe(
      catchError((x) => of(x))
    );
  }
}
