import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Auth, authState } from '@angular/fire/auth';
import { ToastController } from '@ionic/angular';
import { FirebaseUserService } from '@radium/core';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard {
  constructor(
    private authService: Auth,
    private router: Router,
    public toastCtrl: ToastController,
    public fireUser: FirebaseUserService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    authState(this.authService)
      .pipe(<any>take(1))
      .subscribe((res: any) => {
        if (res === null) {
          this.toast().catch();

          this.router
            .navigate(['home'], { queryParams: { redirectUrl: next.url } })
            .catch();
          return false;
        } else {
          return this.fireUser.isAdmin().then((isAdmin: any) => {
            if (!isAdmin) {
              this.toast().catch();

              this.router
                .navigate(['home'], {
                  queryParams: { redirectUrl: next.url },
                })
                .catch();
              return false;
            }
            return true;
          });
        }
      });
    return true;
  }

  async toast() {
    const toast = await this.toastCtrl.create({
      duration: 3000,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
          handler: () => {
            console.log('Close clicked');
          },
        },
      ],
      position: 'top',
      header:
        'You are not allowed to view that page. You are redirected to the Home Page',
    });

    await toast.present();
  }
}
