import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BookMoreInfo } from '@radium/ui';
import { deleteObject, ref, Storage } from '@angular/fire/storage';
import { generateUUID } from '../../../../../utils';
import { debounceTime, skipWhile } from 'rxjs/operators';
import { TagModel } from 'ngx-chips/core/tag-model';

import { BarcodeScannerOptions } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { BarcodeFormat } from '@zxing/library';
import { MatDialog } from '@angular/material/dialog';
import {
  FirebaseBookService,
  FirebaseBusinessService,
  FirebaseUserService,
} from '@radium/core';
import { FormatsDialogComponent } from '../formats-dialog';

@Component({
  selector: 'radium-create-book',
  templateUrl: './create-book.component.html',
  styleUrls: ['./create-book.component.scss'],
})
export class CreateBookComponent implements OnInit, OnDestroy, AfterViewInit {
  scanCode = false;
  isHovering: boolean;
  file: File;
  submitAttempt = false;
  bookForm: FormGroup;
  @Output() closeComponentEmit: EventEmitter<any> = new EventEmitter();
  @Input() preview = true;
  @Input() data: {
    isRequired: boolean;
    images: {
      downloadURL: string;
      approved: boolean;
      id: string;
      imageCategory: string;
    }[];
    type: string;
    book: any;
    isBigDevice: boolean;
    imageCategories: string[];
    categoryName: string;
  };
  selectedImageCategory: string;
  uploadingImage: boolean;

  sub1: Subscription;
  sub2: Subscription;

  sub: Subscription;

  results: any[];
  selected: boolean;
  blurred: boolean;
  selectedBook: BookMoreInfo;
  tags$: Observable<string[]>;
  barcodeScannerOptions: BarcodeScannerOptions;

  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  hasDevices: boolean;
  hasPermission: boolean;

  qrResultString: string;

  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;

  constructor(
    public modalCtrl: ModalController,
    public formBuilder: FormBuilder,
    public fireBusService: FirebaseBusinessService,
    private router: Router,
    private storage: Storage,
    private readonly _dialog: MatDialog,
    private fireUser: FirebaseUserService,
    private fireBookService: FirebaseBookService
  ) {
    this.barcodeScannerOptions = {
      showTorchButton: true,
      showFlipCameraButton: true,
    };
  }

  ngOnInit() {
    this.bookForm = this.formBuilder.group({
      title: ['', Validators.compose([Validators.required])],
      description: [''],
      authorName: ['', Validators.compose([Validators.required])],
      price: ['', Validators.compose([Validators.required])],
      quantity: [
        1,
        Validators.compose([Validators.required, Validators.min(1)]),
      ],
      weight: [
        500,
        Validators.compose([Validators.required, Validators.min(5)]),
      ],
      categories: [[]],
    });

    if (this.data?.book) {
      this.bookForm.patchValue({
        price: this.data?.book.price,
        description: this.data?.book.description,
        title: this.data?.book.title,
        authorName: this.data?.book.authorName,
        weight: this.data?.book.weight,
        categories: this.data?.book.moreInfo.categories,
      });
      this.data.images = this.data?.book.images;
      this.selectedBook = this.data?.book.moreInfo;
    }

    // const modalState = {
    //   modal: true,
    //   desc: "fake state for our modal",
    // };
    // history.pushState(modalState, null);
    this.tags$ = this.fireBookService.getTags();

    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null);
  }

  ngAfterViewInit() {
    console.log('after view');
    // this.barcodeScanner.start().catch();
  }

  onValueChanges(result) {
    console.log({ result });
    // this.barcodeValue = result.codeResult.code;
  }

  onStarted(started) {
    console.log(started);
  }

  // scanCode() {
  //   this.barcodeScanner
  //     .scan()
  //     .then((barcodeData) => {
  //       console.log('Barcode data', barcodeData);
  //     })
  //     .catch((err) => {
  //       console.log('Error', err);
  //     });
  // }

  setImageData(val: { downloadURL: string; path: string }) {
    console.log('this.file: ', this.file);
    // console.log('val', val);

    if ((val as unknown) === 'error') {
      this.uploadingImage = false;
      return;
    }

    if ((val as unknown) === 'cancel') {
      this.uploadingImage = false;
      return;
    }

    const newFile = {
      downloadURL: val.downloadURL,
      approved: true,
      id: generateUUID(),
      imageCategory: this.selectedImageCategory,
    };

    this.file = {
      ...this.file,
      ...newFile,
    } as any;

    setTimeout(() => {
      this.data?.images.push(newFile);
      this.preview = true;
      this.uploadingImage = false;
    }, 500);
  }

  addBook() {
    this.submitAttempt = true;
    this.bookForm?.markAllAsTouched();
    console.log(this.bookForm?.valid);

    if (this.bookForm.valid && this.data.images?.length > 0) {
      // If we use this comp as a model we will do this
      const f = this.bookForm
        .get('categories')
        .value.map((g) => (g.value ? g.value : g));

      this.selectedBook.categories = f;

      this.closeModal({
        title: this.bookForm.get('title').value,
        description: this.bookForm.get('description').value,
        price: this.bookForm.get('price').value.toString(),
        authorName: this.bookForm.get('authorName').value,
        quantity: this.bookForm.get('quantity').value,
        images: this.data?.images,
        moreInfo: this.selectedBook,
        weight: this.bookForm.get('weight').value,
      });

      this.fireBookService.updateTags(f).catch();
    }
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(e: { file: File; url: string }) {
    console.log(e.url);
    console.log('url not used!');
    this.file = e.file;
  }

  @HostListener('window:popstate', ['$event'])
  closeModal(data?) {
    if (this.preview === false) {
      return (this.preview = true);
    } else {
      const newData = data ?? {};
      return this.modalCtrl.dismiss(newData);
    }
  }

  actionTheImage(e) {
    console.log('clicked: ', e);
    console.log(this.data?.images);

    if (e.action === 'delete') {
      const x = this.data?.images[e.index];
      const path = this.data.images[e.index].downloadURL;
      // this.storage.refFromURL(path).delete();
      deleteObject(ref(this.storage, path)).catch();
      this.data.images.splice(e.index, 1);
      if (this.data?.book) {
        // TODO delete from book on firestore book images array too
        this.fireBookService.removeBookImage(this.data?.book?.id, x);
      }
    }
  }

  closeComponent() {
    if (this.data.type === 'modal') {
      this.closeModal();
    } else {
      this.closeComponentEmit.emit(this.data);
    }
  }

  search(e) {
    this.blurred = false;
    if (e.detail.value.length > 1 && !this.selected) {
      this.fireBookService
        .searchBooksOnGoogle(e.detail.value)
        .pipe(
          debounceTime(2000),
          skipWhile((x) => !x)
        )
        .subscribe((res) => {
          console.log({ res });
          this.results = res.items;
        });
    } else {
      this.results = [];
    }
  }

  selectBook(volumeInfo?) {
    this.selected = true;
    this.selectedBook = volumeInfo;
    this.blurred = true;
    console.log({ volumeInfo });

    if (volumeInfo) {
      const cats =
        this.bookForm.get('categories').value.map((x) => x.value) ?? [];
      console.log({ cats });
      // console.log([...cats, ...volumeInfo?.categories]);

      this.bookForm.patchValue({
        title: volumeInfo?.title,
        description: volumeInfo?.description ?? 'No Description!',
        authorName: volumeInfo?.authors?.[0] ?? 'Unknown Author',
        categories: [...cats, ...volumeInfo.categories],
      });
      this.results = [];
      setTimeout(() => (this.selected = false), 3000);
    }
  }

  removeFocus() {
    console.log('here');
    setTimeout(() => (this.blurred = true), 100);
    // this.blurred = true;
    // this.results = [];
  }

  actionThePreview(event: any) {
    console.log({ event });
    if (event.action === 'disablePreview') {
      this.preview = false;
    }
    if (event.action === 'enablePreview') {
      this.preview = true;
    }
  }

  setImageCategory(e: string) {
    this.selectedImageCategory = e;
  }

  incrDecr(e: string) {
    if (e === 'decrement') {
      this.bookForm
        .get('quantity')
        .setValue(this.bookForm.get('quantity').value - 1);
    } else if (e === 'increment') {
      this.bookForm
        .get('quantity')
        .setValue(this.bookForm.get('quantity').value + 1);
    }
  }

  onTagEdited(event: TagModel) {
    console.log({ event });
  }

  addImage() {
    if (this.file) {
      this.uploadingImage = true;
    }
  }

  ngOnDestroy(): void {
    if (this.sub1) {
      this.sub1.unsubscribe();
    }
    if (this.sub2) {
      this.sub2.unsubscribe();
    }
    if (this.sub) {
      this.sub.unsubscribe();
    }

    if (window.history.state.modal) {
      history.back();
    }
  }

  scanSuccess(e: string) {
    console.log({ e });
  }

  scanError(e) {
    console.log({ e });
  }

  clearResult(): void {
    this.qrResultString = null;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  onCodeResult(resultString: string) {
    this.qrResultString = resultString;
  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find((x) => x.deviceId === selected);
    this.currentDevice = device || null;
  }

  openFormatsDialog() {
    const data = {
      formatsEnabled: this.formatsEnabled,
    };

    this._dialog
      .open(FormatsDialogComponent, { data })
      .afterClosed()
      .subscribe((x) => {
        if (x) {
          this.formatsEnabled = x;
        }
      });
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }

  openInfoDialog() {
    const data = {
      hasDevices: this.hasDevices,
      hasPermission: this.hasPermission,
    };
  }

  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
  }
}
