<ion-item class="ion-no-padding" style="font-size: 13px" lines="none">
  <ion-label>{{ field.props.label }}:</ion-label>
  <ion-select
    [formControl]="formControl"
    [formlyAttributes]="field"
    style="min-width: 180px"
  >
    <ion-select-option
      *ngFor="let option of field.props?.options"
      [value]="option.value"
      >{{ option.label }}
    </ion-select-option>
  </ion-select>
</ion-item>
<ion-item fill="outline" style="width: 100%">
  <ion-label position="stacked">New {{ field.props?.label }}:</ion-label>
  <ion-input
    [(ngModel)]="newOption"
    type="text"
    class="ion-padding-start"
    placeholder="Type here..."
  ></ion-input>

  <ion-button
    [disabled]="!newOption || newOption?.length === 0"
    (click)="addOption()"
    slot="end"
    class="ion-no-margin"
    >Add
  </ion-button>
</ion-item>
