import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  isLoading$ = new BehaviorSubject(false);

  constructor(public loadingController: LoadingController) {}

  async loadingPresent(message: string, source: string, dontAbort?: boolean) {
    return await this.loadingController
      .create({
        message,
        spinner: 'circles',
        showBackdrop: false,
      })
      .then((a) => {
        this.isLoading$.next(true);
        a.present().then(() => {
          console.log('loading presented from --> ' + source);
          setTimeout(() => {
            if (this.isLoading$.getValue() === true && !dontAbort) {
              a.dismiss().then(() => console.log('abort loading'));
            }
          }, 5000);
        });
      });
  }

  async loadingDismiss(source: any) {
    if (this.isLoading$.getValue() === true) {
      this.isLoading$.next(false);
      return await this.loadingController
        .dismiss()
        .then(() => console.log('loading dismissed from --> ' + source));
    }
  }

  wrapUpLoading(msg: any) {
    return setTimeout(() => {
      if (this.isLoading$.getValue() === true) {
        this.loadingDismiss(msg).catch();
      }
    }, 1000);
  }
}
