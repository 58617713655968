<!--<div-->
<!--  class="ion-padding-horizontal ion-margin-horizontal"-->
<!--  style="font-size: 10px"-->
<!--&gt;-->
<!--  <div [ngStyle]="{ 'min-height': isBigDevice ? '110%' : '130%' }">-->

<!--  </div>-->
<!--</div>-->

<h5 class="ion-text-center ion-no-margin">Terms and Conditions</h5>
<p class="ion-text-center" style="font-size: 10px">
  Please read through these carefully and check the checkbox below
</p>

<ion-grid fixed class="ion-margin-bottom ion-padding-bottom">
  <form novalidate [formGroup]="$any(tcsForm)" style="font-size: 10px">
    <ion-row *ngIf="type === 'business'">
      <ul>
        <!--        <li>-->
        <!--          I agree to indemnify Detailers against any loss or damage which may be-->
        <!--          occasioned as a result of having this business added to the Detailers -->
        <!--          platform.-->
        <!--        </li>-->
        <!--        <br />-->
        <!--        <li>-->
        <!--          By adding this business, I indemnify Detailers and/ or its supplier-->
        <!--          against any and all claims arising from whatsoever nature and from-->
        <!--          whosoever in respect of, inter alia, claims, costs and expenses-->
        <!--          arising out of infringement of copyright, trademarks, patent or-->
        <!--          design.-->
        <!--        </li>-->
        <!--        <br />-->
        <!--        <li>-->
        <!--          Detailers shall have the right to without notice to the business, remove-->
        <!--          the business from the Detailers platform.-->
        <!--        </li>-->
        <!--        <br />-->
      </ul>
    </ion-row>

    <ion-row>
      <ul style="padding-left: 20px">
        <li>
          All prices shown are estimates only, a quote will be send to the
          customer.
        </li>
        <li>
          All quotations will remain open to acceptance for a period of 30 days
          from their date.
        </li>
        <li>Any shown prices include VAT.</li>
      </ul>
    </ion-row>

    <ion-item class="ion-margin-bottom">
      <ion-checkbox
        slot="start"
        formControlName="acceptAgreement"
      ></ion-checkbox>
      <ion-label style="font-size: 12px">
        I agree to the terms and conditions above
      </ion-label>
    </ion-item>
  </form>
</ion-grid>
