<ion-card style="height: 100%">
  <ion-card-content
    style="padding-right: 0; padding-bottom: 5px"
    class="ion-padding"
  >
    <div class="ion-no-padding ion-no-margin">
      <div
        class="ion-text-end ion-no-padding ion-no-margin"
        style="width: 100%; font-size: 11px"
      >
        <span style="position: absolute; left: 15px">
          Created:
          {{ job.createdAt?.seconds * 1000 | date : 'MM-dd-yyyy' }}
        </span>

        <ion-badge
          class="ion-no-margin ion-margin-end"
          slot="end"
          style="font-size: 11px"
        >
          ID: {{ job?.id }}
        </ion-badge>

        <br />
        <span style="position: absolute; left: 15px">
          Appointment:
          {{
            job.appointment?.StartTime
              ? (job.appointment?.StartTime?.seconds * 1000
                | date : 'MM-dd-yyyy h:mm')
              : 'Not Set'
          }}
        </span>
      </div>
      <br />
      <div style="width: 100%" class="ion-no-margin">
        Services:
        <p
          *ngFor="let s of job.services; index as index"
          style="margin-bottom: 2px"
        >
          {{ index + 1 }}) {{ s.text }}
        </p>

        <p *ngIf="!job.services || job.services?.length === 0">
          No services added as yet
        </p>
      </div>
    </div>

    <ion-item class="ion-no-padding" lines="none">
      <ion-label
        class="ion-no-margin"
        slot="start"
        style="font-size: 12px; min-width: 70%"
      >
        State: {{ job.state }} ({{ states[job.state] | uppercase }})
        {{ job.state < states.submitted ? '(DRAFT)' : '' }}
      </ion-label>

      <!--                  <ion-buttons slot="end" class="ion-no-margin">-->
      <ion-button
        color="secondary"
        fill="solid"
        size="small"
        shape="round"
        slot="end"
        class="ion-no-margin"
        style="margin: 0 0 25px"
        (click)="doAction('open', job?.id)"
      >
        <!--                    <ion-icon name="create"></ion-icon>-->
        {{ canEdit ? 'edit job' : 'open' }}
      </ion-button>

      <!--                  <ion-button-->
      <!--                    size="small"-->
      <!--                    fill="solid"-->
      <!--                    shape="round"-->
      <!--                    color="danger"-->
      <!--                    (click)="cancelJob(job?.id, job.approved)"-->
      <!--                  >-->
      <!--                    <ion-text> cancel </ion-text>-->
      <!--                  </ion-button>-->
      <!--                  </ion-buttons>-->
    </ion-item>
    <div *ngIf="canEdit">
      <ion-button
        (click)="doAction('cancel', job?.id)"
        size="small"
        color="danger"
        *ngIf="job.state !== states.job_cancelled"
      >
        cancel job
      </ion-button>
      <ion-button
        *ngIf="
          job.state > states.quote_sent && job.state !== states.job_cancelled
        "
        size="small"
        routerLink="/view/quote/{{ job?.id }}"
      >
        view quote
      </ion-button>

      <ion-button
        *ngIf="job.state > states.job_completed"
        size="small"
        routerLink="/view/invoice/{{ job?.id }}"
      >
        view invoice
      </ion-button>
    </div>
  </ion-card-content>
</ion-card>
