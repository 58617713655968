import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Auth, authState } from '@angular/fire/auth';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApprovedGuard {
  constructor(public router: Router, private afAuth: Auth) {}

  async canLoad(): Promise<boolean> {
    return authState(this.afAuth)
      .pipe(<any>take(1))
      .toPromise()
      .then((res) => {
        if (!res || !!res) {
          console.log('user not found so go to home page');
          return true;
        } else {
          console.log('user found so go to home page');
          this.router.navigate(['home']).catch();
          return false;
        }
      });
  }
}
