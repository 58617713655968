<radium-header [pageName]="title"></radium-header>

<ion-content class="ion-margin ion-padding">
  <ion-item lines="none">
    <div *ngIf="extras?.invoice">
      <ion-datetime-button datetime="datetime"></ion-datetime-button>

      <ion-modal [keepContentsMounted]="true">
        <ng-template>
          <ion-datetime
            [(ngModel)]="date"
            (ionChange)="dateChanged($event)"
            id="datetime"
            presentation="date"
          ></ion-datetime>
        </ng-template>
      </ion-modal>
    </div>
  </ion-item>

  <radium-table
    [tableConfig]="tableConfig"
    (tableEvent)="tableModalAction($event)"
  ></radium-table>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-label *ngIf="extras?.invoice" class="ion-padding">
      Total: R {{ total }}
    </ion-label>
    <ion-buttons slot="end">
      <ion-button fill="solid" color="primary" (click)="dismiss()"
        >Done
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
