import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import dayjs from 'dayjs';

@Component({
  selector: 'radium-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent extends FieldType<FieldTypeConfig> {
  date = this.formControl?.value;

  constructor() {
    super();
  }

  ngOnInit() {
    this.date = this.formControl?.value;
  }

  setDate(e) {
    if (this.date) {
      this.date = dayjs(this.date).format('DD-MM-YYYY');
      this.formControl.setValue(this.date);
    }
  }
}
