import { Injectable } from '@angular/core';

// import firebase from "firebase/app";

@Injectable({
  providedIn: 'root',
})
export class FirebaseuiService {
  ui: any;

  constructor() {
    // Initialize the FirebaseUI Widget using Firebase.
    //  this.ui = new firebaseui.auth.AuthUI(firebase.auth());
  }
}
