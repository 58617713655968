import { catchError, map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { combineLatest, firstValueFrom, Observable, of } from 'rxjs';
import {
  Address,
  formData,
  Org,
  OrgAccount,
  OrgAttendee,
  OrgChartOfAccount,
  OrgClass,
  OrgCorrespondence,
  OrgCreditNote,
  OrgDebitNote,
  OrgDeposit,
  OrgEvent,
  OrgFinanceDetails,
  OrgLedgerEntry,
  OrgPayment,
  OrgRegistrationField,
  OrgService,
  OrgStaffMember,
  OrgVenue,
} from '@radium/ui';
import { Platform } from '@ionic/angular';
import {
  arrayRemove,
  arrayUnion,
  collection,
  collectionData,
  CollectionReference,
  collectionSnapshots,
  deleteDoc,
  doc,
  docData,
  DocumentReference,
  Firestore,
  getDoc,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  setDoc,
  Timestamp,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import { toDate, toDateNoTime } from 'utils';
import { FirebaseUserService } from './firebase-user.service';

@Injectable({ providedIn: 'root' })
export class FirebaseOrgService {
  orgCollection: CollectionReference<Org>;
  orgRegFormCollection: CollectionReference<OrgRegistrationField[]>;

  constructor(
    private afs: Firestore,
    public platform: Platform,
    private userService: FirebaseUserService
  ) {
    this.orgCollection = collection(
      this.afs,
      'orgs'
    ) as CollectionReference<Org>;

    this.orgRegFormCollection = collection(
      this.afs,
      'forms'
    ) as CollectionReference<OrgRegistrationField[]>;
  }

  async orgExists(uid: string): Promise<boolean> {
    const orgRef = doc(this.afs, `orgs/${uid}`);
    const getD = await getDoc(orgRef);
    return getD.exists();
  }

  deleteOrgDoc(uid: string) {
    const orgRef = doc(this.afs, `orgs/${uid}`);
    return deleteDoc(orgRef).catch();
  }

  async createOrgDoc(data: Partial<Org>) {
    const orgRef = doc(this.orgCollection);
    const uid = orgRef.id;
    data.createdAt = Timestamp.now();
    data.uid = uid;
    data.websiteActive = false;
    await setDoc(orgRef, data, { merge: true });
    return uid;
  }

  updateOrgDoc(uid: string, data: Partial<Org>) {
    const orgRef = doc(this.afs, 'orgs', uid) as DocumentReference<Org>;
    const newData = { ...data, updatedAt: Timestamp.now() };
    return updateDoc(orgRef, newData as any).catch();
  }

  // get org doc based on webName
  getOrgDocByWebName(webName: string): Observable<Org> {
    const q = query(this.orgCollection, where('webName', '==', webName));

    return collectionSnapshots(q).pipe(map((orgs) => orgs[0]?.data() as Org));
  }

  getOrgDoc(uid: string): Observable<Org> {
    const orgRef = doc(this.afs, `orgs/${uid}`);

    return docData(orgRef, { idField: 'id' }).pipe(
      catchError((x: any) => of(x))
    ) as Observable<Org>;
  }

  // getAllOrgDocs(uid: string): Observable<Org[]> {
  //   const orgsCollection = collection(
  //     this.afs,
  //     'orgs'
  //   ) as CollectionReference<Org>;
  //   return collectionData<Org>(query<Org>(orgsCollection), {
  //     idField: 'id',
  //   });
  // }

  getAllOrgDocs(uid: string): Observable<Org[]> {
    const q = query(this.orgCollection, where('owners', 'array-contains', uid));

    return collectionData<Org>(q, {
      idField: 'id' as any,
    });
  }

  addAddress(uid: string, address: Address) {
    this.updateOrgDoc(uid, {
      addresses: arrayUnion(address) as any,
    }).catch();
  }

  removeAddress(uid: string, address: Address) {
    this.updateOrgDoc(uid, {
      addresses: arrayRemove(address) as any,
    }).catch();
  }

  // async orgAccountExists(
  //   orgId: string,
  //   uid: string
  // ): Promise<DocumentSnapshot<DocumentData>> {
  //   const orgRef = doc(this.afs, `orgs/${orgId}/accounts/${uid}`);
  //   return await getDoc(orgRef);
  // }

  async createOrgAccountNumber(orgId: string): Promise<number> {
    const orgRef = collection(this.afs, `orgs/${orgId}/accounts`);
    const q = query(orgRef, orderBy('accountNumber', 'desc'), limit(1));
    const lastAccount = await getDocs(q).then((x) => x?.docs[0]?.data());
    console.log({ lastAccount });
    return lastAccount ? lastAccount.accountNumber + 1 : 100001;
  }

  async createOrgAccount(orgId: string, uid: string, account: OrgAccount) {
    const orgRef = doc(this.afs, `orgs/${orgId}/accounts/${uid}`);

    const user = await firstValueFrom(this.userService.getUserDoc(uid));

    if (!user?.contactNumber && !user?.whatsappNumber && account?.cellNumber) {
      await this.userService.updateUserDoc(uid, {
        whatsappNumber: account.cellNumber,
        contactNumber: account.cellNumber,
      });
    }

    account.createdAt = Timestamp.now();
    return setDoc(orgRef, account).then(() => account);
  }

  async updateOrgAccount(
    orgId: string,
    uid: string,
    account: Partial<OrgAccount>
  ) {
    const orgRef = doc(this.afs, `orgs/${orgId}/accounts/${uid}`);

    const user = await firstValueFrom(this.userService.getUserDoc(uid));
    // update the user cell and whatsapp number

    if (!user?.contactNumber && !user?.whatsappNumber && account?.cellNumber) {
      await this.userService.updateUserDoc(uid, {
        whatsappNumber: account.cellNumber,
        contactNumber: account.cellNumber,
      });
    }

    account.updatedAt = Timestamp.now();
    return updateDoc(orgRef, account).then(() => account);
  }

  getOrgAccount(orgId: string, uid: string) {
    const orgRef = doc(this.afs, `orgs/${orgId}/accounts/${uid}`);
    return docData(orgRef).pipe(
      catchError((x) => of(x))
    ) as Observable<OrgAccount>;
  }

  getOrgAccounts(orgId: string): Observable<OrgAccount[]> {
    const orgRef = collection(this.afs, `orgs/${orgId}/accounts`);

    return collectionData(
      query(collection(this.afs, `orgs/${orgId}/accounts/`))
    ).pipe(catchError((x) => of(x)));

    // return collectionSnapshots(orgRef).pipe(
    //   map((accounts: QueryDocumentSnapshot<OrgAccount>[]) =>
    //     accounts.map((account) => {
    //       const {
    //         accountNumber,
    //         cellNumber,
    //         name,
    //         active,
    //         email,
    //         balance,
    //         createdAt,
    //         updatedAt,
    //         billedUntil,
    //         ...rest
    //       } = account.data();
    //
    //       return {
    //         accountNumber,
    //         name,
    //         cellNumber,
    //         email,
    //         balance,
    //         active,
    //         createdAt: toDate(createdAt) as any,
    //         updatedAt: toDate(updatedAt) as any,
    //         billedUntil: toDate(billedUntil, false) as any,
    //         ...rest,
    //       };
    //     })
    //   )
    // );
  }

  deleteOrgAccount(orgId: string, data: OrgAccount) {
    const orgRef = doc(this.afs, `orgs/${orgId}/accounts/${data.uid}`);
    const archived = this.archiveOrgItem(orgId, data);
    return Promise.all([deleteDoc(orgRef), archived]);
  }

  async archiveOrgItem(orgId: string, data: any) {
    const archivedRef = doc(this.afs, `orgs/${orgId}/archive/${data.uid}`);
    return setDoc(archivedRef, data);
  }

  createOrgFinanceDetails(orgId: string, financeDetails: OrgFinanceDetails) {
    const orgRef = doc(this.afs, `orgsFinanceDetails/${orgId}`);
    return setDoc(orgRef, financeDetails);
  }

  updateOrgFinanceDetails(
    orgId: string,
    financeDetails: Partial<OrgFinanceDetails>
  ) {
    const orgRef = doc(this.afs, `orgsFinanceDetails/${orgId}`);
    return updateDoc(orgRef, financeDetails);
  }

  getOrgFinanceDetails(orgId: string) {
    const orgRef = doc(this.afs, `orgsFinanceDetails/${orgId}`);
    return docData(orgRef).pipe(
      catchError((x) => of(x))
    ) as Observable<OrgFinanceDetails>;
  }

  async createOrgDeposit(orgId: string, deposit: OrgDeposit) {
    const orgDepositsCollection = collection(
      this.afs,
      `orgs/${orgId}/deposits`
    );
    const orgDepositsRef = doc(orgDepositsCollection);

    return await setDoc(orgDepositsRef, deposit);
  }

  getOrgDeposits(orgId: string) {
    const orgRef = collection(this.afs, `orgs/${orgId}/deposits`);
    return collectionData(orgRef).pipe(catchError((x) => of(x))) as Observable<
      OrgDeposit[]
    >;
  }

  async addOrgCorrespondence(orgId: string, correspondence: OrgCorrespondence) {
    const orgCorrespondenceCollection = collection(
      this.afs,
      `orgs/${orgId}/correspondence`
    );
    const orgCorrespondenceRef = doc(orgCorrespondenceCollection);

    return await setDoc(orgCorrespondenceRef, correspondence);
  }

  getOrgCorrespondences(orgId: string, accountUid: string) {
    const q = query(
      collection(this.afs, `orgs/${orgId}/correspondence`),
      where('accountUid', '==', accountUid)
    );

    return collectionData(q).pipe(catchError((x) => of(x))) as Observable<
      OrgCorrespondence[]
    >;
  }

  async createOrgRegForm(orgId: string) {
    const orgRegFormCollection = collection(this.afs, `orgs/${orgId}/forms`);
    const orgFormRef = doc(orgRegFormCollection, 'reg');

    return await setDoc(orgFormRef, { formData });
  }

  async updateOrgRegForm(orgId, data) {
    const orgRef = doc(
      this.afs,
      `orgs/${orgId}/forms/reg`
    ) as DocumentReference<{
      formData;
    }>;
    return await updateDoc(orgRef, {
      formData: data,
    });
  }

  getOrgRegForm(orgId: string) {
    const docRef = doc(this.afs, `orgs/${orgId}/forms/reg`);
    return docData(docRef) as Observable<{
      formData: OrgRegistrationField[];
    }>;
  }

  async deleteOrgRegForm(orgId: string, data) {
    const orgRef = doc(this.afs, `orgs/${orgId}/forms/reg`);
    return await updateDoc(orgRef, {
      formData: arrayRemove(data),
    });
  }

  async deleteOrgRegFormField(orgId: string, name) {
    const orgRef = doc(this.afs, `orgs/${orgId}/forms/reg`);
    return await updateDoc(orgRef, {
      formData: arrayRemove({ name }),
    });
  }

  getOrgAttendees(orgId: string, ownerUid?: string): Observable<OrgAttendee[]> {
    return collectionData(
      query(
        collection(this.afs, `orgs/${orgId}/attendees/`),
        ownerUid && where('owner', '==', ownerUid)
      )
    ).pipe(catchError((x) => of(x)));
  }

  async getOrgAttendee(orgId: string, uid: string): Promise<OrgAttendee> {
    const orgRef = doc(this.afs, `orgs/${orgId}/attendees/${uid}`);
    return await getDoc(orgRef).then(
      (doc) => doc.exists() && (doc?.data() as OrgAttendee)
    );
  }

  async updateOrgAttendee(orgId: string, uid: string, data: OrgAttendee) {
    const orgRef = doc(this.afs, `orgs/${orgId}/attendees/${uid}`);
    data.updatedAt = Timestamp.now();
    return await updateDoc(orgRef, data as any);
  }

  async createOrgAttendee(
    orgId: string,
    ownerId: string,
    data: Partial<OrgAttendee>
  ) {
    const subCollectionRef = collection(this.afs, `orgs/${orgId}/attendees/`);
    const docRef = doc(subCollectionRef);
    const uid = docRef.id;
    console.log({ uid });
    console.log({ docRef });
    data.createdAt = Timestamp.now();
    data.uid = uid;
    data.owner = ownerId;
    return await setDoc(docRef, data).catch(console.log);
  }

  async deleteOrgAttendee(orgId: string, data: OrgAttendee) {
    const orgRef = doc(this.afs, `orgs/${orgId}/attendees/${data.uid}`);
    const archived = this.archiveOrgItem(orgId, data);
    return await Promise.all([deleteDoc(orgRef), archived]);
  }

  getEmailMessage(orgId: string, type: string) {
    const orgRef = doc(this.afs, `orgs/${orgId}/email-messages/${type}`);

    return docData(orgRef, { idField: 'id' }).pipe(catchError((x) => of(x)));
  }

  createEmailMessage(orgId: string, type: string, message: string) {
    const orgRef = doc(this.afs, `orgs/${orgId}/email-messages/${type}`);
    return setDoc(orgRef, { message });
  }

  async updateEmailMessage(orgId: string, type: string, message: string) {
    const orgRef = doc(this.afs, `orgs/${orgId}/email-messages/${type}`);
    return await updateDoc(orgRef, {
      message,
    });
  }

  getOrgVenues(orgId: string): Observable<OrgVenue[]> {
    return collectionData(
      query(collection(this.afs, `orgs/${orgId}/venues/`), orderBy('createdAt'))
    ).pipe(catchError((x) => of(x)));
  }

  async updateOrgVenue(orgId: string, uid: string, data: OrgVenue) {
    const orgRef = doc(this.afs, `orgs/${orgId}/venues/${uid}`);
    data.updatedAt = Timestamp.now();
    return await updateDoc(orgRef, data as any);
  }

  async createOrgVenue(orgId: string, data: Partial<OrgVenue>) {
    const subCollectionRef = collection(this.afs, `orgs/${orgId}/venues/`);
    const docRef = doc(subCollectionRef);
    const uid = docRef.id;
    console.log({ uid });
    console.log({ docRef });
    data.createdAt = Timestamp.now();
    data.uid = uid;
    return await setDoc(docRef, data).catch(console.log);
  }

  async deleteOrgVenue(orgId: string, uid: string) {
    const orgRef = doc(this.afs, `orgs/${orgId}/venues/${uid}`);
    return await deleteDoc(orgRef);
  }

  getOrgClasses(orgId: string): Observable<OrgClass[]> {
    return collectionData(
      query(
        collection(this.afs, `orgs/${orgId}/classes/`),
        orderBy('createdAt')
      )
    ).pipe(catchError((x) => of(x)));
  }

  async updateOrgClass(orgId: string, uid: string, data: OrgClass) {
    const orgRef = doc(this.afs, `orgs/${orgId}/classes/${uid}`);
    data.updatedAt = Timestamp.now();
    return await updateDoc(orgRef, data as any);
  }

  async createOrgClass(orgId: string, data: Partial<OrgClass>) {
    const subCollectionRef = collection(this.afs, `orgs/${orgId}/classes/`);
    const docRef = doc(subCollectionRef);
    const uid = docRef.id;
    console.log({ uid });
    console.log({ docRef });
    data.createdAt = Timestamp.now();
    data.uid = uid;
    return await setDoc(docRef, data).catch(console.log);
  }

  async deleteOrgClass(orgId: string, uid: string) {
    const orgRef = doc(this.afs, `orgs/${orgId}/classes/${uid}`);
    return await deleteDoc(orgRef);
  }

  getOrgServices(orgId: string): Observable<OrgService[]> {
    return collectionData(
      query(
        collection(this.afs, `orgs/${orgId}/services/`),
        orderBy('createdAt')
      )
    ).pipe(catchError((x) => of(x)));
  }

  async updateOrgService(orgId: string, uid: string, data: OrgService) {
    const orgRef = doc(this.afs, `orgs/${orgId}/services/${uid}`);
    data.updatedAt = Timestamp.now();
    return await updateDoc(orgRef, data as any);
  }

  async createOrgService(orgId: string, data: Partial<OrgService>) {
    const subCollectionRef = collection(this.afs, `orgs/${orgId}/services/`);
    const docRef = doc(subCollectionRef);
    const uid = docRef.id;
    console.log({ uid });
    console.log({ docRef });
    data.createdAt = Timestamp.now();
    data.uid = uid;
    return await setDoc(docRef, data).catch(console.log);
  }

  async deleteOrgService(orgId: string, uid: string) {
    const orgRef = doc(this.afs, `orgs/${orgId}/services/${uid}`);
    return await deleteDoc(orgRef);
  }

  getOrgStaffMembers(orgId: string): Observable<OrgStaffMember[]> {
    return collectionData(
      query(
        collection(this.afs, `orgs/${orgId}/staff-members/`),
        orderBy('createdAt')
      )
    ).pipe(catchError((x) => of(x)));
  }

  getOrgStaffMember(
    orgId: string,
    userEmail: string
  ): Observable<OrgStaffMember> {
    const q = query(
      collection(this.afs, `orgs/${orgId}/staff-members/`),
      where('email', '==', userEmail)
    );

    return collectionData<OrgStaffMember>(q as Query<OrgStaffMember>, {
      idField: 'id' as any,
    }).pipe(
      take(1),
      map((staffMs) => staffMs[0])
    );
  }

  async updateOrgStaffMember(orgId: string, uid: string, data: OrgStaffMember) {
    const orgRef = doc(this.afs, `orgs/${orgId}/staff-members/${uid}`);
    data.updatedAt = Timestamp.now();
    return await updateDoc(orgRef, data as any);
  }

  async createOrgStaffMember(orgId: string, data: Partial<OrgStaffMember>) {
    const subCollectionRef = collection(
      this.afs,
      `orgs/${orgId}/staff-members/`
    );
    const docRef = doc(subCollectionRef);
    const uid = docRef.id;
    console.log({ uid });
    console.log({ docRef });
    data.createdAt = Timestamp.now();
    data.uid = uid;
    return await setDoc(docRef, data).catch(console.log);
  }

  async deleteOrgStaffMember(orgId: string, uid: string) {
    const orgRef = doc(this.afs, `orgs/${orgId}/staff-members/${uid}`);
    return await deleteDoc(orgRef);
  }

  // events
  getOrgEvents(orgId: string) {
    const col = collection(this.afs, `orgs/${orgId}/events/`);
    return collectionData(col) as Observable<OrgEvent[]>;
  }

  async getOrgEvent(orgId: string, uid: string): Promise<OrgEvent> {
    const orgRef = doc(this.afs, `orgs/${orgId}/events/${uid}`);
    return await getDoc(orgRef).then(
      (doc) => doc.exists() && (doc?.data() as OrgEvent)
    );
  }

  async updateOrgEvent(orgId: string, uid: string, data: OrgEvent) {
    const orgRef = doc(this.afs, `orgs/${orgId}/events/${uid}`);
    return await updateDoc(orgRef, data as any);
  }

  async createOrgEvent(orgId: string, data: OrgEvent) {
    const subCollectionRef = collection(this.afs, `orgs/${orgId}/events/`);
    const docRef = doc(subCollectionRef);
    const uid = docRef.id;

    console.log({ uid });
    console.log({ docRef });

    data.uid = uid;
    return await setDoc(docRef, data).catch(console.log);
  }

  // chart of accounts
  getOrgChartOfAccounts(orgId: string): Observable<OrgChartOfAccount[]> {
    const col = collection(this.afs, `orgs/${orgId}/chartOfAccounts/`);
    return collectionSnapshots(col).pipe(
      map((col) => col.map((doc) => doc.data() as OrgChartOfAccount))
    );
  }

  async getOrgChartOfAccount(
    orgId: string,
    uid: string
  ): Promise<OrgChartOfAccount> {
    const orgRef = doc(this.afs, `orgs/${orgId}/chartOfAccounts/${uid}`);
    return await getDoc(orgRef).then(
      (doc) => doc.exists() && (doc?.data() as OrgChartOfAccount)
    );
  }

  async updateOrgChartOfAccount(
    orgId: string,
    uid: string,
    data: OrgChartOfAccount
  ) {
    const orgRef = doc(this.afs, `orgs/${orgId}/chartOfAccounts/${uid}`);
    data.updatedAt = Timestamp.now();
    return await updateDoc(orgRef, data as any);
  }

  async createOrgChartOfAccount(
    orgId: string,
    data: Partial<OrgChartOfAccount>
  ) {
    const subCollectionRef = collection(
      this.afs,
      `orgs/${orgId}/chartOfAccounts/`
    );

    const docRef = doc(subCollectionRef);
    const uid = docRef.id;
    data.uid = uid;
    data.createdAt = Timestamp.now();
    return await setDoc(docRef, data).catch(console.log);
  }

  async createOrgLedgerEntry(orgId: string, data: OrgLedgerEntry) {
    const subCollectionRef = collection(
      this.afs,
      `orgs/${orgId}/ledgerEntries/`
    );
    const docRef = doc(subCollectionRef);
    const uid = docRef.id;
    data.uid = uid;
    data.createdAt = Timestamp.now();
    return await setDoc(docRef, data).catch(console.log);
  }

  async updateOrgLedgerEntry(orgId: string, uid: string, data: OrgLedgerEntry) {
    const orgRef = doc(this.afs, `orgs/${orgId}/ledgerEntries/${uid}`);
    data.updatedAt = Timestamp.now();
    return await updateDoc(orgRef, data as any);
  }

  getOrgChartAccountLedgerEntries(orgId: string, chartAccountId: string) {
    const col = collection(this.afs, `orgs/${orgId}/ledgerEntries/`);
    const whereFn1 = where('creditAccount.uid', '==', chartAccountId);
    const whereFn2 = where('debitAccount.uid', '==', chartAccountId);

    // const q = query(col, whereFn1, whereFn2);

    const q1 = query(col, whereFn1);
    const q2 = query(col, whereFn2);

    return combineLatest([
      collectionSnapshots(q1),
      collectionSnapshots(q2),
    ]).pipe(
      map(
        ([snap1, snap2]) =>
          [
            ...snap1.map((doc) => doc.data()),
            ...snap2.map((doc) => doc.data()),
          ] as OrgLedgerEntry[]
      ),
      map((col) =>
        col.map((data) => {
          const obj = {};
          if (data?.createdAt) {
            obj['createdAt'] = toDate(data.createdAt);
          }
          if (data?.updatedAt) {
            obj['updatedAt'] = toDate(data.updatedAt);
          }
          if (data?.date) {
            obj['date'] = toDateNoTime(data?.date);
          }
          return { ...data, ...obj };
        })
      )
    );
  }

  deleteOrgLedgerEntry(orgId: string, uid: string) {
    const orgRef = doc(this.afs, `orgs/${orgId}/ledgerEntries/${uid}`);
    return deleteDoc(orgRef);
  }

  // payments
  getOrgPayments(orgId: string) {
    const col = collection(this.afs, `orgs/${orgId}/payments/`);
    return collectionSnapshots(col).pipe(
      map((col) => col.map((doc) => doc.data()))
    );
  }

  async getOrgPayment(orgId: string, uid: string): Promise<OrgPayment> {
    const orgRef = doc(this.afs, `orgs/${orgId}/payments/${uid}`);
    return await getDoc(orgRef).then(
      (doc) => doc.exists() && (doc?.data() as OrgPayment)
    );
  }

  async updateOrgPayment(orgId: string, uid: string, data: OrgPayment) {
    const orgRef = doc(this.afs, `orgs/${orgId}/payments/${uid}`);
    data.updatedAt = Timestamp.now();
    return await updateDoc(orgRef, data as any);
  }

  async createOrgPayment(orgId: string, accountId: string, data: OrgPayment) {
    const subCollectionRef = collection(this.afs, `orgs/${orgId}/payments/`);
    const docRef = doc(subCollectionRef);
    const uid = docRef.id;
    data.uid = uid;
    data.createdAt = Timestamp.now();
    data.accountId = accountId;
    return await setDoc(docRef, data).catch(console.log);
  }

  // debit notes
  getOrgDebitNotes(orgId: string) {
    const col = collection(this.afs, `orgs/${orgId}/debitNotes/`);
    return collectionSnapshots(col).pipe(
      map((col) => col.map((doc) => doc.data()))
    );
  }

  async getOrgDebitNote(orgId: string, uid: string): Promise<OrgDebitNote> {
    const orgRef = doc(this.afs, `orgs/${orgId}/debitNotes/${uid}`);
    return await getDoc(orgRef).then(
      (doc) => doc.exists() && (doc?.data() as OrgDebitNote)
    );
  }

  async updateOrgDebitNote(orgId: string, uid: string, data: OrgDebitNote) {
    const orgRef = doc(this.afs, `orgs/${orgId}/debitNotes/${uid}`);
    data.updatedAt = Timestamp.now();
    return await updateDoc(orgRef, data as any);
  }

  async createOrgDebitNote(
    orgId: string,
    accountId: string,
    data: OrgDebitNote
  ) {
    const subCollectionRef = collection(this.afs, `orgs/${orgId}/debitNotes/`);
    const docRef = doc(subCollectionRef);
    const uid = docRef.id;
    data.uid = uid;
    data.createdAt = Timestamp.now();
    data.accountId = accountId;
    return await setDoc(docRef, data).catch(console.log);
  }

  // credit notes
  getOrgCreditNotes(orgId: string) {
    const col = collection(this.afs, `orgs/${orgId}/creditNotes/`);
    return collectionSnapshots(col).pipe(
      map((col) => col.map((doc) => doc.data()))
    );
  }

  async getOrgCreditNote(orgId: string, uid: string): Promise<OrgCreditNote> {
    const orgRef = doc(this.afs, `orgs/${orgId}/creditNotes/${uid}`);
    return await getDoc(orgRef).then(
      (doc) => doc.exists() && (doc?.data() as OrgCreditNote)
    );
  }

  async updateOrgCreditNote(orgId: string, uid: string, data: OrgCreditNote) {
    const orgRef = doc(this.afs, `orgs/${orgId}/creditNotes/${uid}`);
    data.updatedAt = Timestamp.now();
    return await updateDoc(orgRef, data as any);
  }

  async createOrgCreditNote(
    orgId: string,
    accountId: string,
    data: OrgCreditNote
  ) {
    const subCollectionRef = collection(this.afs, `orgs/${orgId}/creditNotes/`);
    const docRef = doc(subCollectionRef);
    const uid = docRef.id;
    data.uid = uid;
    data.createdAt = Timestamp.now();
    data.accountId = accountId;
    return await setDoc(docRef, data).catch(console.log);
  }

  //  legal

  async updateOrgTCS(orgId: string, data: string) {
    const orgRef = doc(this.afs, `orgs/${orgId}/legal/tcs`);
    return await setDoc(orgRef, { tcs: data });
  }

  async getOrgTCS(orgId: string): Promise<string> {
    const orgRef = doc(this.afs, `orgs/${orgId}/legal/tcs`);
    return await getDoc(orgRef).then(
      (doc) => doc.exists() && (doc?.data()?.tcs as string)
    );
  }

  getOrgLegal(orgId: string) {
    const orgRef = collection(this.afs, `orgs/${orgId}/legal`);
    return collectionSnapshots(orgRef).pipe(
      map((col) => col.map((doc) => doc.data()))
    );
  }

  async updateOrgPP(orgId: string, data: string) {
    const orgRef = doc(this.afs, `orgs/${orgId}/legal/pp`);
    return await setDoc(orgRef, { pp: data });
  }

  async getOrgPP(orgId: string): Promise<string> {
    const orgRef = doc(this.afs, `orgs/${orgId}/legal/pp`);
    return await getDoc(orgRef).then(
      (doc) => doc.exists() && (doc?.data()?.pp as string)
    );
  }
}
