import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BookProduct } from '../../models';

@Component({
  selector: 'radium-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  @Input() isBigDevice?: boolean;
  @Input() productsInCart?: BookProduct[] = [];
  @Input() uid?: string;
  @Input() total = 0;

  @Output() emitCartEvent = new EventEmitter();

  // constructor() { }

  ngOnInit(): void {
    console.log('cart');
  }

  deleteItemFromCart(productId: any) {
    this.emitCartEvent.emit({ type: 'delete', productId });
  }

  incrDecr(e: string, productId: any) {
    if (e === 'decrement') {
      this.emitCartEvent.emit({ type: 'decrement', productId });
    } else if (e === 'increment') {
      this.emitCartEvent.emit({ type: 'increment', productId });
    }
  }

  viewItem(productId: string) {
    this.emitCartEvent.emit({ type: 'viewItem', productId });
  }
}

//   {Object.values(productsInCart).length > 0 ? (
//       <>
//         <IonRow style={{ backgroundColor: "darkcyan" }}>
//   <IonCol className={"ion-padding-start"} size={"6"}>
//     Title
//     </IonCol>
//     <IonCol className={"ion-padding-start"}>Quantity</IonCol>
//     <IonCol className={"ion-padding-start"}>Price</IonCol>
//     </IonRow>
//
//   {Object.values(productsInCart)
//     .sort((a, b) => (a.id > b.id ? 1 : -1))
//     .map((p: IBookProduct, index) => (
//       <IonRow
//         key={index}
//     color="dark"
//     style={{ backgroundColor: "#222428" }}
//   >
//     <IonCol size={".3"}>
//     <IonButton
//
//     color={"clear"}
//     onClick={() => deleteItemFromCart(props.uid, p.id)}
//     size={"small"}
//     >
//     <IonIcon
//       size={"small"}
//     color={"danger"}
//     icon={trashOutline}
//     />
//     </IonButton>
//     </IonCol>
//     <IonCol
//     className={"ion-padding-start ion-padding-top"}
//     size={"5.5"}
//       >
//       {p.title} <br />
//       <small>{p.description}</small>
//       </IonCol>
//       <IonCol
//     className={
//       "ion-padding-start ion-padding-top ion-text-center"
//     }
//     >
//     <IonButton
//       className={"ion-no-padding ion-no-margin"}
//     color={"clear"}
//     size={"small"}
//     onClick={() => decrementQty(props.uid, p.id)}
//     disabled={p.quantity === 1}
//     >
//     <IonIcon size={"small"} icon={removeCircleOutline} />
//   </IonButton>
//     {"  " + p.quantity + "  "}
//     <IonButton
//       className={"ion-no-padding ion-no-margin"}
//     color={"clear"}
//     onClick={() => incrementQty(props.uid, p.id)}
//     size={"small"}
//     >
//     <IonIcon size={"small"} icon={addCircleOutline} />
//   </IonButton>
//   </IonCol>
//   <IonCol className={"ion-padding-start ion-padding-top"}>
//     R{p.price}
//     </IonCol>
//     </IonRow>
//   ))}
//   </>
// ) : (
//     <>
//       <IonItem color="dark" className={"ion-text-center"}>
//     Your cart is empty!
//   </IonItem>
//   </>
// )}
//   </IonList>
//   <IonItem color={"dark"}>
//   <IonLabel slot={"end"}>Subtotal: R{total}</IonLabel>
//   </IonItem>
//   <IonButton
//   disabled={
//   !productsInCart ||
//   (productsInCart && Object.values(productsInCart).length === 0)
// }
//
