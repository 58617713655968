import { FormlyFieldConfig } from '@ngx-formly/core';
import { OrgRegistrationField } from './Org';

const types = [
  'input',
  'number',
  'textarea',
  'checkbox',
  'radio',
  'select',
  'multiselect',
  'datetime',
  'date',
  'datetimepicker',
  'range',
  'phone',
  'email',
  'toggle',
];

export const accountFields: FormlyFieldConfig[] = [
  {
    key: 'accountNumber',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Account No',
      required: true,
      readonly: true,
    },
  },
  {
    key: 'name',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Name',
      required: true,
    },
  },
  {
    key: 'cellNumber',
    type: 'phone',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Cell Number',
      required: true,
    },
  },
  {
    key: 'email',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Email',
      required: true,
    },
  },
  {
    key: 'active',
    type: 'checkbox',
    defaultValue: true,
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Active',
      required: true,
    },
  },
  {
    key: 'balance',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Balance',
      required: true,
      readonly: true,
    },
  },
  // {
  //   key: 'createdAt',
  //   type: 'input',
  //   wrappers: ['radium-formly-wrapper'],
  //   props: {
  //     label: 'Created At',
  //     required: true,
  //     readonly: true,
  //   },
  // },
  // {
  //   key: 'updatedAt',
  //   type: 'input',
  //   wrappers: ['radium-formly-wrapper'],
  //   props: {
  //     label: 'Updated At',
  //     required: true,
  //     readonly: true,
  //   },
  // },
  {
    key: 'billedUntil',
    type: 'datetimepicker',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Billed Until',
      required: true,
      readonly: true,
    },
  },
  {
    key: 'discount',
    type: 'number',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Discount',
      required: false,
    },
  },
  {
    key: 'discountType',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Discount Type',
      required: false,
    },
  },
  {
    key: 'tcAccepted',
    type: 'checkbox',
    defaultValue: true,
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'TCs Accepted',
      required: true,
    },
  },
  {
    key: 'exposureToUs',
    type: 'select',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Where did you hear about us?',
      placeholder: 'Select on option',
      required: false,
      interface: 'popover',
      options: [
        { label: 'Google', value: 'Google' },
        { label: 'Facebook', value: 'Facebook' },
        { label: 'Twitter', value: 'Twitter' },
        { label: 'Instagram', value: 'Instagram' },
        { label: 'Word of Mouth', value: 'Word of Mouth' },
        { label: 'Other', value: 'Other' },
      ],
    },
  },
  {
    key: 'nextOfKinName',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Next of Kin Name',
      altLabel: 'Kin Name',
      attributes: {
        title: 'in case of emergency',
      },
      required: true,
    },
  },
  {
    key: 'nextOfKinCellNumber',
    type: 'phone',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Next of Kin Cell Number',
      attributes: {
        title: 'in case of emergency',
      },
      altLabel: 'Kin Cell Number',
      required: true,
    },
  },
  {
    key: 'sponsor',
    type: 'checkbox',
    defaultValue: false,
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Sponsor needed for settling the account?',
      altLabel: 'Sponsor needed',
      placeholder: 'Select on option',
      required: false,
    },
  },
  {
    key: 'vatNumber',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'VAT Number',
      required: false,
    },
  },
  {
    key: 'companyRegistrationNumber',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Company Reg. No',
      attributes: {
        title: 'Company Registration Number',
      },
    },
  },
  {
    key: 'comment',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Comments',
      required: false,
      attributes: {
        title: 'Any general comments you would like to add.',
      },
    },
  },
];

export const serviceFields: FormlyFieldConfig[] = [
  {
    key: 'name',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Name',
      placeholder: 'Enter Service Name',
      required: true,
      labelPosition: 'stacked',
    },
  },
  {
    key: 'description',
    type: 'textarea',
    defaultValue: '',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Description',
      placeholder: 'Enter Service Description',
      required: false,
      labelPosition: 'stacked',
    },
  },
  {
    key: 'active',
    type: 'checkbox',
    defaultValue: true,
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Active',
      placeholder: 'Check if Service Active',
    },
  },
  {
    key: 'groupSizeMax',
    type: 'number',
    defaultValue: 0,
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Max Group Size',
      placeholder: 'Enter Max Group Size',
      required: false,
    },
  },
  {
    key: 'duration',
    type: 'duration-picker',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Duration',
      placeholder: 'Enter Duration',
      required: true,

      minuteValues: '0,15,30,45',
      presentation: 'time',
    },
  },
  // {
  //   key: 'duration',
  //   type: 'datetime',
  //   wrappers: ['radium-formly-wrapper'],
  // props: {
  //     label: 'Duration',
  //     placeholder: 'Enter Duration',
  //     required: false,
  //
  //     minuteValues: '0,15,30,45',
  //     presentation: 'time',
  //   },
  // },
  {
    key: 'serviceFee',
    type: 'number',
    defaultValue: 0,
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Fee',
      placeholder: 'Enter Service Fee',
      required: false,
    },
  },
  {
    key: 'serviceRegFee',
    type: 'number',
    defaultValue: 0,
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Registration Fee',
      placeholder: 'Enter Service Registration Fee',
      required: false,
    },
  },
  {
    key: 'billingCategory',
    type: 'select',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Billing Category',
      placeholder: 'Select Billing Category',
      required: true,
      interface: 'popover',
      options: [
        { value: 'Add-Hoc', label: 'Add-Hoc' },
        { value: 'Monthly', label: 'Monthly' },
        { value: 'Annually', label: 'Annually' },
        { value: 'Semester', label: 'Semester' },
        { value: 'Quarterly', label: 'Quarterly' },
        { value: 'Event', label: 'Event' },
        { value: 'Other', label: 'Other' },
      ],
    },
  },
  {
    key: 'hideServiceOnRegistration',
    type: 'checkbox',
    defaultValue: false,
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Hide On Registration',
      placeholder: 'Check if Service should be hidden on registration',
      required: false,
    },
  },
];

export const venueFields: FormlyFieldConfig[] = [
  {
    key: 'name',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Name',
      placeholder: 'Enter Venue Name',
      required: true,
      labelPosition: 'stacked',
    },
  },
  {
    key: 'description',
    type: 'textarea',
    defaultValue: '',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Description',
      placeholder: 'Enter Venue Description',
      required: false,
      labelPosition: 'stacked',
    },
  },
  {
    key: 'active',
    type: 'checkbox',
    defaultValue: true,
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Active',
      placeholder: 'Check if Venue Active',
    },
  },
];

export const classFields: FormlyFieldConfig[] = [
  {
    key: 'name',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Class Name',
      required: true,
    },
  },
  {
    key: 'description',
    type: 'textarea',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Class Description',
      required: true,
    },
  },
  {
    key: 'active',
    type: 'checkbox',
    defaultValue: true,
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Active',
      placeholder: 'Check if Venue Active',
    },
  },
];

export const chartOfAccountsFields: FormlyFieldConfig[] = [
  {
    key: 'chartAccountNumber',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Account Number',
      placeholder: 'Enter Account Number',
      required: true,
      labelPosition: 'stacked',
    },
  },
  {
    key: 'name',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Name',
      placeholder: 'Enter Account Name',
      required: true,
      labelPosition: 'stacked',
    },
  },
  {
    key: 'active',
    type: 'checkbox',
    defaultValue: true,
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Active',
      placeholder: 'Check if Account Active',
    },
  },
  {
    key: 'balance',
    type: 'number',
    defaultValue: 0,
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Balance',
      placeholder: 'Enter Account Balance',
      required: false,
      readonly: true,
    },
  },
  {
    key: 'balanceSheetSubType',
    type: 'select',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Balance Sheet Sub Type',
      placeholder: 'Enter Balance Sheet Sub Type',
      required: false,
      labelPosition: 'stacked',
      interface: 'popover',
      options: [
        { value: 'A-B Cash Assets', label: 'A-B Cash Assets' },
        { value: 'A-C Current Assets', label: 'A-C Current Assets' },
        { value: 'A-F Fixed Assets', label: 'A-F Fixed Assets' },
        { value: 'A-X Customer Assets', label: 'A-X Customer Assets' },
        { value: 'C-E Expenses', label: 'C-E Expenses' },
        { value: 'C-I Income', label: 'C-I Income' },
        { value: 'C-O Other Income', label: 'C-O Other Income' },
        { value: 'C-X Owner Equity', label: 'C-X Owner Equity' },
        { value: 'L-C Current Liabilities', label: 'L-C Current Liabilities' },
        {
          value: 'L-L Long Term Liabilities',
          label: 'L-L Long Term Liabilities',
        },
      ],
    },
  },
  {
    key: 'toIncrease',
    type: 'select',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'To Increase',
      placeholder: 'Choose Debit or Credit',
      required: true,
      interface: 'popover',
      options: [
        { value: 'Debit', label: 'Debit' },
        { value: 'Credit', label: 'Credit' },
      ],
    },
  },
];

export const ledgerEntryFields: FormlyFieldConfig[] = [
  {
    key: 'date',
    type: 'datetimepicker',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Date',
      placeholder: 'Enter Date',
      required: true,
      presentation: 'day-month-year',
    },
  },
  {
    key: 'description',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Description',
      placeholder: 'Enter Description',
      required: true,
      labelPosition: 'stacked',
    },
  },
  {
    key: 'amount',
    type: 'number',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Amount',
      placeholder: 'Enter Amount',
      required: true,
    },
  },
  {
    key: 'debitAccount',
    type: 'select',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Debit Account',
      placeholder: 'Select Debit Account',
      required: true,
      options: [],
      valueProp: 'value',
      labelProp: 'label',
      interface: 'popover',
    },
  },
  {
    key: 'creditAccount',
    type: 'select',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Credit Account',
      placeholder: 'Select Credit Account',
      required: true,
      options: [],
      valueProp: 'value',
      labelProp: 'label',
      interface: 'popover',
    },
  },
];

export const paymentFields: FormlyFieldConfig[] = [
  {
    key: 'paymentMethod',
    type: 'select',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Payment Method',
      placeholder: 'Select Payment Method',
      required: true,
      interface: 'popover',
      options: [
        { value: 'cash', label: 'Cash' },
        { value: 'creditCard', label: 'Credit Card' },
        { value: 'EFT', label: 'EFT' },
        { value: 'other', label: 'Other' },
      ],
    },
  },

  {
    key: 'amount',
    type: 'number',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Amount',
      placeholder: 'Enter Amount',
      required: true,
    },
  },

  {
    key: 'date',
    type: 'datetimepicker',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Date',
      placeholder: 'Enter Date',
      required: true,
      presentation: 'day-month-year',
    },
  },

  {
    key: 'description',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Description',
      placeholder: 'Enter Description',
      required: true,
      labelPosition: 'stacked',
    },
  },
  {
    key: 'comment',
    type: 'input',
    defaultValue: '',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Comment',
      placeholder: 'Enter Comment',
      required: false,
      labelPosition: 'stacked',
    },
  },
  {
    key: 'paymentReference',
    type: 'input',
    defaultValue: '',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Payment Reference',
      placeholder: 'Enter Payment Reference',
      required: false,
    },
  },
];

export const debitNoteFields: FormlyFieldConfig[] = [
  {
    key: 'date',
    type: 'datetimepicker',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Date',
      placeholder: 'Enter Date',
      required: true,
      presentation: 'day-month-year',
    },
  },
  {
    key: 'description',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Description',
      placeholder: 'Enter Description',
      required: true,
      labelPosition: 'stacked',
    },
  },
  {
    key: 'amount',
    type: 'number',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Amount',
      placeholder: 'Enter Amount',
      required: true,
    },
  },
  {
    key: 'category',
    type: 'select',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Category',
      placeholder: 'Select Category',
      required: true,
      interface: 'popover',
      options: [
        { value: 'Cash Received Fix', label: 'Cash Received Fix' },
        { value: 'EFT Received Fix', label: 'EFT Received Fix' },
        { value: 'Deposit Request', label: 'Deposit Request' },
        { value: 'Opening Balance Debit', label: 'Opening Balance Debit' },
        { value: 'Refund Bank EFT', label: 'Refund Bank EFT' },
        { value: 'Refund Petty Cash', label: 'Refund Petty Cash' },
      ],
    },
  },
  {
    key: 'comment',
    type: 'input',
    defaultValue: '',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Comments',
      placeholder: 'Enter Comment',
      required: false,
      labelPosition: 'stacked',
    },
  },
];

export const creditNoteFields: FormlyFieldConfig[] = [
  {
    key: 'date',
    type: 'datetimepicker',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Date',
      placeholder: 'Enter Date',
      required: true,
      presentation: 'day-month-year',
    },
  },
  {
    key: 'description',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Description',
      placeholder: 'Enter Description',
      required: true,
      labelPosition: 'stacked',
    },
  },
  {
    key: 'amount',
    type: 'number',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Amount',
      placeholder: 'Enter Amount',
      required: true,
    },
  },
  {
    key: 'category',
    type: 'select',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Category',
      placeholder: 'Select Category',
      required: true,
      interface: 'popover',
      options: [
        { value: 'Credit Note', label: 'Credit Note' },
        { value: 'Refund Bank EFT', label: 'Refund Bank EFT' },
        { value: 'Refund Petty Cash', label: 'Refund Petty Cash' },
        { value: 'Write Off', label: 'Write Off' },
      ],
    },
  },
  {
    key: 'comment',
    type: 'input',
    defaultValue: '',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Comment',
      placeholder: 'Enter Comment',
      required: false,
      labelPosition: 'stacked',
    },
  },
];

// export interface OrgInvoiceLineItem {
//   uid: string;
//   invoiceId: string;
//   accountId: string;
//   createdAt?: Timestamp;
//   updatedAt?: Timestamp;
// }

export const invoiceLineItemFields: FormlyFieldConfig[] = [
  {
    key: 'service',
    type: 'select',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Service',
      placeholder: 'Enter Service',
      required: true,
      interface: 'popover',
      options: [],
    },
  },
  {
    key: 'attendee',
    type: 'select',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Attendee',
      placeholder: 'Enter Attendee',
      required: true,
      interface: 'popover',
      options: [],
    },
  },
  {
    key: 'fromDateInDescription',
    type: 'datetimepicker',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'From Date',
      placeholder: 'Enter From Date',
      required: true,
      presentation: 'day-month-year',
    },
  },
  {
    key: 'toDateInDescription',
    type: 'datetimepicker',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'To Date',
      placeholder: 'Enter To Date',
      required: true,
      presentation: 'day-month-year',
    },
  },
  {
    key: 'amount',
    type: 'number',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Amount',
      placeholder: 'Enter Amount',
      required: true,
    },
  },
  {
    key: 'comment',
    type: 'input',
    defaultValue: '',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Comments',
      placeholder: 'Enter Comments',
      required: false,
      labelPosition: 'stacked',
    },
  },
  {
    key: 'category',
    type: 'select',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Category',
      placeholder: 'Select Category',
      required: true,
      interface: 'popover',
      options: [
        { value: 'Invoice Normal', label: 'Invoice Normal' },
        { value: 'Invoice Discount', label: 'Invoice Discount' },
        { value: 'Invoice Other', label: 'Invoice Other' },
        { value: 'Invoice Registration', label: 'Invoice Registration' },
      ],
    },
  },
  {
    key: 'description',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Description',
      placeholder: 'Enter Description',
      required: true,
      labelPosition: 'stacked',
    },
  },
];

// export interface OrgInvoice {
//   uid: string;
//   accountId: string;
//   total: number;
//   date?: string;
//   createdAt?: Timestamp;
//   updatedAt?: Timestamp;
//   lineItems?: OrgInvoiceLineItem[];
// }

export const invoiceFields: FormlyFieldConfig[] = [
  {
    key: 'date',
    type: 'datetimepicker',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Date',
      placeholder: 'Enter Date',
      required: true,
      presentation: 'day-month-year',
    },
  },
  {
    key: 'total',
    type: 'number',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Total',
      placeholder: 'Enter Total',
      required: true,
    },
  },
];

export const regSetupFields: FormlyFieldConfig[] = [
  {
    key: 'label',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Label',
      placeholder: 'Enter Field Label',
      required: true,
      labelPosition: 'stacked',
    },
  },
  {
    key: 'description',
    type: 'input',
    defaultValue: '',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Short Description',
      placeholder: 'Enter Field Description',
      required: false,
      labelPosition: 'stacked',
    },
  },
  // {
  //   key: 'details',
  //   type: 'textarea',
  //   defaultValue: '',
  //   wrappers: ['radium-formly-wrapper'],
  // props: {
  //     label: 'Field Details',
  //     placeholder: 'Enter Field Details',
  //     required: false,
  //     labelPosition: 'stacked',
  //   },
  // },
  {
    key: 'type',
    type: 'select',
    defaultValue: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Type',
      placeholder: 'Select Field Type',
      required: true,
      interface: 'popover',
      options: [...types.map((type) => ({ label: type, value: type }))],
    },
  },
  {
    key: 'state',
    type: 'select',
    defaultValue: 'Optional',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'State',
      placeholder: 'Select Field State',
      required: true,
      interface: 'popover',
      options: [
        { label: 'Hidden', value: 'Hidden' },
        { label: 'Optional', value: 'Optional' },
        { label: 'Required', value: 'Required' },
      ],
    },
  },
];

export const formData: OrgRegistrationField[] = [
  {
    label: 'Name',
    state: 'Required',
    type: 'input',
    description: 'Attendee Name',
  },
  {
    label: 'Surname',
    state: 'Required',
    description: 'Attendee Surname',
    type: 'input',
  },
  {
    label: 'Email',
    state: 'Required',
    description: 'Attendee Email',
    type: 'email',
  },
  {
    label: 'Cell Number',
    state: 'Required',
    description: 'Attendee Cell Number',
    type: 'phone',
  },
  {
    label: 'Birth Date',
    state: 'Required',
    description: 'Attendee Birth Date',
    type: 'datetimepicker',
  },
  {
    label: 'Comments',
    state: 'Optional',
    description: 'Any comments you would like to add',
    type: 'textarea',
    defaultValue: '',
  },
  {
    label: 'Competency',
    state: 'Optional',
    description: 'Attendee Competency',
    type: 'textarea',
    defaultValue: '',
  },
  {
    label: 'Gender',
    state: 'Required',
    description: 'Attendee Gender',
    type: 'select',
    options: ['Male', 'Female'],
  },
  {
    label: 'Languages',
    state: 'Required',
    description: 'Attendee Languages, select all that apply',
    type: 'select',
    multiple: true,
    options: ['English'],
  },
  {
    label: 'Occupation',
    state: 'Required',
    description: 'Attendee Occupation',
    type: 'input',
  },
  {
    label: 'Address',
    state: 'Optional',
    description: 'Attendee Home Address',
    type: 'textarea',
    defaultValue: '',
  },
  {
    label: 'Medical Conditions',
    state: 'Optional',
    description: 'Medical Conditions, Concerns, Allergies or Notes',
    type: 'textarea',
    defaultValue: '',
  },
  {
    label: 'Class',
    state: 'Required',
    description: 'School/Class/Group/Team/Club/Year/Grade',
    type: 'select',
    options: [],
  },
  {
    label: 'Services',
    state: 'Required',
    description: 'Attendee Services',
    type: 'select',
    options: [],
  },
  {
    label: 'School Grade',
    state: 'Optional',
    description: 'Attendee School Grade',
    type: 'input',
    defaultValue: '',
  },
  {
    label: 'Id Number',
    state: 'Required',
    description: 'Attendee ID Number',
    type: 'input',
  },
  {
    label: 'Transport Needed',
    state: 'Optional',
    description: 'Does Attendee require transport?',
    type: 'checkbox',
    defaultValue: false,
  },
  {
    label: 'Medical Aid Detail',
    state: 'Optional',
    description: 'Medical Aid Details of attendee',
    type: 'textarea',
  },
  // {
  //   label: 'createdAt',
  //   state: 'Required',
  //   description: 'Attendee created date',
  //   type: 'date',
  // },
  // {
  //   label: 'updatedAt',
  //   state: 'Required',
  //   description: 'Attendee updated date',
  //   type: 'date',
  // },
];

export const emailMessages = [
  {
    type: 'registration',
    message: `<p>Dear {{ name }}</p><p></p><p>Welcome to {{ companyName }}.</p><p></p><p>You are now successfully registered on our Admineze system. </p><p></p><p>Your new account number is: {{ accountNumber }}. </p><p>IMPORTANT: Please use this number in all your EFTs and correspondence. </p><p></p><p>Please note that our fees will be charged at month end. </p><p>On month end you will receive a statement from us showing all account history and amount outstanding. </p><p>We will be loading registration fees and pro-rata fees if applicable before month end. </p><p></p><p>You can login to our Admineze system from this URL: {{ companyURL }}. </p><p>Use this email address and the password supplied during registration to log in. </p><p>If you do not know your password, use the forget password button and form to retrieve it. </p><p></p><p>From this login you will be able to:</p><ul><li><p>View your latest statement </p></li><li><p>Send a message to us </p></li><li><p>Maintain your account</p><p></p></li></ul><p>Call us on phone number or reply to this email should you have any questions or account queries. </p><p></p><p>Thank you</p>`,
  },
  {
    type: 'invoice',
    message: `<p>Dear {{ name }}</p><p>Please find attached your invoice of {{ balance }}. </p><p>Please note that payment is due on the first of each month. </p><p>Thank you </p><p>Account Number: {{ accountNumber }} </p><p>Attendees: {{ accountAttendees }}</p>`,
  },
  {
    type: 'receipt',
    message: `<p>Dear {{ name }} </p><p>Please find attached your receipt for {{ balance }}. </p><p>Thank you for the payment! </p><p></p><p>Account Number: {{ accountNumber }} </p><p>Attendees: {{ accountAttendees }}</p>`,
  },
  {
    type: 'statement',
    message: `<p>Dear {{ name }},</p><p>Please find attached your latest statement.</p><p>Your current account balance is {{ balance }}. (minus amount means that you are in credit). </p><p>Please note that payment is due on the first of each month. </p><p>Thank You </p><p>Account Number: {{ accountNumber }} </p><p>Attendees: {{ accountAttendees }}</p>`,
  },
];

export const chartOfAccounts = [
  {
    chartAccountNumber: 'A-B-1501',
    name: 'Current Account',
    active: true,
    balanceSheetSubType: 'A-B Cash Assets',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'A-B-1502',
    name: 'Petty Cash',
    active: true,
    balanceSheetSubType: 'A-B Cash Assets',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'A-C-1505',
    name: 'Stock',
    active: true,
    balanceSheetSubType: 'A-C Current Assets',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'A-F-1001',
    name: 'Land and Building',
    active: true,
    balanceSheetSubType: 'A-F Fixed Assets',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'A-F-1002',
    name: 'Building Improvements',
    active: true,
    balanceSheetSubType: 'A-F Fixed Assets',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'A-F-1003',
    name: 'Vehicles',
    active: true,
    balanceSheetSubType: 'A-F Fixed Assets',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'A-F-1004',
    name: 'Furniture and Fixtures',
    active: true,
    balanceSheetSubType: 'A-F Fixed Assets',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'A-F-1005',
    name: 'Equipment',
    active: true,
    balanceSheetSubType: 'A-F Fixed Assets',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'A-X-1503',
    name: 'Trade Debtors/Accounts Receivable',
    active: true,
    balanceSheetSubType: 'A-X Customer Assets',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'A-X-1504',
    name: 'Bad Debt',
    active: true,
    balanceSheetSubType: 'A-X Customer Assets',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4001',
    name: 'Auditors Remuneration',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4002',
    name: 'Bank Charges',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4003',
    name: 'Company Memberships',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },

  {
    chartAccountNumber: 'C-E-4004',
    name: 'Donations',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4005',
    name: 'Electricity and Water',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4006',
    name: 'Entertainment',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4007',
    name: 'General Expense',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4008',
    name: 'HR',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4009',
    name: 'Insurance',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4010',
    name: 'Lawyer Fees',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4011',
    name: 'Marketing Events',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4012',
    name: 'Marketing General',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4013',
    name: 'Marketing Print and Advertising',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4014',
    name: 'Office Computers and Equipment',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4015',
    name: 'Office Furniture',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4016',
    name: 'Office Internet',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4017',
    name: 'Office Refreshments',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4018',
    name: 'Office Rent',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4019',
    name: 'Office Servers and Hosting',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4020',
    name: 'Office Software Tools',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4021',
    name: 'Office Stationary',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4022',
    name: 'Office Telephone',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4023',
    name: 'Salaries and Wages',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4024',
    name: 'Sales General Expenses',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4025',
    name: 'SARS PAYE',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4026',
    name: 'SARS UIF',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4028',
    name: 'Training',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-E-4029',
    name: 'Travel',
    active: true,
    balanceSheetSubType: 'C-E Expenses',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-1-3501',
    name: 'Sales',
    active: true,
    balanceSheetSubType: 'C-I Income',
    toIncrease: 'Credit',
    balance: 0,
  },

  {
    chartAccountNumber: 'C-1-3504',
    name: 'Sales Discounts',
    active: true,
    balanceSheetSubType: 'C-I Income',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-1-3505',
    name: 'Sales Returns',
    active: true,
    balanceSheetSubType: 'C-I Income',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-O-3502',
    name: 'Interest Income',
    active: true,
    balanceSheetSubType: 'C-O Other Income',
    toIncrease: 'Credit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-O-3503',
    name: 'Other Income',
    active: true,
    balanceSheetSubType: 'C-O Other Income',
    toIncrease: 'Credit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-X-3002',
    name: 'Dividents/Owner Drawing',
    active: true,
    balanceSheetSubType: 'C-X Owner Equity',
    toIncrease: 'Debit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-X-3003',
    name: 'Opening Asset Balance',
    active: true,
    balanceSheetSubType: 'C-X Owner Equity',
    toIncrease: 'Credit',
    balance: 0,
  },
  {
    chartAccountNumber: 'C-X-4000',
    name: 'Profit or Loss Carried over',
    active: true,
    balanceSheetSubType: 'C-X Owner Equity',
    toIncrease: 'Credit',
    balance: 0,
  },
  {
    chartAccountNumber: 'L-C-2501',
    name: 'Trade Creditors/Accounts Payable',
    active: true,
    balanceSheetSubType: 'L-C Current Liabilities',
    toIncrease: 'Credit',
    balance: 0,
  },
  {
    chartAccountNumber: 'L-C-2502',
    name: 'VAT',
    active: true,
    balanceSheetSubType: 'L-C Current Liabilities',
    toIncrease: 'Credit',
    balance: 0,
  },
  {
    chartAccountNumber: 'L-C-4004',
    name: 'Security Deposit',
    active: true,
    balanceSheetSubType: 'L-C Current Liabilities',
    toIncrease: 'Credit',
    balance: 0,
  },
  {
    chartAccountNumber: 'L-L-2001',
    name: 'Bank Loan',
    active: true,
    balanceSheetSubType: 'L-L Long Term Liabilities',
    toIncrease: 'Credit',
    balance: 0,
  },
  {
    chartAccountNumber: 'L-L-2002',
    name: 'Owner Loan Account',
    active: true,
    balanceSheetSubType: 'L-L Long Term Liabilities',
    toIncrease: 'Credit',
    balance: 0,
  },
];

export const legal = [
  {
    type: 'TCS',
    message: `By using our website and services, you agree to the following:1) We provide educational and extracurricular activities for a fee.2) Payment is due upon registration and is non-refundable unless otherwise agreed upon in writing. We will not turn any student away who cannot pay the full fee or part of it.3) You may cancel your registration at any time.4) Our website and services, including all content and intellectual property, are owned by us or our licensors.5) We are not liable for any damages arising out of your use of our website and services.6) You agree to indemnify and hold us harmless from any claims arising out of your use of our website and services.7) We reserve the right to modify these terms and conditions at any time.8) If you have any questions about these terms and conditions, please contact us.`,
  },
  {
    type: 'PP',
    message: `We collect personal information such as your name, email address, and payment details when you register for an account or purchase a service. We use your information to provide and improve our services, process payments, and communicate with you about your account. We may also offer transportation and sponsorship options for our services, and collect additional personal information for these purposes. We take appropriate measures to protect your information and will not sell or rent it to third parties. We may use cookies to improve your experience on our website.`,
  },
];

export const staffFields: FormlyFieldConfig[] = [
  {
    key: 'name',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Name',
      required: true,
    },
  },
  {
    key: 'surname',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Surname',
      required: true,
    },
  },
  {
    key: 'role',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Role',
      required: true,
    },
  },
  {
    key: 'email',
    type: 'email',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Email',
      required: true,
    },
  },
  {
    key: 'cellNumber',
    type: 'phone',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Cell Number',
      required: true,
    },
  },
  {
    key: 'active',
    type: 'checkbox',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Active',
      required: true,
    },
  },
  {
    key: 'accessLevel',
    type: 'select',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Access Level',
      required: true,
      options: [
        { value: 'Admin', label: 'Admin' },
        { value: 'Staff', label: 'Staff' },
        { value: 'Manager', label: 'Manager' },
      ],
    },
  },
  {
    key: 'description',
    type: 'input',
    wrappers: ['radium-formly-wrapper'],
    props: {
      label: 'Description',
      required: false,
    },
  },
];
