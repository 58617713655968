import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Order } from '@radium/ui';
import { FirebaseBusinessService } from '@radium/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'radium-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss'],
})
export class OrderComponent {
  @Input() order!: Order;
  @Output() orderChanged: EventEmitter<Order> = new EventEmitter();

  constructor(
    public fireBusService: FirebaseBusinessService,
    private modalCtrl: ModalController
  ) {}
}
