<form [formGroup]="adhocJobForm" (ngSubmit)="onSubmit()">
  <ion-item>
    <ion-textarea
      rows="8"
      placeholder="You can send us from message here. Alternatively, you can book a service by clicking the button below."
      [autoGrow]="true"
      formControlName="message"
    ></ion-textarea>
  </ion-item>
  <ion-button
    expand="full"
    color="primary"
    type="submit"
    [disabled]="adhocJobForm?.invalid"
  >
    Submit
  </ion-button>
</form>

<div style="position: absolute; bottom: 10%; width: 95%">
  <p class="ion-text-center" style="font-size: 10px; color: red; margin: 0">
    Please note we currently only operate in the Western Cape area
  </p>

  <ion-button
    class="ion-text-center ion-margin"
    routerLink="/add-job/new"
    expand="full"
    (click)="adhocJobEvent.emit({ type: 'closeModal' })"
    >Book your services now
  </ion-button>
</div>
