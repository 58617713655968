<ion-toolbar>
  <ion-title>{{ data?.title }}</ion-title>
</ion-toolbar>

<ion-fab vertical="top" horizontal="end" slot="fixed" *ngIf="!uploading">
  <ion-fab-button size="small" color="danger" (click)="closeComponent()">
    <ion-icon name="close"></ion-icon>
  </ion-fab-button>
</ion-fab>

<ion-content class="ion-padding-horizontal">
  <!--  Preview -->
  <div *ngIf="preview">
    <radium-preview-images
      (previewAction)="actionThePreview($event)"
      (imageAction)="actionTheImage($event)"
      [files]="$any(data?.images)"
      [canAddImage]="true"
      [canDelete]="true"
    ></radium-preview-images>
  </div>

  <!-- Upload -->
  <div
    *ngIf="uploading"
    class="ion-text-center"
    style="margin-top: 10%; border: solid 8px lightgrey; padding: 10px"
  >
    <radium-upload-task
      [fileInfo]="$any(file)"
      (imageData)="setImageData($event)"
    ></radium-upload-task>
  </div>

  <!--  Dropzone -->
  <div
    *ngIf="data?.isBigDevice && !preview && !uploading"
    class="dropzone"
    radiumDropzone
    (hovered)="toggleHover($event)"
    (dropped)="onImageSelected($event.file, $event.url)"
    [class.hovering]="isHovering"
  >
    <h3>Drop Zone</h3>
    <p>Drag and Drop a Picture</p>

    <radium-select-file
      [isBigDevice]="data?.isBigDevice"
      [url]="url"
      (dropAction)="onImageSelected($event)"
      (selectFileAction)="actionThePreview($event)"
    ></radium-select-file>
    <br />
    <radium-select-dropdown
      *ngIf="includeCategories"
      [dataList]="data?.imageCategories"
      [heading]="data?.categoryName"
      (selected)="selectedImageCategory = $event"
    ></radium-select-dropdown>
  </div>

  <!-- Select file and category -->
  <div *ngIf="!data?.isBigDevice && !preview && !uploading">
    <radium-select-file
      [isBigDevice]="data?.isBigDevice"
      (dropAction)="onImageSelected($event)"
      (selectFileAction)="actionThePreview($event)"
    ></radium-select-file>
    <br />

    <radium-select-dropdown
      *ngIf="includeCategories"
      [dataList]="data?.imageCategories"
      [heading]="data?.categoryName"
      (selected)="selectedImageCategory = $event"
    ></radium-select-dropdown>
  </div>

  <!-- Form -->
  <form
    [formGroup]="$any(imageForm)"
    *ngIf="!preview && !uploading && includeImageForm"
  >
    <ion-item>
      <ion-label position="floating">Title:</ion-label>
      <ion-input
        formControlName="title"
        [class.invalid]="
          $any(imageForm).controls?.['title']?.invalid &&
          ($any(imageForm).controls?.['title']?.dirty || submitAttempt)
        "
      >
      </ion-input>
    </ion-item>

    <radium-form-error-display
      [control]="$any(imageForm)?.controls?.['title']"
    ></radium-form-error-display>

    <ion-item>
      <ion-label position="floating">Description:</ion-label>
      <ion-textarea
        rows="4"
        formControlName="description"
        [class.invalid]="
          $any(imageForm).controls['description'].invalid &&
          ($any(imageForm).controls['description'].dirty || submitAttempt)
        "
      ></ion-textarea>
    </ion-item>

    <radium-form-error-display
      [control]="$any(imageForm)?.controls['description']"
    ></radium-form-error-display>

    <ion-button
      color="success"
      expand="full"
      class="ion-margin-bottom"
      (click)="addImage()"
      >Complete
    </ion-button>
  </form>

  <ion-button
    color="success"
    expand="full"
    shape="round"
    *ngIf="!includeImageForm && file && !uploading"
    (click)="addImage()"
    >Done
  </ion-button>

  <!--  <ion-item-->
  <!--    *ngIf="data?.images?.length === 0 && submitAttempt && !preview"-->
  <!--    class="alert-danger"-->
  <!--    lines="none"-->
  <!--  >-->
  <!--    <ion-label-->
  <!--      color="danger"-->
  <!--      class="ion-text-center ion-no-margin ion-margin-top"-->
  <!--      >* Please add an image first</ion-label-->
  <!--    >-->
  <!--  </ion-item>-->

  <ion-item
    *ngIf="!file && submitAttempt && !preview && !uploading"
    class="alert-danger"
    lines="none"
  >
    <ion-label
      color="danger"
      class="ion-text-center ion-no-margin ion-margin-top"
      >* Please add an image first
    </ion-label>
  </ion-item>
  <br />
</ion-content>
