<ion-app>
  <radium-splash-screen
    style="z-index: 2000000000; height: 100%"
    (done)="done($event)"
    *ngIf="!canShowNow"
    img="../assets/icons/hhnr_logo.png"
    [backgroundSize]="'40% 20%'"
    duration="2500"
  >
    <p style="margin: 0"></p>
  </radium-splash-screen>
  <!-- how do i make it such that the split-pane does not show -->

  <ion-split-pane [disabled]="true" *ngIf="canShowNow" contentId="main-content">
    <ion-menu contentId="main-content" type="overlay" #menuC>
      <radium-menu
        [appPages]="appPages"
        [isAnonymous]="isAnonymous"
        [uid]="uid"
        (go)="go($event)"
        [isAdmin]="isAdmin"
        [version]="version"
      ></radium-menu>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
