import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  Address,
  BookProduct,
  Cart,
  LocationDetailsComponent,
  User,
  UserBasicInfoComponent,
} from '@radium/ui';
import { map, take } from 'rxjs/operators';
import { FormBuilder } from '@angular/forms';
import { FirebaseBookService, FirebaseBusinessService } from '@radium/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, Subscription } from 'rxjs';
import { MatStep } from '@angular/material/stepper';
import { postToURL } from '../../../../../utils';

@Component({
  selector: 'radium-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('locationDetails') ld?: LocationDetailsComponent;
  @ViewChild('basicInfo') basicInformation?: UserBasicInfoComponent;
  total: number;
  productsInCart: BookProduct[];
  @Input() uid?: string;
  // details: Details;
  paymentId;

  isDelivery = true;
  finalTotal = 0;
  paymentDetails;
  loading = true;
  deliveryCost = 100;
  location: any;
  sub: Subscription;
  sub1: Subscription;
  sub12: Subscription;
  getParamsSub: any;
  fromPayfast: boolean;
  clientAddress: string;

  @ViewChild('step')
  public step: MatStep;

  // constructor() {}

  @Output() eventFromCheckoutComp = new EventEmitter();
  collectDate = new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000);
  fastwayPickupDate;
  deliverToPostnet: boolean;
  sub4: Subscription;
  sub5: Subscription;
  subIsSet: boolean;
  addressInUse: Address;
  isPile = false;
  @Input() user: User;
  @Input() pileAllowed = false;

  constructor(
    private fb: FormBuilder,
    public fireBusService: FirebaseBusinessService,
    private router: Router,
    public toastCtrl: ToastController,
    public http: HttpClient,
    public fireBookService: FirebaseBookService,
    @Inject('environment') private env: any
  ) {}

  ngOnInit(): void {
    this.sub5 = this.fireBusService
      .getCart(this.uid)
      .subscribe((cart: Cart) => {
        console.log({ cart });

        if (cart?.products) {
          const productsArray = Object.values(cart?.products);
          this.productsInCart = productsArray.sort((a, b) =>
            a.id > b.id ? 1 : -1
          );

          this.total = this.productsInCart.reduce(
            (p: number, j: BookProduct) => {
              let a = j.quantity * Number(j.price);
              a += p;
              return a;
            },
            0
          );

          this.paymentId = cart?.PFPaymentId;
          this.isDelivery = cart?.deliveryMethod === 'deliver';
          this.isPile = cart?.isPile;
        }
      });
    this.clientAddress = this.env.clientAddress;
  }

  ngAfterViewInit() {
    console.log({ gg: this.user });

    this.sub4 = this.step?._stepper?.selectionChange.subscribe((x) => {
      this.handleNext(x.selectedIndex).catch();
    });

    this.basicInformation?.userForm?.patchValue({
      contactNumber: this.user?.contactNumber ?? '',
      whatsappNumber:
        this.user?.whatsappNumber ?? this.user?.contactNumber ?? '',
      email: this.user?.email,
      displayName: this.user?.displayName,
    });

    // this.details = {
    //   contact: x?.contactNumber ?? '',
    //   email: x?.email,
    //   firstName: x?.displayName?.split(' ')[0] ?? 'Anonymous',
    //   lastName: x?.displayName?.split(' ')[1] ?? 'User',
    // };

    if (this.user?.addresses?.length > 0 && this.ld) {
      this.ld?.initializeAddresses(this.user?.addresses);
    }
  }

  private async setCosts() {
    this.finalTotal = this.total;
    const totalWeight = this.productsInCart.reduce((a, b) => a + b.weight, 0);

    if (this.addressInUse?.city) {
      this.deliveryCost = await this.calcDelivery(totalWeight);
    }

    this.finalTotal += this.isDelivery ? this.deliveryCost : 0;
    console.log('kkkkk: ', this.finalTotal);
  }

  emitEventWithData(event, data?) {
    this.eventFromCheckoutComp.emit({ event, data, from: 'checkout' });
  }

  setDelivery() {
    this.isDelivery = !this.isDelivery;
  }

  async handleNext(selectedIndex) {
    await this.setCosts();
    const data: Partial<Cart> = {};
    if (selectedIndex === 1) {
      await this.setCosts().catch();
      data.deliveryCost = this.deliveryCost;
      data.deliveryMethod = this.isDelivery ? 'deliver' : 'collect';
      if (this.addressInUse?.city) {
        data['deliveryAddress'] = this.addressInUse;
      }
    } else if (selectedIndex === 2) {
      data.details = { ...this.basicInformation?.userForm?.value };
    }
    // TODO fix types here
    await this.fireBusService.updateCart(this.uid, data).catch();

    this.emitEventWithData('updateUser', {
      ...this.basicInformation?.userForm?.value,
    });
  }

  async placeOrder() {
    const name_first = this.basicInformation?.userForm
      .get('displayName')
      .value.split(' ')[0];

    const name_last = this.basicInformation?.userForm
      .get('displayName')
      .value.split(' ')[1];

    const email_address = this.basicInformation?.userForm?.get('email').value;

    const payDetails = {
      name_first: name_first?.trim() ?? 'Anonymous',
      name_last: name_last?.trim() ?? 'User',
      email_address: email_address?.trim(),
      // cell_number: details.email.trim(),

      m_payment_id: this.paymentId ? this.paymentId.trim() : 'none',
      amount: this.finalTotal,
      item_name: `Cart from ${this.env.clientName}`.trim(),
      item_description: `Your cart from ${this.env.clientName}`.trim(),
      // email_confirmation: 1,
      // confirmation_address: "turnono@gmail.com"
      custom_str1: this.uid?.trim(),
      custom_str2: this.env.production ? 'prod' : 'dev',
      custom_str4: 'pile' + this.isPile?.toString(),
    };
    if (this.fastwayPickupDate) {
      payDetails['custom_str3'] = this.fastwayPickupDate;
    }

    const ids = this.productsInCart.map((p) => p.id);
    const PWQLTO =
      await this.fireBookService.getProductsWithQuantitiesLessThanOne(ids);

    if (PWQLTO.length > 0) {
      this.toast().catch();
    } else {
      setTimeout(() => {
        postToURL(payDetails, this.env);
      }, 600);
    }
  }

  async toast() {
    const toast = await this.toastCtrl.create({
      color: 'danger',
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
          handler: () => {
            console.log('Close clicked');
          },
        },
      ],
      position: 'middle',
      header: 'Sorry, one or more of the products in your cart is out of stock',
    });

    await toast.present();
  }

  async calcDelivery(weight: number) {
    this.deliverToPostnet = false;
    const twoDaysFromToday = new Date(
      new Date().getTime() + 42 * 60 * 60 * 1000
    );
    const day = twoDaysFromToday.getDate();
    const month = twoDaysFromToday.getMonth() + 1;
    const year = twoDaysFromToday.getFullYear();
    const roundedWeight = Math.ceil(weight / 1000);

    if (this.total < 700) {
      const apiUrl = `https://sa.api.fastway.org/v3/psc/eta/JNB/${this.addressInUse?.suburb.trim()}/${
        this.addressInUse?.postalCode
      }/${roundedWeight}?pickupDate=${day}/${month}/${year}&api_key=bb395fb2277217669f480eeefcc57682`;

      return await firstValueFrom(
        this.http.get(`${apiUrl}`).pipe(
          take(1),
          map((r: any) => {
            if (r.result) {
              return {
                services: r.result.services.map((s) => ({
                  label: s.labelcolour,
                  price: Math.ceil(Number(s.totalprice_normal) / 5) * 5,
                  type: s.type,
                })) as any[],
                latest_delivery_date:
                  r?.result?.target_delivery?.latest_delivery_date,
                pickup_date: r?.result?.target_delivery?.pickup_date,
              };
            } else {
              return {
                data: r.data,
                error: r.error,
              };
            }
          })
        )
      )
        .then((response) => {
          console.log({ response });
          this.fastwayPickupDate =
            response?.pickup_date ??
            new Date(
              new Date().getTime() + 48 * 60 * 60 * 1000
            ).toLocaleDateString();

          if (response.services) {
            const services = response.services.sort((a, b) =>
              a.price > b.price ? 1 : -1
            );

            if (this.productsInCart.length === 1) {
              return services[0]?.price;
            } else if (this.productsInCart.length === 2) {
              return services[1]?.price ?? services[0]?.price;
            } else if (this.productsInCart.length <= 5) {
              return (
                services[2]?.price ?? services[1]?.price ?? services[0]?.price
              );
            } else if (this.productsInCart.length <= 11) {
              return (
                services[3]?.price ??
                services[2]?.price ??
                services[1]?.price ??
                services[0]?.price
              );
            } else {
              return services[services.length - 1]?.price;
            }
          } else {
            if (this.productsInCart.length <= 3) {
              return 105;
            } else {
              this.deliverToPostnet = true;
              return 105;
            }
          }
        })
        .catch();
    } else {
      return 0;
    }
  }

  addAddress(addresses) {
    const addressesCopy: Address[] = addresses;
    this.addressInUse = addresses.find((e) => e.inUse);
    addressesCopy.map((ac) => {
      for (const key in ac) {
        if (ac[key] === undefined) {
          delete ac[key];
        }
      }
      return ac;
    });

    this.emitEventWithData('updateUser', { addresses: addressesCopy });
  }

  setCartPile() {
    this.fireBusService
      .updateCart(this.uid, {
        isPile: !this.isPile,
        ...(this.isDelivery ? { deliveryMethod: 'collect' } : {}),
      })
      .catch((err) => console.log({ err }));
  }

  ngOnDestroy() {
    if (this.sub12) {
      this.sub12.unsubscribe();
    }
    if (this.sub4) {
      this.sub4.unsubscribe();
    }
    if (this.sub5) {
      this.sub5.unsubscribe();
    }
  }
}
