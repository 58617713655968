import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CartComponent } from './cart/cart.component';
import { LocationDetailsComponent } from './location-details';

import { ReactiveFormErrorDisplayComponent } from './reactive-form-error-display/reactive-form-error-display.component';
import { ContactDetailsComponent } from './contact-details';
import { PipesModule } from '@radium/pipes';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { PreviewImagesComponent } from './preview-images/preview-images.component';
import { UploadTaskComponent } from './upload-task/upload-task.component';
import { DropzoneDirective } from './dropzone/dropzone.directive';
import { SelectDropdownComponent } from './select-dropdown/select-dropdown.component';
import { SelectFileComponent } from './select-file/select-file.component';
import { TextareaFormComponent } from './textarea-form/textarea-form.component';
import { IncrDecrComponent } from './incr-decr/incr-decr.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { UserBasicInfoComponent } from './user-basic-info';
import { NavButtonsComponent } from './nav-buttons/nav-buttons.component';
import { TagInputModule } from 'ngx-chips';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { RDGenericModalComponent } from './generic-modal';
import { ServiceSelectComponent } from './service-select/service-select.component';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { SchedulerComponent } from './scheduler';
import { TsAndCsComponent } from './ts-and-cs/ts-and-cs.component';
import { ImagesCompleteComponent } from './images-complete';
import { StepperButtonsComponent } from './stepper-buttons/stepper-buttons.component';
import { OrderComponent } from './order';
import { JobPreviewComponent } from './job-preview';
import { FormModalComponent } from './form-modal';
import { FormlyWrapperComponent } from './formly-wrapper';

import {
  DayService,
  MonthService,
  ScheduleModule,
  WeekService,
  WorkWeekService,
} from '@syncfusion/ej2-angular-schedule';
import { AddEditAddressComponent } from './add-edit-address';
import { RouterModule } from '@angular/router';
import { FireDBServiceModel } from '../models';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyFieldInput } from '@ngx-formly/ionic/input';
import { RadiumTagComponent, RadiumTagsInputComponent } from './ion-tags-input';
import { DurationPickerComponent } from './duration-picker';
import { FormlyIonicModule } from '@ngx-formly/ionic';
import { ActionsDropdownComponent } from './actions-dropdown';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { PhoneNumberComponent } from './phone-number';
import { DatePickerComponent } from './date-picker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { SelectAddDropdownComponent } from './select-add-dropdown/select-add-dropdown.component';
import { ServiceAddEditComponent } from './service-add-edit/service-add-edit.component';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { AdhocJobComponent } from './adhoc-job/adhoc-job.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    FormsModule,
    PipesModule,
    MatStepperModule,
    MatButtonModule,
    TagInputModule,
    ListViewModule,
    TooltipModule,
    ScheduleModule,
    RouterModule,
    FormlyModule.forRoot({
      wrappers: [
        { name: 'radium-formly-wrapper', component: FormlyWrapperComponent },
      ],
      validationMessages: [
        { name: 'required', message: 'This field is required' },
      ],
      types: [
        {
          name: 'number',
          component: FormlyFieldInput,
          wrappers: ['form-field'],
          defaultOptions: {
            props: {
              type: 'number',
              step: 1,
            },
          },
        },
        {
          name: 'tags',
          component: RadiumTagsInputComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'duration-picker',
          component: DurationPickerComponent,
          wrappers: ['form-field'],
        },
        // {
        //   name: 'date',
        //   component: DatePickerComponent,
        //   wrappers: ['form-field'],
        // },

        { name: 'datetimepicker', component: DatePickerComponent },
        {
          name: 'phone',
          component: PhoneNumberComponent,
          wrappers: ['form-field'],
        },
        {
          name: 'email',
          extends: 'input',
          defaultOptions: {
            props: {
              type: 'email',
              label: 'Default email Field',
              pattern: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
            },
          },
        },
        {
          name: 'selectAdd',
          component: SelectAddDropdownComponent,
          wrappers: ['form-field'],
          defaultOptions: {
            props: {
              label: 'Select',
              placeholder: 'Select',
              required: true,
              multiple: false,
              options: [],
            },
          },
        },

        // {
        //   name: 'select',
        //   component: FormlyCustomSelectComponent,
        //   wrappers: ['form-field'],
        // },
        // {
        //   name: 'phone',
        //   extends: 'input',
        //   defaultOptions: {
        //     props: {
        //       type: 'tel',
        //       label: 'Phone Number',
        //       placeholder: 'Enter phone number',
        //       pattern: '^[+]?[0-9]{10,13}$',
        //     },
        //   },
        // },
        // TODO: Add a formly field for the date time picker -> https://stackoverflow.com/questions/60504816/angular-8-ngx-formly-datetimepicker

        // the same method can be used to use the international phone number package for the phone number field

        // {
        //   name: 'number',
        //   extends: 'input',
        //   defaultOptions: {
        //     props: {
        //       type: 'number',
        //       label: 'Default number Field',
        //     },
        //   },
        // },
        // {
        //   name: 'datetime',
        //   component: FormlyFieldInput,
        //   wrappers: ['form-field'],
        //   defaultOptions: {
        //     templateOptions: {
        //       type: 'datetime',
        //       minuteValues: '0,15,30,45',
        //       presentation: 'time',
        //     },
        //   },
        // },
      ],
    }),
    FormlyIonicModule,
    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxGpAutocompleteModule,
  ],
  declarations: [
    CartComponent,
    LocationDetailsComponent,
    ReactiveFormErrorDisplayComponent,
    ContactDetailsComponent,
    PreviewImagesComponent,
    UploadTaskComponent,
    DropzoneDirective,
    SelectDropdownComponent,
    SelectAddDropdownComponent,
    SelectFileComponent,
    TextareaFormComponent,
    IncrDecrComponent,
    SplashScreenComponent,
    UserBasicInfoComponent,
    NavButtonsComponent,
    ContactUsComponent,
    RDGenericModalComponent,
    ServiceSelectComponent,
    ServiceAddEditComponent,
    SchedulerComponent,
    TsAndCsComponent,
    ImagesCompleteComponent,
    StepperButtonsComponent,
    AddEditAddressComponent,
    OrderComponent,
    JobPreviewComponent,
    FormModalComponent,
    ActionsDropdownComponent,
    FormlyWrapperComponent,
    RadiumTagsInputComponent,
    RadiumTagComponent,
    PhoneNumberComponent,
    DurationPickerComponent,
    DatePickerComponent,
    AdhocJobComponent,
  ],
  exports: [
    CartComponent,
    LocationDetailsComponent,
    ReactiveFormErrorDisplayComponent,
    ContactDetailsComponent,
    PreviewImagesComponent,
    UploadTaskComponent,
    DropzoneDirective,
    SelectDropdownComponent,
    SelectAddDropdownComponent,
    SelectFileComponent,
    TextareaFormComponent,
    IncrDecrComponent,
    SplashScreenComponent,
    UserBasicInfoComponent,
    NavButtonsComponent,
    ContactUsComponent,
    RDGenericModalComponent,
    ServiceSelectComponent,
    SchedulerComponent,
    TsAndCsComponent,
    ImagesCompleteComponent,
    StepperButtonsComponent,
    AddEditAddressComponent,
    OrderComponent,
    JobPreviewComponent,
    FormModalComponent,
    ActionsDropdownComponent,
    FormlyWrapperComponent,
    RadiumTagsInputComponent,
    PhoneNumberComponent,
    DurationPickerComponent,
    DatePickerComponent,
    ServiceAddEditComponent,
    AdhocJobComponent,
  ],
  providers: [WeekService, DayService, MonthService, WorkWeekService],
})
export class UiModule {
  static forRoot(
    env: any,
    fireDBService?: FireDBServiceModel
  ): ModuleWithProviders<UiModule> {
    return {
      ngModule: UiModule,
      providers: [
        { provide: 'environment', useValue: env },
        { provide: 'FireDBService', useValue: fireDBService },
      ],
    };
  }
}
