import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Auth, authState } from '@angular/fire/auth';
import { ToastController } from '@ionic/angular';
import { FirebaseUserService } from '@radium/core';

@Injectable({
  providedIn: 'root',
})
export class SPGuard {
  constructor(
    private authService: Auth,
    private router: Router,
    public toastCtrl: ToastController,
    public fireUser: FirebaseUserService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    authState(this.authService)
      .pipe(<any>take(1))
      .subscribe((res: any) => {
        if (res === null) {
          this.toast().catch();

          this.router
            .navigate(['home'], { queryParams: { redirectUrl: next.url } })
            .catch();
          return false;
        } else {
          return this.fireUser.isSp().then((isSp) => {
            console.log({ isSp });
            if (!isSp) {
              this.router.navigate(['home']).catch();
              return false;
            } else {
              this.router
                .navigate(['sp-workflow'], {
                  queryParams: { redirectUrl: next.url },
                })
                .catch();

              return true;
            }
          });
        }
      });
    return true;
  }

  async toast() {
    const toast = await this.toastCtrl.create({
      duration: 3000,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
          handler: () => {
            console.log('Close clicked');
          },
        },
      ],
      position: 'top',
      header:
        'You are not allowed to view that page. You are redirected to the Home Page',
    });

    await toast.present();
  }

  canDeactivate() {
    console.log('unsubbed');
    return true;
  }
}
