<ion-toolbar>
  <ion-title>Add Book</ion-title>
</ion-toolbar>

<ion-fab *ngIf="!uploadingImage" horizontal="end" slot="fixed" vertical="top">
  <ion-fab-button (click)="closeComponent()" color="danger" size="small">
    <ion-icon name="close"></ion-icon>
  </ion-fab-button>
</ion-fab>

<ion-content class="ion-padding-horizontal">
  <!--  Preview -->
  <div *ngIf="preview">
    <radium-preview-images
      (imageAction)="actionTheImage($event)"
      (previewAction)="actionThePreview($event)"
      [canAddImage]="true"
      [canDelete]="true"
      [files]="data?.images"
      cameraButtonPosition="160px"
    ></radium-preview-images>
  </div>

  <div
    *ngIf="uploadingImage"
    class="ion-text-center"
    style="margin-top: 50%; border: solid 8px lightgrey; padding: 10px"
  >
    <radium-upload-task
      (imageData)="setImageData($event)"
      [fileInfo]="file"
    ></radium-upload-task>
  </div>

  <div
    (dropped)="onDrop($event)"
    (hovered)="toggleHover($event)"
    *ngIf="data?.isBigDevice && !preview && !uploadingImage"
    [class.hovering]="isHovering"
    class="dropzone"
    radiumDropzone
  >
    <h3>Drop Zone</h3>
    <p>Drag and Drop a Picture</p>

    <radium-select-file
      (dropAction)="onDrop($event)"
      (selectFileAction)="actionThePreview($event)"
      [isBigDevice]="data?.isBigDevice"
    ></radium-select-file>
    <br />
    <radium-select-dropdown
      [dataList]="data?.imageCategories"
      [heading]="data?.categoryName"
    ></radium-select-dropdown>
  </div>

  <div *ngIf="!data?.isBigDevice && !preview && !uploadingImage">
    <radium-select-file
      (dropAction)="onDrop($event)"
      (selectFileAction)="actionThePreview($event)"
      [isBigDevice]="data?.isBigDevice"
    ></radium-select-file>
    <br />

    <radium-select-dropdown
      (selected)="setImageCategory($event)"
      [dataList]="data?.imageCategories"
      [heading]="data?.categoryName"
    ></radium-select-dropdown>
    <br />
    <br />
    <ion-button
      (click)="addImage()"
      [disabled]="!file"
      class="ion-margin-bottom"
      color="success"
      expand="full"
      shape="round"
      size="large"
      >Upload Image
    </ion-button>
  </div>
  <br />

  <!--  <ion-button *ngIf="!scanCode" color="primary" (click)="scanCode = true"-->
  <!--    >Click To Scan <ion-icon slot="end" name="barcode-outline"></ion-icon-->
  <!--  ></ion-button>-->

  <!--  <mat-menu #appMenu="matMenu">-->
  <!--    <mat-selection-list>-->
  <!--      <mat-list-option-->
  <!--        checkboxPosition="start"-->
  <!--        (click)="toggleTryHarder()"-->
  <!--        [selected]="tryHarder"-->
  <!--      >-->
  <!--        Enable Try-harder-->
  <!--      </mat-list-option>-->
  <!--      <mat-list-option-->
  <!--        checkboxPosition="start"-->
  <!--        (click)="toggleTorch()"-->
  <!--        [selected]="torchEnabled"-->
  <!--        *ngIf="torchAvailable$ | async"-->
  <!--      >-->
  <!--        Enable Torch-->
  <!--      </mat-list-option>-->
  <!--    </mat-selection-list>-->
  <!--    <mat-divider></mat-divider>-->
  <!--    <button mat-menu-item (click)="openFormatsDialog()">Barcode formats</button>-->
  <!--    <button mat-menu-item (click)="openInfoDialog()">App info</button>-->
  <!--  </mat-menu>-->

  <!--  <header>-->
  <!--    <mat-form-field appearance="fill">-->
  <!--      <select-->
  <!--        matNativeControl-->
  <!--        matInput-->
  <!--        (change)="onDeviceSelectChange($event.target?.value)"-->
  <!--      >-->
  <!--        <option value="" [selected]="!currentDevice">No Device Selected</option>-->
  <!--        <option-->
  <!--          *ngFor="let device of availableDevices"-->
  <!--          [value]="device.deviceId"-->
  <!--          [selected]="-->
  <!--            currentDevice && device.deviceId === currentDevice.deviceId-->
  <!--          "-->
  <!--        >-->
  <!--          {{ device.label }}-->
  <!--        </option>-->
  <!--      </select>-->
  <!--    </mat-form-field>-->
  <!--    <button mat-icon-button [matMenuTriggerFor]="appMenu">-->
  <!--      <mat-icon>more_vert</mat-icon>-->
  <!--    </button>-->
  <!--  </header>-->

  <!--  <zxing-scanner-->
  <!--    (scanComplete)="onValueChanges($event)"-->
  <!--    (scanSuccess)="scanSuccess($event)"-->
  <!--    (scanError)="scanError($event)"-->
  <!--    (cancel)="scanCode = false"-->
  <!--    [torch]="true"-->
  <!--    (camerasFound)="onCamerasFound($event)"-->
  <!--    (torchCompatible)="onTorchCompatible($event)"-->
  <!--    [(device)]="currentDevice"-->
  <!--  ></zxing-scanner>-->

  <!--  <section class="results">-->
  <!--    <div>-->
  <!--      <small>Result</small>-->
  <!--      <strong>{{ qrResultString }}</strong>-->
  <!--    </div>-->
  <!--    <button mat-icon-button (click)="clearResult()">&times;</button>-->
  <!--  </section>-->

  <form *ngIf="preview && !uploadingImage" [formGroup]="bookForm">
    <div class="autocomplete">
      <ion-label class="ion-margin">Title:</ion-label>
      <ion-searchbar
        (ionBlur)="removeFocus()"
        (ionChange)="search($event)"
        debounce="500"
        formControlName="title"
        placeholder="search book title"
      >
      </ion-searchbar>
      <ion-list *ngIf="results?.length > 0 && !blurred">
        <ion-item
          (click)="selectBook(res.volumeInfo)"
          *ngFor="let res of results"
        >
          {{ res.volumeInfo?.title }}, ({{
            res.volumeInfo?.authors
              ? res.volumeInfo?.authors[0]
              : 'unknown author'
          }})
        </ion-item>
      </ion-list>

      <radium-form-error-display
        [control]="bookForm?.controls['title']"
      ></radium-form-error-display>
    </div>

    <!-- author name -->
    <ion-item>
      <ion-label position="floating">Author Name:</ion-label>
      <ion-input
        [class.invalid]="
          !bookForm.controls['authorName'].valid &&
          (bookForm.controls['authorName'].dirty || submitAttempt)
        "
        formControlName="authorName"
      >
      </ion-input>
    </ion-item>

    <radium-form-error-display
      [control]="bookForm?.controls['authorName']"
    ></radium-form-error-display>

    <!--Price-->
    <ion-item>
      <ion-label>Price: R</ion-label>

      <ion-input
        [class.invalid]="
          !bookForm.controls['price'].valid &&
          (bookForm.controls['price'].dirty || submitAttempt)
        "
        formControlName="price"
        type="number"
      >
      </ion-input>
    </ion-item>

    <radium-form-error-display
      [control]="bookForm?.controls['price']"
    ></radium-form-error-display>

    <!--   quantity -->

    <ion-item>
      <ion-label>Quantity:</ion-label>
      <radium-incr-decr
        (incrDecrEvent)="incrDecr($event)"
        [amount]="bookForm.get('quantity').value"
        [disableDecrement]="bookForm.get('quantity')?.value === 1"
      ></radium-incr-decr>
    </ion-item>

    <!--  weight -->
    <ion-item>
      <ion-label slot="start">Weight:</ion-label>
      <ion-input
        [class.invalid]="
          !bookForm.controls['weight'].valid &&
          (bookForm.controls['weight'].dirty || submitAttempt)
        "
        formControlName="weight"
        style="width: 50px"
        type="number"
      ></ion-input>
      <span style="float: right">grams</span>
    </ion-item>

    <radium-form-error-display
      [control]="bookForm?.controls['weight']"
    ></radium-form-error-display>

    <ion-item lines="none">
      <ion-label position="stacked">Categories:</ion-label>
      <tag-input
        (onTagEdited)="onTagEdited($event)"
        [editable]="true"
        [maxItems]="5"
        formControlName="categories"
        theme="dark"
      >
        <tag-input-dropdown
          [autocompleteItems]="tags$ | async"
          [showDropdownIfEmpty]="true"
        >
        </tag-input-dropdown>
      </tag-input>
    </ion-item>

    <!--    description-->
    <ion-item>
      <ion-label position="floating">Description:</ion-label>
      <ion-textarea
        [class.invalid]="
          !bookForm.controls['description'].valid &&
          (bookForm.controls['description'].dirty || submitAttempt)
        "
        formControlName="description"
        rows="4"
      ></ion-textarea>
    </ion-item>

    <radium-form-error-display
      [control]="bookForm?.controls['description']"
    ></radium-form-error-display>

    <!--    <ion-item lines="none" *ngIf="file?.downloadURL">-->
    <!--      <ion-label>{{ categoryName }}:</ion-label>-->
    <!--      <ion-select-->
    <!--        interface="popover"-->
    <!--        [(ngModel)]="imageCategory"-->
    <!--        [ngModelOptions]="{ standalone: true }"-->
    <!--      >-->
    <!--        <ion-select-option-->
    <!--          *ngFor="let category of categories"-->
    <!--          [value]="category.value"-->
    <!--          >{{ category.value }}</ion-select-option-->
    <!--        >-->
    <!--      </ion-select>-->
    <!--    </ion-item>-->
  </form>

  <ion-item
    *ngIf="data?.images?.length === 0 && submitAttempt"
    class="alert-danger"
    lines="none"
  >
    <ion-label
      class="ion-text-center ion-no-margin ion-margin-top"
      color="danger"
      >* Please add an image first
    </ion-label>
  </ion-item>
  <br />

  <ion-button
    (click)="addBook()"
    *ngIf="preview && !uploadingImage"
    [disabled]="uploadingImage"
    class="ion-margin-bottom"
    color="success"
    expand="full"
    >{{ data?.book ? 'Update' : 'Add' }} Book
  </ion-button>
</ion-content>
