<div
  class="bg"
  *ngIf="show"
  @fadeOut
  [ngStyle]="{
    'background-image': 'url(' + img + ')',
    backgroundColor: backgroundColor,
    backgroundSize: backgroundSize,
    margin: margin
  }"
  [ngClass]="otherClass"
>
  <ng-content></ng-content>
  <ion-spinner
    style="
      top: 66%;
      left: 50%;
      position: fixed;
      transform: translate(-50%, -50%);
      --color: forestgreen;
    "
    name="dots"
  ></ion-spinner>
</div>
<!--<div-->
<!--  *ngIf="img2"-->
<!--  [ngStyle]="{-->
<!--    'background-image': 'url(' + img2 + ')'-->
<!--  }"-->
<!--  class="secondImg"-->
<!--&gt;</div>-->
