import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Image, Like, Product, ProductBasic } from '@radium/ui';
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  collectionChanges,
  CollectionReference,
  doc,
  docData,
  DocumentChange,
  documentId,
  DocumentReference,
  endBefore,
  FieldPath,
  Firestore,
  getDocs,
  increment,
  limit,
  limitToLast,
  orderBy,
  OrderByDirection,
  query,
  setDoc,
  startAfter,
  Timestamp,
  updateDoc,
  where,
} from '@angular/fire/firestore';

@Injectable({ providedIn: 'root' })
export class FirebaseProductService {
  // Source data
  private LOADING = new BehaviorSubject(false);
  // private query: QueryConfig;

  productCollection: CollectionReference<Product>;
  productsBasicColl: CollectionReference<ProductBasic>;
  tagColl: CollectionReference<any>;

  constructor(private afs: Firestore, private http: HttpClient) {
    this.productCollection = collection(
      this.afs,
      'products'
    ) as CollectionReference<Product>;
    this.productsBasicColl = collection(
      this.afs,
      'products_basic'
    ) as CollectionReference<ProductBasic>;
    this.tagColl = collection(this.afs, 'tags') as CollectionReference<any>;
  }

  // PRODUCT METHODS

  getProduct(productDocId: string): Observable<Product> {
    const productDoc = doc(
      this.productCollection,
      productDocId
    ) as DocumentReference<Product>;
    return docData(productDoc, { idField: 'id' }).pipe(
      catchError((x) => of(x))
    );
  }

  getAllProductDocs(
    key: string | FieldPath,
    operator: any,
    value: any,
    order?: OrderByDirection
  ) {
    return collectionChanges<Product>(
      query<Product>(this.productCollection, where(key, operator, value))
    ).pipe(
      map((arr) => {
        const values = arr.map((snap) => {
          const data: Product = snap.doc.data();
          const doc = snap.doc;
          return { ...data, doc };
        });
        return values;
      }),
      catchError((x) => of(x))
    );
  }

  getFilteredProductDocs(
    key: string | FieldPath,
    operator: any,
    value: any,
    approved: boolean
  ) {
    return collectionChanges<Product>(
      query<Product>(
        this.productCollection,
        where(key, operator, value),
        where('approved', '==', approved)
      )
    ).pipe(
      map((arr) => {
        this.LOADING.next(true);
        const values = arr.map((snap) => {
          const data: Product = snap.doc.data();
          const doc = snap.doc;
          return { ...data, doc };
        });
        return values;
      }),
      catchError((x) => of(x))
    );
  }

  createProduct(newData: Product) {
    const data: Product = {
      createdAt: Timestamp.now(),
      viewedByAdmin: false,
      likes: [],
      views: 0,
      isDraft: false,
      approved: true,
      ...newData,
    };
    console.log({ data });

    // return this.productColl.add(data).then(async (productRef) => await productRef.get());
    return addDoc(this.productCollection, data).catch();

    // let document;
    // let documentUuid;

    // if (productId === "0") {
    //   // Generate "locally" a new document in a collection
    //   document = this.productColl.ref.doc();
    //   documentUuid = document.id;
    // } else {
    //   document = this.productBasicColl.doc(productId);
    //   documentUuid = productId;
    // }

    // return document.set(data, { merge: true }).then((j) => {
    //   const basicData: ProductBasic = {
    //     position: address.position,
    //     approved: false,
    //     productId: documentUuid,
    //     authorId,
    //     authorType,
    //     packageName,
    //   };
    //
    //   this.productBasicColl
    //     .doc(documentUuid)
    //     .set(basicData, { merge: true })
    //     .catch();
    //   return j;
    // });
  }

  getProductDocsByCategory(categoryID: number): any {
    return collectionChanges<Product>(
      query<Product>(
        this.productCollection,
        where('productInfo.productCategory', '==', categoryID)
      )
    ).pipe(
      map((arr) => {
        this.LOADING.next(true);
        const values = arr.map((snap) => {
          const data: Product = snap.doc.data();
          const doc = snap.doc;
          return { ...data, doc };
        });
        return values;
      }),
      catchError((x) => of(x))
    );
  }

  getProductsWithQuantitiesLessThanOne(productIds: string[]) {
    const queryId = documentId();

    const q = query(this.productCollection, where(queryId, 'in', productIds));
    return getDocs(q).then(({ docs }) => {
      const docsData = docs.map((doc) => doc.data());
      return docsData.filter((x) => x?.quantity < 1);
    });
  }

  tempCreate(id: string, basicData: ProductBasic) {
    const productBasicRef = doc(
      this.afs,
      'products_basic',
      id
    ) as DocumentReference<ProductBasic>;
    return setDoc(productBasicRef, basicData, { merge: true }).catch();
  }

  // async updateAllProductInfo(uid: string, data: Product) {
  //   console.log(data);
  //
  //   const product: Partial<Product> = {
  //     productId: data.productId,
  //     authorId: data.authorId,
  //     viewed_by_admin: data.viewed_by_admin,
  //     createdAt: data.createdAt,
  //     views: data.views
  //   };
  //
  //   const cleanedObj = this.cleanAnObject(product);
  //
  //   await this.updateProduct(uid, cleanedObj);
  // }

  updateProduct(docId: string, data: Partial<Product>) {
    const productRef = doc(
      this.afs,
      'products',
      docId
    ) as DocumentReference<Product>;
    return updateDoc(productRef, data as any).catch();
  }

  removeProductImage(productId: string, image: Image) {
    this.updateProduct(productId, {
      images: arrayRemove(image) as any,
    }).catch();
  }

  updateProductBasic(docId: string, data: Partial<ProductBasic>) {
    const productBasicRef = doc(
      this.afs,
      'products_basic',
      docId
    ) as DocumentReference<ProductBasic>;
    return updateDoc(productBasicRef, data as any).catch();
  }

  updateProductViews(productId: string) {
    return this.updateProduct(productId, {
      views: increment(1) as any,
    });
  }

  addProductLike(productId: string, uid: string) {
    this.updateProduct(productId, {
      likes: arrayUnion({
        authorId: uid,
        createdAt: Timestamp.now(),
      }) as any,
    }).catch();
  }

  removeProductLike(productId: string, like: Like) {
    this.updateProduct(productId, {
      likes: arrayRemove(like) as any,
    }).catch();
  }

  searchProductsOnGoogle(search: string): Observable<any> {
    // eslint-disable-next-line max-len
    const fields = `volumeInfo/description,volumeInfo/title,volumeInfo/authors,volumeInfo/publisher,volumeInfo/publishedDate,volumeInfo/pageCount,volumeInfo/categories,volumeInfo/averageRating,volumeInfo/maturityRating,volumeInfo/imageLinks,volumeInfo/language,volumeInfo/previewLink`;

    return this.http.get(
      `https://www.googleapis.com/books/v1/volumes?q=${search}&fields=items(${fields})`
    );
  }

  getAllProducts(limiT: number): Observable<Product[]> {
    return collectionChanges<Product>(
      query<Product>(
        this.productCollection,
        where('quantity', '>', 0),
        orderBy('quantity'),
        orderBy('title'),
        limit(limiT)
      )
    ).pipe(
      map((arr) => this.formattedResults(arr)),
      catchError((x) => of(x))
    );
  }

  nextPage(last, limiT: number): Observable<Product[]> {
    const { title, quantity } = last;
    const varNames = Object.keys({ title, quantity });
    console.log(varNames);
    return collectionChanges<Product>(
      query<Product>(
        this.productCollection,
        where('quantity', '>', 0),
        orderBy('quantity'),
        orderBy('title'),
        startAfter(quantity, title),
        limit(limiT)
      )
    ).pipe(
      map((arr) => this.formattedResults(arr)),
      catchError((x) => {
        return of([]);
      })
    );
  }

  prevPage(first: any, limiT: number): Observable<Product[]> {
    const { title, quantity } = first;
    return collectionChanges<Product>(
      query<Product>(
        this.productCollection,
        where('quantity', '>', 0),
        orderBy('quantity'),
        orderBy('title'),
        endBefore(quantity, title),
        limitToLast(limiT)
      )
    ).pipe(
      map((arr) => this.formattedResults(arr)),
      catchError((x) => of(x))
    );
  }

  prevFromLast(limiT: number): Observable<Product[]> {
    return collectionChanges<Product>(
      query<Product>(
        this.productCollection,
        where('quantity', '>', 0),
        orderBy('quantity'),
        orderBy('title'),
        limitToLast(limiT)
      )
    ).pipe(
      map((arr) => this.formattedResults(arr)),
      catchError((x) => of(x))
    );
  }

  formattedResults(arr: DocumentChange<Product>[]): Product[] {
    console.log(arr.map((x) => x.doc.data()));
    return arr?.length > 0
      ? arr?.map((snap) => ({
          ...snap?.doc?.data(),
          id: snap?.doc?.id,
        }))
      : [];
  }

  getTags() {
    const tagDoc = doc(
      this.tagColl,
      'wL7jXIhEZS5dm5qIN4l5'
    ) as DocumentReference<any>;
    return docData(tagDoc, { idField: 'id' }).pipe(
      map((a) => a?.tags),
      catchError((x) => of(x))
    );
  }

  updateTags(tags: string[]) {
    const tagRef = doc(
      this.afs,
      'tags',
      'wL7jXIhEZS5dm5qIN4l5'
    ) as DocumentReference<any>;
    return setDoc(
      tagRef,
      {
        tags: arrayUnion(...tags) as any,
      },
      { merge: true }
    ).catch();
  }
}
