import { Injectable } from '@angular/core';
import { Book, IiraabDoc } from '@radium/ui';
import {
  collection,
  collectionChanges,
  CollectionReference,
  deleteDoc,
  doc,
  docData,
  DocumentReference,
  endBefore,
  FieldPath,
  Firestore,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  startAt,
  Timestamp,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FirebaseIiraabService {
  iiraabColl?: CollectionReference<IiraabDoc>;
  msgColl?: CollectionReference<any>;

  constructor(private afs: Firestore) {
    this.iiraabColl = collection(
      this.afs,
      'iraabsNew'
    ) as CollectionReference<IiraabDoc>;
    this.msgColl = collection(this.afs, 'messages') as CollectionReference<any>;
  }

  getDocs(path: string, field: string | FieldPath, limiT: number): any {
    return collectionChanges(
      query(collection(this.afs, path), limit(limiT), orderBy(field, 'desc'))
    ).pipe(catchError((x) => of(x)));
  }

  getFilteredDocs(path: string, queryText: string): any {
    return collectionChanges(
      query(
        collection(this.afs, path),
        where('title', '>=', queryText),
        where('title', '<=', queryText + '\uf8ff')
      )
    ).pipe(catchError((x) => of(x)));
  }

  getFilteredUserDocs(path: string, queryText: string, uid: any): any {
    return collectionChanges(
      query(
        collection(this.afs, path),
        where('author_id', '==', uid),
        where('title', '>=', queryText),
        where('title', '<=', queryText + '\uf8ff')
      )
    ).pipe(catchError((x) => of(x)));
  }

  getMyDocs(
    path: string,
    field: string | FieldPath,
    limiT: number,
    uid: any
  ): any {
    return collectionChanges(
      query(
        collection(this.afs, path),
        where('author_id', '==', uid),
        limit(limiT)
      )
    ).pipe(catchError((x) => of(x)));
  }

  getNextDocs(
    path: string,
    field: string,
    limiT: number,
    lastInResponse: any
  ): any {
    return collectionChanges(
      query(
        collection(this.afs, path),
        limit(limiT),
        orderBy(field, 'desc'),
        startAfter(lastInResponse)
      )
    ).pipe(catchError((x) => of(x)));
  }

  getPreviousDocs(
    path: string,
    field: string,
    limiT: number,
    firstInResponse: any,
    prev: any
  ): any {
    return collectionChanges(
      query(
        collection(this.afs, path),
        orderBy(field, 'desc'),
        startAt(prev),
        endBefore(firstInResponse),
        limit(limiT)
      )
    ).pipe(catchError((x) => of(x)));
  }

  getMyNextDocs(
    path: string,
    field: string,
    limiT: number,
    lastInResponse: any,
    uid: any
  ): any {
    return collectionChanges(
      query(
        collection(this.afs, path),
        where('author_id', '==', uid),
        limit(limiT),
        startAfter(lastInResponse)
      )
    ).pipe(catchError((x) => of(x)));
  }

  getMyPreviousDocs(
    path: string,
    field: string,
    limiT: number,
    firstInResponse: any,
    prev: any,
    uid: any
  ): any {
    return collectionChanges(
      query(
        collection(this.afs, path),
        where('author_id', '==', uid),
        limit(limiT),
        startAt(prev),
        endBefore(firstInResponse)
      )
    ).pipe(catchError((x) => of(x)));
  }

  async createIiraabDoc(data: Partial<IiraabDoc>) {
    data.createdAt = Timestamp.now();

    const iiraabsRef = doc(
      this.afs,
      'iraabsNew'
    ) as DocumentReference<IiraabDoc>;
    return setDoc(iiraabsRef, data, { merge: true }).then(() => ({
      id: iiraabsRef.id,
    }));
  }

  updateIiraabDoc(id: string, data: Partial<IiraabDoc>) {
    const iiraabsRef = doc(
      this.afs,
      'iraabsNew',
      id
    ) as DocumentReference<Book>;
    return updateDoc(iiraabsRef, data).catch();
  }

  getIiraabDoc(id: string): any {
    // return this.iiraabColl?.doc<IiraabDoc>(id).get();

    const iiraabsRef = doc(this.iiraabColl, id) as DocumentReference<IiraabDoc>;
    return docData(iiraabsRef, { idField: 'id' }).pipe(
      catchError((x) => of(x))
    );
  }

  deleteIiraabDoc(id: string) {
    const iiraabsRef = doc(this.iiraabColl, id) as DocumentReference<IiraabDoc>;
    return deleteDoc(iiraabsRef).catch();
  }

  addMessageDoc(data: { createdAt: Timestamp }) {
    data.createdAt = Timestamp.now();

    const messageRef = doc(this.afs, 'messages') as DocumentReference<any>;
    return setDoc(messageRef, data, { merge: true }).catch();
  }
}
