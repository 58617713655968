import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';

import {
  AlertController,
  LoadingController,
  ModalController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import {
  Auth,
  authState,
  signInAnonymously,
  signOut,
} from '@angular/fire/auth';
import {
  FirebaseBusinessService,
  FirebaseJobService,
  FirebaseUserService,
  MessagingService,
} from '@radium/core';
import { Subscription } from 'rxjs';

import { environment } from '../environments/environment';
import { RDGenericModalComponent, User } from '@radium/ui';
import { take } from 'rxjs/operators';
import { MenuComponent } from '@radium/screens';

@Component({
  selector: 'radium-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
  public appPages = [
    {
      title: 'Home',
      link: '/home',
      icon: 'home',
    },
    {
      title: 'Login',
      link: '/login',
      icon: 'log-in',
      showWhen: 'isAnonymous',
    },
    {
      title: 'My account',
      link: '/account',
      icon: 'person-circle-outline',
    },
    {
      title: 'Contact Us',
      icon: 'mail',
      event: 'contact',
      styles: 'cursor: pointer',
    },
    {
      title: 'Admin',
      link: '/admin',
      icon: 'lock-closed',
      showWhen: 'isAdmin',
    },
    {
      title: 'My Jobs',
      link: '/jobs',
      icon: 'file-tray-full-outline',
      showWhen: 'isLoggedIn',
    },
    {
      title: 'Sign-out',
      icon: 'log-out',
      event: 'logOut',
      showWhen: 'isLoggedIn',
      styles: 'cursor: pointer',
    },
  ];

  @ViewChild('myImg') myImg: ElementRef;
  @ViewChild('menuC') menuC: MenuComponent;

  isAnonymous = true;
  userDetails: any;
  displayName = 'Anonymous User';
  uid?: string;
  isAdmin: boolean;
  isSP: boolean;

  email?: string;
  sub: Subscription;
  sub3: Subscription;
  canShowNow: boolean;
  version;
  anonUser: any;
  authSubscriber: Subscription;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private afAuth: Auth,
    private msgService: MessagingService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private fireJobService: FirebaseJobService,
    private router: Router,
    private lCtrl: LoadingController,
    private toastCtrl: ToastController,
    public fireUser: FirebaseUserService,
    private fireBusService: FirebaseBusinessService
  ) {
    this.initializeApp();
    // this.version = environment.appVersion;
    // console.log('version: ', environment.appVersion);

    const v = !environment.production ? '1.0.1' : '1.0.1';
    console.log('version: ', v);
    this.version = `${v}${environment.production ? '' : ' - dev'}`;
  }

  setVisible(): void {
    this.myImg['el'].style.setProperty('visibility', 'inherit');
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    if (this.platform.is('mobile')) {
      console.log('I am a mobile device!');
    }

    if (!environment.production) {
      this.done(true);
    }

    this.sub = authState(this.afAuth).subscribe({
      next: async (res) => {
        console.log({ res });
        console.log('anon: ', this.anonUser);

        if (res !== null) {
          this.uid = res.uid;
          this.userDetails = res;
          this.displayName = res.displayName ?? 'Anonymous User';
          this.email = res.email;
          this.isAnonymous = res?.isAnonymous;
          this.isAdmin = await this.fireUser.isAdmin().catch();
          this.isSP = await this.fireUser.isSp().catch();
          if (this.isSP) {
            this.messaging(res?.uid);
          }
          console.log('isAdmin: ', this.isAdmin);
        } else {
          const anonUser = await signInAnonymously(this.afAuth).catch();
          await this.fireUser.createAnonUserDoc(anonUser?.user?.uid).catch();
        }
      },
      error: (err) => console.log({ err }),
    });

    this.sub3 = this.msgService.currentMessage.subscribe((msg) => {
      this.toast(msg).catch();
    });
  }

  go(event: string) {
    console.log(event);
    if (event === 'logOut') {
      this.logout().catch();
    } else {
      this.openModalPage(event).catch();
    }
  }

  async openModalPage(type: string) {
    if (type === 'contact') {
      const modal = await this.modalCtrl.create({
        component: RDGenericModalComponent,
        backdropDismiss: true,
        cssClass: 'wideModal',
        componentProps: {
          data: {
            displayName: this.displayName,
            uid: this.uid,
            email: this.email,
            type,
            clientName: environment.clientName,
          },
        },
      });
      await modal.present();
      modal.onDidDismiss().then((val: any) => {
        console.log('onDidDismiss: ', val);
        if (val?.data?.data) {
          if (
            environment.clientName
              ?.toLowerCase()
              ?.includes('Hunger Has No Religion')
          ) {
            this.fireJobService.createAddhocJob(
              this.userDetails.uid,
              'user',
              val.data.data,
              this.userDetails?.displayName,
              this.userDetails?.contactNumber,
              this.userDetails?.email
            );
          }

          this.fireBusService.addQuery(val.data.data);
        }
      });
    } else if (type === 'upload' && !this.uid) {
      this.goLoginPage('upload videos');
    }
  }

  async logout() {
    const alert = await this.alertCtrl.create({
      header: 'Are you sure you want to sign out?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
        },
        {
          text: 'Yes',
          handler: () => {
            this.uid = null;
            signOut(this.afAuth).catch();
            setTimeout(() => this.router.navigate(['home']), 100);
          },
        },
      ],
    });

    await alert.present();
  }

  goLoginPage(frag?: string) {
    this.router.navigate(['/login'], { fragment: frag }).catch();
  }

  async presentLoading() {
    const loading = await this.lCtrl.create({
      message: 'logging in ...',
    });
    return loading.present();
  }

  done(val: any) {
    this.canShowNow = val === true;
    this.go('choosePath');
  }

  private messaging(uid: string) {
    this.fireUser
      .getUserDoc(uid)
      .pipe(take(1))
      .subscribe((user) => {
        this.msgService.getPermission(user);
        // this.msgService.monitorRefresh(user);
        this.msgService.receiveMessages();
      });
  }

  async toast(msg) {
    const toast = await this.toastCtrl.create({
      duration: 20000,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
          handler: () => {
            console.log('Close clicked');
          },
        },
      ],
      position: 'top',
      header: msg.title,
      message: msg.body,
      animated: true,
      color: 'success',
    });

    await toast.present();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.sub3?.unsubscribe();
    this.authSubscriber.unsubscribe();
  }
}
