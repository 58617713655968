<form [formGroup]="$any(textareaForm)" (ngSubmit)="submit()">
  <ion-item>
    <ion-label position="floating"
      >{{ textareaLabel }}
      <ion-text color="danger">*</ion-text>
    </ion-label>
    <ion-textarea
      required
      formControlName="textarea"
      type="text"
    ></ion-textarea>
  </ion-item>
  <ion-button
    expand="block"
    color="primary"
    type="submit"
    [disabled]="textareaForm?.invalid"
    [color]="submitButtonColor"
  >
    {{ submitButtonText }}
  </ion-button>
</form>
