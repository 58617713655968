<div *ngIf="paymentSucceeded; else fail">
  <h2 class="ion-margin ion-padding ion-text-center">
    Thank you for your order.
  </h2>

  <!--      <ion-button-->
  <!--        class="ion-margin"-->
  <!--        expand="block"-->
  <!--        color="success"-->
  <!--        [routerLink]="[]"-->
  <!--        (click)="fromPayfast = false"-->
  <!--      >-->
  <!--        Close-->
  <!--      </ion-button>-->

  <div *ngIf="payfastComplete">
    <p class="ion-margin ion-padding">
      Your order has been successfully processed. <br />
      Your order number is {{ paymentId }}. We have emailed your order
      confirmation, and will send you an update when your order is ready for
      collection or shipped.

      <!--    <ion-button expand="block" (click)="goToAccount()">-->
      <!--      View order histories-->
      <!--   </ion-button>-->

      <br />
      For enquiries please contact our customer support team.
      <br />
    </p>

    <ion-button
      class="ion-margin"
      expand="block"
      color="success"
      [routerLink]="[]"
      (click)="eventFromPayfastComp.emit({ type: 'fromPayfast' })"
    >
      Close
    </ion-button>
  </div>

  <div *ngIf="!payfastComplete">
    <p class="ion-margin ion-padding ion-text-center">
      Oops an error occurred! Please contact our customer support team.
    </p>
    <ion-button
      class="ion-margin"
      expand="block"
      color="warning"
      [routerLink]="[]"
      (click)="eventFromPayfastComp.emit({ type: 'fromPayfast' })"
    >
      Close
    </ion-button>
  </div>
</div>
<ng-template #fail>
  <p class="ion-margin ion-padding ion-text-center">
    You have cancelled the payment process
  </p>
  <ion-button
    class="ion-margin"
    expand="block"
    color="warning"
    [routerLink]="[]"
    (click)="eventFromPayfastComp.emit({ type: 'fromPayfast' })"
  >
    Close
  </ion-button>
  <ion-button
    color="primary"
    class="ion-margin"
    expand="block"
    (click)="eventFromPayfastComp.emit({ type: 'goToCheckOut' })"
  >
    Return to Checkout Page
  </ion-button>
</ng-template>
