<div *ngIf="show" [ngStyle]="margin ? margin : null">
  <h2
    *ngIf="heading"
    class="ion-text-center"
    style="margin-top: 20px; margin-bottom: 0"
  >
    {{ heading }}
  </h2>

  <ion-spinner
    *ngIf="loading$ | async"
    color="primary"
    name="lines"
    class="spinner-center"
    style="margin: auto; left: 45%"
  ></ion-spinner>

  <div
    *ngIf="(loading$ | async) === false"
    style="border: solid 1px lightslategrey"
  >
    <ion-grid fixed class="ion-padding-bottom">
      <div [formGroup]="addressesGroup">
        <div formArrayName="addresses">
          <div *ngIf="addressesFormArray.controls.length === 0">
            <ion-item lines="none">
              <ion-label class="ion-text-center">
                No addresses added as yet
              </ion-label>
            </ion-item>
          </div>

          <ion-list
            lines="none"
            *ngFor="let address of addressesFormArray?.controls; index as i"
          >
            <ion-item
              class="ion-no-margin"
              [class.highlight]="address.get('inUse')?.value"
              (click)="setInUse($event, i)"
            >
              <ion-label>
                {{ address.get('streetAddress')?.value }}
              </ion-label>

              <ion-buttons slot="end">
                <ion-button
                  color="danger"
                  (click)="removeAddress(i, true)"
                  slot="end"
                >
                  <ion-icon name="trash"></ion-icon>
                </ion-button>
                <ion-button
                  color="secondary"
                  (click)="addEditAddress(address, false)"
                  slot="end"
                >
                  <ion-icon name="pencil"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-item>
          </ion-list>

          <ion-button
            class="ion-margin-horizontal"
            color="primary"
            (click)="addEditAddress(null, true)"
            expand="block"
          >
            add
            <span *ngIf="addressesFormArray.length > 0"
              >&nbsp;another&nbsp;</span
            >
            address
          </ion-button>
        </div>
      </div>
    </ion-grid>
  </div>
</div>
