import { Injectable } from '@angular/core';
import { Route, Router, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WelcomeGuard {
  constructor(private router: Router) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | Observable<boolean> | Promise<boolean> {
    console.log(window.history?.state?.navigationId);
    if (window.history?.state?.navigationId > 1) {
      return true;
    } else {
      this.router.navigate(['/welcome']).catch();
      return false;
    }
  }
}
