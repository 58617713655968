import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Auth, authState } from '@angular/fire/auth';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AnonActivateGuard {
  constructor(
    private authService: Auth,
    private router: Router,
    public toastCtrl: ToastController
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    authState(this.authService)
      .pipe(<any>take(1))
      .subscribe((res: any) => {
        if (!res || res.isAnonymous) {
          this.router
            .navigate(['login'], {
              queryParams: {
                redirectUrl: next?.routeConfig.path,
                ...next.queryParams,
              },
              replaceUrl: true,
            })
            .catch();
          return false;
        } else {
          return true;
        }
      });
    return true;
  }
}
