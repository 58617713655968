import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Observable, of, Subscription } from 'rxjs';
import { LoadingService } from '@radium/core';
import { Service } from '@radium/ui';
import {
  Fields,
  FieldSettingsModel,
  ListView,
} from '@syncfusion/ej2-angular-lists';
import { Tooltip } from '@syncfusion/ej2-angular-popups';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'radium-service-select',
  templateUrl: './service-select.component.html',
  styleUrls: ['./service-select.component.scss'],
})
export class ServiceSelectComponent implements OnInit, OnDestroy {
  @ViewChild('listView') listView?: ListView;
  @ViewChild('tooltip') tooltip?: Tooltip;

  @Input() servicesForm?: FormArray;
  @Input() margin: string;
  @Input() services$?: Observable<Service[]>;

  @Output() setImage = new EventEmitter();
  @Output() setTotal = new EventEmitter();

  serviceHasBeenSelected = false;
  catGroups: { [key: string]: Service[] } = {};

  fields: FieldSettingsModel = {
    tooltip: 'name',
  };
  headerTitle = 'Services';
  loungeTitle = false;
  sub?: Subscription;
  total?: number;
  tooltipsArr: Tooltip[] = [];

  constructor(
    private modalCTRL: ModalController,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
    this.loadingService
      .loadingPresent('Getting services ...', 'services')
      .then(() => {
        this.services$ = this.services$?.pipe(
          map((services: Service[]) => {
            console.log({ services: services });
            const cats = new Set(services.map((s) => s['category'] ?? 'Root'));

            cats.forEach((cat) => (this.catGroups[cat] = []));

            services.forEach((s) =>
              s.category
                ? this.catGroups[s.category]?.push(s)
                : this.catGroups['Root']?.push(s)
            );

            Object.entries(this.catGroups).map(
              ([key, val]) =>
                (this.catGroups[key] = val.sort((a: any, b: any) =>
                  a?.position < b?.position
                    ? -1
                    : a?.position > b?.position
                    ? 1
                    : 0
                ))
            );
            setTimeout(
              () => this.loadingService.loadingDismiss('services').catch(),
              500
            );

            return this.catGroups['Root'];
          }),
          catchError((x) => {
            this.loadingService.loadingDismiss('services').catch();
            return of(x);
          })
        );
      })
      .catch();

    this.sub = this.servicesForm?.valueChanges.subscribe((vals: any[]) => {
      this.total = vals.reduce(
        (pre: any, curr: any) => pre + curr.price * curr.quantity,
        0
      );

      this.setTotal.emit(this.total);
    });
  }

  select(e: any) {
    // if already in form, do nothing
    const i = this.servicesForm?.value.findIndex(
      (v: any) => v.text === e.data?.text
    );
    if (i > -1) return;

    if (this.catGroups[e.data?.text]) {
      e.data.child = this.catGroups[e.data.text];
    }

    if (e.event.defaultPrevented) {
      delete e.data;
      e.isInteracted = false;
      e.item.classList.remove('e-active');
      e.item.classList.remove('e-has-child');
      return;
    } else if (e.data.child) {
      e.item.classList.add('e-has-child');
    }
    this.loungeTitle = e.data.category?.includes('Lounge');

    this.headerTitle = 'Services';

    setTimeout(() => {
      if (!e.data.child && !e.data.text?.includes('COMING SOON')) {
        // this.openModal({ selectedJob: e.data }).catch();
        this.serviceHasBeenSelected = true;

        this.servicesForm?.push(
          new FormGroup({
            text: new FormControl(e.data.text),
            id: new FormControl(e.data.id),
            price: new FormControl(e.data.price),
            category: new FormControl(e.data.category),
            quantity: new FormControl(e.data.quantity),
            quantityCanEdit: new FormControl(e.data.quantityCanEdit),
          })
        );

        this.listView?.hideItem(e);
        this.listView?.refresh();
        this.setImage.emit([e.data.text]);
      }
    }, 20);
  }

  actionComplete(event: any) {
    this.serviceHasBeenSelected = false;
    // this.selectedService.emit({ selectedService: undefined });
  }

  incrDecr(e: string, index: any) {
    if (e === 'decrement') {
      this.servicesForm?.controls[index].patchValue({
        quantity:
          this.servicesForm?.controls?.[index]?.get('quantity')?.value - 1,
      });
    } else if (e === 'increment') {
      this.servicesForm?.controls[index].patchValue({
        quantity:
          this.servicesForm?.controls[index]?.get('quantity')?.value + 1,
      });
    }
  }

  removeService(index: number, item: HTMLElement | Element | Fields) {
    this.servicesForm?.removeAt(index);
    this.listView?.showItem(item);
    if (this.servicesForm?.length === 0) this.serviceHasBeenSelected = false;
  }

  showInfo(e: any, details: any) {
    e.preventDefault();
  }

  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }
}
