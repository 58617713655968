import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from 'ngx-auth-firebaseui';
import {
  AdminGuard,
  AnonActivateGuard,
  AnonGuard,
  SPCanLoadGuard,
  SPGuard,
} from '@radium/core';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./pages/about/about.module').then((m) => m.AboutPageModule),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./pages/contact/contact.module').then((m) => m.ContactPageModule),
  },
  {
    path: 'donate',
    loadChildren: () =>
      import('./pages/donate/donate.module').then((m) => m.DonatePageModule),
  },
  {
    path: 'login',
    canActivate: [LoggedInGuard],
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },

  {
    path: 'account',
    canLoad: [AnonGuard],
    loadChildren: () =>
      import('./pages/account/account.module').then((m) => m.AccountPageModule),
  },

  {
    path: 'verify-auth',
    loadChildren: () =>
      import('./pages/verify-auth/verify-auth.module').then(
        (m) => m.VerifyAuthPageModule
      ),
  },

  {
    path: 'about/:id',
    canLoad: [AnonGuard],
    loadChildren: () =>
      import('./pages/about/about.module').then((m) => m.AboutPageModule),
  },

  {
    path: 'error',
    loadChildren: () =>
      import('./pages/error/error.module').then((m) => m.ErrorPageModule),
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
