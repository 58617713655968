<ejs-listview
  id="servicesListView"
  #listView
  [dataSource]="_dataManager | async"
  [fields]="fields"
  [showHeader]="true"
  [headerTitle]="headerTitle"
  (select)="select($event)"
  (actionComplete)="actionComplete($event)"
  cssClass="customListView"
  [ngStyle]="{ margin: margin }"
>
  <ng-template #template let-data>
    <div
      class="settings"
      *ngIf="data?.isHeader; then innerHeader; else itemTemplate"
    ></div>

    <ng-template #innerHeader>
      <div class="innerHeader" style="text-align: center">{{ data.text }}</div>
    </ng-template>

    <ng-template #itemTemplate>
      <span>{{ data.text }}</span>

      <ejs-tooltip
        #tooltip
        *ngIf="data.details"
        id="showTooltip"
        opensOn="Click"
        [content]="data.details"
        position="BottomCenter"
      >
        <ion-button
          style="margin-top: 0; margin-bottom: 10px"
          size="small"
          fill="clear"
          (click)="showInfo($event, data.details)"
        >
          <ion-icon
            color="success"
            name="information-circle-outline"
          ></ion-icon>
        </ion-button>
      </ejs-tooltip>

      <ion-button
        style="display: inline; float: right; color: darkblue; font-weight: 500"
        size="small"
        fill="clear"
        (click)="
          $event.preventDefault();
          $event.stopPropagation();
          serviceRemoved.emit(data)
        "
        slot="end"
      >
        <ion-icon color="danger" name="trash"></ion-icon>
      </ion-button>
    </ng-template>
  </ng-template>
</ejs-listview>
