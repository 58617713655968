<ion-row
  class="ion-no-padding ion-no-margin"
  [ngClass]="
    isBigDevice ? '' : isOdd ? 'smallDeviceHackOdd' : 'smallDeviceHack'
  "
>
  <ion-col
    size="6"
    class="ion-no-padding ion-no-margin"
    *ngIf="(first || last) && hasItems"
  >
    <ion-button
      expand="block"
      class="ion-no-margin ion-margin-horizontal"
      [ngClass]="first ? 'ion-margin-bottom' : 'ion-margin-top'"
      (click)="doAction('previous')"
      [disabled]="disableBack"
    >
      <ion-icon slot="start" name="caret-back-outline"></ion-icon>
      <span>Previous</span>
    </ion-button>
  </ion-col>
  <ion-col
    size="6"
    class="ion-no-padding ion-no-margin"
    *ngIf="(first || last) && hasItems"
  >
    <ion-button
      expand="block"
      class="ion-margin ion-margin-horizontal"
      [ngClass]="first ? 'ion-margin-bottom' : 'ion-margin-top'"
      [disabled]="length < limit"
      (click)="doAction('next')"
    >
      <ion-icon slot="end" name="caret-forward-outline"></ion-icon>

      <span>&nbsp;&nbsp;&nbsp;&nbsp;Next</span>
    </ion-button>
  </ion-col>

  <ion-col
    *ngIf="!hasItems && last"
    [ngStyle]="{ left: !isBigDevice && !isOdd ? '25%' : 'inherit' }"
  >
    <p style="text-align: center; margin: 30px">
      No more results found!

      <ion-button
        expand="block"
        class="ion-margin"
        (click)="doAction('previousFromLast')"
      >
        <ion-icon slot="start" name="caret-back-outline"></ion-icon>

        Previous
      </ion-button>
    </p>
  </ion-col>
</ion-row>
