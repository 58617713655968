import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImageProcessingService } from '@radium/core';

@Component({
  selector: 'radium-select-file',
  templateUrl: './select-file.component.html',
  styleUrls: ['./select-file.component.scss'],
})
export class SelectFileComponent {
  @Input() isBigDevice?: boolean;
  @Output() selectFileAction = new EventEmitter();
  @Output() dropAction = new EventEmitter();
  @Input() url?: string;
  @Input() showCancelButton = true;

  constructor(
    private sanitizer: DomSanitizer,
    private ips: ImageProcessingService
  ) {}

  actionTheSelectFile(action: string) {
    this.url = undefined as any;
    this.selectFileAction.emit({ action });
  }

  async onDrop(eventTarget: HTMLInputElement) {
    const files: any = eventTarget.files;
    console.log('files', files);
    const file = await this.ips.resizeImage(files?.[0]).catch();
    this.url = URL.createObjectURL(file);
    this.dropAction.emit(file);
  }

  get previewUrl(): SafeUrl {
    return this.sanitizer?.bypassSecurityTrustUrl(this?.url);
  }
}
