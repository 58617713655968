import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { deleteObject, ref, Storage } from '@angular/fire/storage';
import { generateUUID } from '../../../../../utils';
import { ImagesData } from '../../models';

@Component({
  selector: 'radium-images-complete',
  templateUrl: './images-complete.component.html',
  styleUrls: ['./images-complete.component.scss'],
})
export class ImagesCompleteComponent implements OnInit, OnDestroy {
  isHovering?: boolean;
  file?: {
    name: string;
    size: number;
    type: string;
    description?: string;
    title?: string;
  };

  submitAttempt = false;
  imageForm?: FormGroup;
  @Output() closeComponentEmit = new EventEmitter();
  @Input() preview = true;
  @Input() includeCategories = true;
  @Input() includeImageForm = true;
  @Input() singleImage = false;

  @Input() data?: ImagesData;
  selectedImageCategory = '';
  uploading?: boolean;
  url?: string;

  constructor(
    public modalCtrl: ModalController,
    public formBuilder: FormBuilder,
    private storage: Storage
  ) {}

  ngOnInit() {
    this.imageForm = this.formBuilder.group({
      title: [''],
      description: [''],
      categories: [[]],
    });

    if (this.data?.imageFormFieldsIsRequired) {
      this.imageForm?.get('title')?.setValidators(Validators.required);
      this.imageForm?.get('description')?.setValidators(Validators.required);
    }
  }

  setImageData(val: { downloadURL: string; path: string }) {
    if ((val as unknown) === 'error' || (val as unknown) === 'cancel') {
      this.uploading = false;
      return;
    }

    const newFile: any = {
      downloadURL: val.downloadURL,
      approved: true,
      id: generateUUID(),
      imageCategory: this.selectedImageCategory?.trim(),
      title: this.imageForm?.get('title')?.value.trim() ?? '',
      description: this.imageForm?.get('description')?.value ?? '',
    };

    console.log({ newFile, old: this.file });

    this.file = {
      ...this.file,
      ...newFile,
    };

    setTimeout(() => {
      this.preview = true;
      this.uploading = false;
      this.file = undefined;
      if (this.singleImage) {
        this.data.images = [newFile];
        this.submitAttempt = false;
        this.closeComponentEmit.emit(this.data?.images);
      } else this.data?.images.push(newFile);
      this.file = undefined;
    }, 500);
  }

  addImage() {
    this.submitAttempt = true;
    if (this.file) this.uploading = true;
  }

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onImageSelected(file: File, url?: any) {
    if (url) this.url = url;
    this.file = file;
  }

  @HostListener('window:popstate', ['$event'])
  closeModal(data?: any, toBase64?: any) {
    if (toBase64) {
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(data);
      return new Promise(
        (resolve) =>
          (reader.onload = () =>
            resolve(this.modalCtrl.dismiss(reader?.result?.toString())))
      );
    } else return this.modalCtrl.dismiss(data);
  }

  actionTheImage(e: any) {
    console.log('clicked: ', e);
    console.log(this.data?.images);

    if (e.action === 'delete') {
      const path: any = this.data?.images?.[e.index]?.downloadURL;
      // this.storage.refFromURL(path).delete();
      deleteObject(ref(this.storage, path)).catch();
      this.data?.images.splice(e.index, 1);
    }
  }

  closeComponent() {
    if (!this.preview) {
      this.preview = !this.preview;
      this.file = undefined;
      this.submitAttempt = false;
      this.data?.componentType === 'modal' && this.closeModal()?.catch();
    } else {
      const val: any = {
        images: this.data?.images,
      };

      if (this.data?.componentType === 'modal') this.closeModal(val)?.catch();
      else this.closeComponentEmit.emit(val);
    }
  }

  actionThePreview(event: any) {
    console.log({ event });
    if (event.action === 'disablePreview') this.preview = false;
    if (event.action === 'enablePreview') this.preview = true;
    if (event.action === 'cancelAddingPicture') {
      this.file = undefined as any;
      this.submitAttempt = false;
    }
  }

  ngOnDestroy(): void {
    if (window.history.state.modal) history.back();
  }
}
