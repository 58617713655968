import { Inject, Injectable } from '@angular/core';
import { User } from '@radium/ui';
import { Subject } from 'rxjs';
import { FirebaseUserService } from './firebase-user.service';
import { getToken, Messaging, onMessage } from '@angular/fire/messaging';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  messageSource = new Subject();
  currentMessage = this.messageSource.asObservable();

  constructor(
    private afMessaging: Messaging,
    public fireUser: FirebaseUserService,
    @Inject('environment') private env: any
  ) {}

  getPermission(user: any) {
    getToken(this.afMessaging, { vapidKey: this.env?.vapidKey }).then(
      (token) => {
        console.log({ token });
        this.saveToken(user, token);
      },
      (err) => console.log({ err })
    );
  }

  // monitorRefresh(user: any) {
  //   // TODO fix ontokenrefresh does not exist on afmessaging
  //   this.afMessaging;
  //   onTokenRefresh(() => {
  //     this.getPermission(user))
  //   }).catch();
  // }

  receiveMessages() {
    onMessage(this.afMessaging, (msg) => {
      console.log({ msg });
      this.messageSource.next(msg?.notification);
    });
  }

  saveToken(user: User, token: string) {
    const currentTokens = user.fcmTokens ?? {};

    if (token && !currentTokens[token]) {
      const tokens = { ...currentTokens, [token]: true };
      this.fireUser.updateUserDoc(user.uid, { fcmTokens: tokens }).catch();
    }
  }
}
