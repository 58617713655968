import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { Address } from '../../models/address';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'radium-add-edit-address',
  templateUrl: './add-edit-address.component.html',
  styleUrls: ['./add-edit-address.component.scss'],
})
export class AddEditAddressComponent implements OnInit {
  @Input() address?: AbstractControl;
  @Input() isNew = true;
  addingAddress?: boolean;

  constructor(private fb: FormBuilder, private modalCtrl: ModalController) {}

  ngOnInit() {}

  @HostListener('window:popstate', ['$event'])
  closeModal(add?: any) {
    if (add && this.address.valid) {
      return this.modalCtrl.dismiss(this.address.value);
    } else {
      return this.modalCtrl.dismiss();
    }
  }

  getFormattedAddress(place: google.maps.places.PlaceResult) {
    console.log(place?.address_components);

    const address: Partial<Address> = {};

    for (const item of place?.address_components as any) {
      this.createLocation(item, address);
    }
    address.latitude = place?.geometry?.location?.lat();
    address.longitude = place?.geometry?.location?.lng();
    address.locationId = place?.id;
    // address.addressId = Math.random()
    //   .toString(36)
    //   .substr(2, 9);
    address.requiresValidation = false;

    this.setAddress(address);
  }

  private createLocation(
    item: google.maps.GeocoderAddressComponent,
    locationObj: Partial<Address>
  ) {
    if (item) {
      if (item?.types?.includes('street_number')) {
        locationObj.streetNumber = item.short_name;
      } else if (item?.types?.includes('route')) {
        locationObj.streetAddress = item?.long_name;
      } else if (
        item?.types?.includes('sublocality_level_2') ||
        item?.types?.includes('sublocality_level_1') ||
        item?.types?.includes('sublocality')
      ) {
        locationObj.suburb = item?.long_name;
      } else if (item.types.includes('locality')) {
        locationObj.city = item?.long_name;
      } else if (item.types.includes('administrative_area_level_1')) {
        locationObj.province = item?.long_name;
      } else if (item?.types?.includes('country')) {
        locationObj.country = item?.long_name;
        locationObj.countryCode = item?.short_name;
      } else if (item?.types?.includes('postal_code')) {
        locationObj.postalCode = item?.long_name;
      }
    }
  }

  setAddress(address: Partial<Address>) {
    console.log({ address });
    if (address?.city) {
      // this.position = this.citiesProvider.getGeoData(
      //   address?.latitude,
      //   address?.longitude
      // );

      const n = address.streetNumber ? address.streetNumber : '';

      this.address.patchValue({
        streetAddress: n + ' ' + address?.streetAddress,
        complex: address?.complex ?? '',
        suburb: address?.suburb ?? '',
        city: address?.city ?? '',
        province: address?.province ?? '',
        postalCode: address?.postalCode ?? '',
        // position: this.position ?? '',
        country: address?.country ?? '',
        countryCode: address?.countryCode ?? '',
        latitude: address?.latitude ?? '',
        locationId: address?.locationId ?? '',
        longitude: address?.longitude ?? '',
        requiresValidation: address?.requiresValidation ?? '',
        inUse: address?.inUse ?? true,
      });
    }
  }
}
