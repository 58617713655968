<ion-menu
  side="end"
  [ngClass]="isBigDevice ? 'smallMenu' : 'fullMenu'"
  contentId="home-content"
  menuId="cart-menu"
>
  <ion-header>
    <ion-toolbar>
      <ion-menu-button menu="cart-menu" slot="end" autoHide="false">
        <ion-icon color="danger" name="close"></ion-icon>
      </ion-menu-button>
      <ion-title>
        <ion-label class="ion-padding-start">Cart</ion-label>
      </ion-title>
    </ion-toolbar>
  </ion-header>

  <!--  [scrollX]="true"-->
  <!--  [scrollY]="true"-->
  <!--  fullscreen="true"-->
  <!--  forceOverscroll="true"-->

  <ion-content id="cart-content">
    <ion-list class="ion-no-padding ion-margin">
      <ion-row
        *ngIf="productsInCart?.length > 0; else empty"
        [ngStyle]="{ backgroundColor: 'silver' }"
      >
        <ion-col class="ion-padding-start" size="6"> TITLE</ion-col>
        <ion-col class="ion-padding-start">QUANTITY</ion-col>
        <ion-col class="ion-padding-start">PRICE</ion-col>
      </ion-row>

      <!--        [ngStyle]="{ backgroundColor: '#222428' }"-->
      <ion-row *ngFor="let p of productsInCart">
        <ion-col size=".3" class="ion-padding-end" style="align-self: center">
          <ion-button
            class="ion-no-padding ion-no-margin ion-margin-top"
            color="clear"
            (click)="deleteItemFromCart(p.id)"
            size="small"
            shape="round"
          >
            <ion-icon
              size="small"
              color="danger"
              name="trash-outline"
            ></ion-icon>
          </ion-button>
        </ion-col>
        <ion-col class="ion-padding-start ion-padding-top" size="5.5">
          <ion-button
            size="small"
            color="clear"
            style="
              --box-shadow: none;
              color: black;
              font-size: 13px;
              padding-top: 10px;
              max-width: 110%;
              text-align: left;
            "
            (click)="viewItem(p.id)"
          >
            {{
              p.title.length > 15
                ? (p.title | slice : 0 : 15) + ' ...'
                : p.title
            }}
          </ion-button>

          <!--          <small>{{ p.description }}</small>-->
        </ion-col>
        <ion-col
          class="ion-padding-start ion-padding-top ion-text-center"
          style="align-self: center"
        >
          <radium-incr-decr
            [size]="'small'"
            [hasPadding]="false"
            (incrDecrEvent)="incrDecr($event, p.id)"
            [amount]="p.quantity"
            [disableDecrement]="p.quantity === 1"
            [disableIncrement]="p.quantityLimit === p.quantity"
          ></radium-incr-decr>
        </ion-col>
        <ion-col
          class="ion-padding-start ion-padding-top"
          style="align-self: center"
        >
          R{{ p.price }}
        </ion-col>
      </ion-row>

      <ng-template #empty>
        <ion-item class="ion-text-center"> Your cart is empty!</ion-item>
      </ng-template>
    </ion-list>
    <ion-item>
      <ion-label slot="end">Subtotal: R {{ total }}</ion-label>
    </ion-item>
    <ion-button
      [disabled]="
        !productsInCart || (productsInCart && productsInCart.length === 0)
      "
      class="ion-margin"
      expand="full"
      (click)="emitCartEvent.emit({ type: 'goToCheckOut' })"
    >
      Checkout
    </ion-button>
  </ion-content>
</ion-menu>
