import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { AutoCompleteService } from "ionic2-auto-complete";
import { map, take, tap } from 'rxjs/operators';
// import { Geolocation } from "@ionic-native/geolocation/ngx";
// import * as geofirex from "geofirex";
import { Plugins } from '@capacitor/core';

/*import RapidAPI from "rapidapi-connect";
import unirest from "unirest";*/
// import { Geolocation } from "@ionic-native/geolocation";

/*const rapid = new RapidAPI(
  "m4m_5b3bac6de4b0d23f1ee78370",
  "/connect/auth/m4m_5b3bac6de4b0d23f1ee78370"
);*/

@Injectable({
  providedIn: 'root',
})
export class CitiesService {
  labelAttribute = 'description';
  countryCode?: string;
  // usersLocation: Coordinates;
  // geo = geofirex.init(firebase);
  geo?: { point: (arg0: any, arg1: any) => any };

  constructor(public http: HttpClient) {}

  getResults(keyword: string) {
    const url = '';
    // "https://maps.googleapis.com/maps/api/place/autocomplete/json?input=" +
    // keyword +
    // "&key=" +
    // environment.firebase.apiKey;

    return this.http.get(url).pipe(
      map((result: any) => {
        console.log(result);
        return result.predictions;
      })
    );
  }

  // test this
  getCallInfo(keyword: any) {
    const url = '';
    // "https://maps.googleapis.com/maps/api/place/details/json?placeid=" +
    // keyword +
    // "&fields=name,rating,formatted_phone_number&key=" +
    // environment.firebase.apiKey;

    return this.http
      .get(url)
      .pipe(
        map((result: any) => {
          console.log(result);
          return result.predictions;
        }, take(1))
      )
      .subscribe();
  }

  getCallCode(countryName: string) {
    if (countryName.includes(' ')) {
      countryName.replace(/ /g, '%20');
    }
    const url =
      'https://restcountries.eu/rest/v2/name/' + countryName + '?fullText=true';

    return this.http.get(url).pipe(
      tap((x: any) => console.log('c details: ', x)),
      map((data: any) => data['0'].callingCodes['0']),

      take(1)
    );
  }

  getAlphaCallCode(countryName: string) {
    if (countryName.includes(' ')) {
      countryName.replace(/ /g, '%20');
    }
    const url =
      'https://restcountries.eu/rest/v2/name/' + countryName + '?fullText=true';

    return this.http.get(url).pipe(
      tap((x) => console.log('c details: ', x)),
      map((data: any) => data['0'].alpha2Code),

      take(1)
    );
  }

  // Api is not reliable,look for alternative
  // checkNumber(
  //   countryCode,
  //   number // : Promise<boolean>
  // )
  // {
  /* return new Promise(resolve => {
      unirest
        .post("https://neutrinoapi-phone-validate.p.mashape.com/phone-validate")
        .header(
          "X-Mashape-Key",
          "slCmZIUBXHmshGXyP8wN3ly2pMRyp1Q1UGLjsn6CMNAKz5SxEP"
        )
        .header("X-Mashape-Host", "neutrinoapi-phone-validate.p.mashape.com")
        .header("Content-Type", "application/x-www-form-urlencoded")
        .send("number=+" + countryCode + number)
        .send("country-code=")
        .end(function(result) {
          console.log(result);
          resolve(result.body.valid);
        });
    });*/

  // }

  getCityFromGeo(lat: any, lng: any) {
    const url = ''; // `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${environment.firebase.apiKey}`;

    return this.http.get(url).pipe(
      map((geoData: any) => {
        if (!geoData || !geoData.results || geoData?.results === 0) {
          return null;
        }
        return geoData.results[0].formatted_address;
      }, take(1))
    );
  }

  getGeoData(lat: any, long: any) {
    return this.geo?.point(lat, long);
  }

  async getUserLocationCoords() {
    try {
      const resp = await Plugins.Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
      });
      return resp.coords;
    } catch (error) {
      console.log({ error });
    }
  }

  async getGeoCenter() {
    const coords = await this.getUserLocationCoords();
    console.log({ coords });
    const center = this.geo?.point(coords.latitude, coords.longitude);
    return center;
  }
}
