import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Auth, authState } from '@angular/fire/auth';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private authService: Auth,
    private router: Router,
    public toastCtrl: ToastController
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return authState(this.authService).pipe(
      <any>map((res: any) => {
        if (res === null || res?.isAnonymous) {
          this.toast().catch();
          this.router
            .navigate(['login'], {
              queryParams: {
                redirectUrl: next.url[0],
                segments: next.url.map((s) => s.path),
              },
              replaceUrl: true,
            })
            .catch();
          return false;
        } else {
          return true;
        }
      })
    );
  }

  async toast() {
    const toast = await this.toastCtrl.create({
      duration: 3000,
      buttons: [
        {
          text: 'Close',
          role: 'cancel',
          handler: () => {
            console.log('Close clicked');
          },
        },
      ],
      position: 'top',
      header:
        'You are not allowed to view that page. You are redirected to login Page',
    });

    await toast.present();
  }
}
