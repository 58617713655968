import {
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { ModalController } from '@ionic/angular';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { deleteObject, ref, Storage } from '@angular/fire/storage';
import { Image } from '../../models';

@Component({
  selector: 'radium-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
})
export class RDGenericModalComponent implements OnInit, OnDestroy {
  type?: string;
  url?: string;
  isBigDevice?: boolean;
  @Output() imageClicked = new EventEmitter();
  @Output() updatedImages = new EventEmitter();
  images?: Image[];
  imageCategories = [
    { id: 1, value: 'ID Document' },
    { id: 2, value: 'Proof of Residence' },
    { id: 3, value: 'Other' },
  ];
  // @HostListener("document:ionBackButton", ["$event"])
  // private async overrideHardwareBackAction($event: any) {
  //   await this.modalCtrl.dismiss();
  // }
  uploading = false;
  canClose = true;
  data?: any;

  constructor(
    public modalCtrl: ModalController,
    public formBuilder: FormBuilder,
    public router: Router,
    private storage: Storage
  ) {}

  ngOnInit() {
    console.log(this.data);
    console.log(this.type);

    const modalState = {
      modal: true,
      desc: 'fake state for our modal',
    };
    history.pushState(modalState, null);
  }

  @HostListener('window:popstate', ['$event'])
  closeModal(data?: any) {
    if (this.canClose) {
      const newData = data ?? {};
      newData.images = this.images;
      return this.modalCtrl.dismiss(newData);
    }
    return null;
  }

  submit(message: any) {
    this.closeModal({
      type: this.data?.type,
      message,
    }).catch();
  }

  contact(values: any) {
    this.closeModal({
      type: 'contact',
      data: values,
    })?.catch();
  }

  clickedImage(e: any) {
    console.log('clicked: ', e);

    if (e.action === 'delete') {
      const path: any = this.images?.[e.index]?.downloadURL;
      // this.storage.refFromURL(path).delete();
      deleteObject(ref(this.storage, path)).catch();
      this.images?.splice(e.index, 1);
      // if (this.book) {
      // TODO delete from book on firestore book images array too
      // this.db.removeBookImage(this.book.id, x);
      // }
    }
  }

  ngOnDestroy() {
    if (window.history.state.modal) {
      history.back();
    }
  }

  setUploading(e: any) {
    console.log({ e });
    if (e && e.downloadURL) {
      this.images?.push(e);
    }
    this.uploading = !this.uploading;
    setTimeout(() => (this.canClose = !this.uploading), 1000);
  }

  handleEvent(e: any) {
    if (e.event === 'close') {
      this.closeModal({ from: e.from, data: e.data, type: e.event }).catch();
    } else {
      this.closeModal({
        data: e?.data,
        type: this.data?.type,
      })?.catch();
    }
  }
}
