import { Component, Input, OnInit } from '@angular/core';
import {
  AlertController,
  ModalController,
  ToastController,
} from '@ionic/angular';
import { Observable } from 'rxjs';
import { cleanAnObject } from '../../../../../utils';
import { FormModalComponent } from '../../../../ui/src/lib/form-modal';
import { ledgerEntryFields, OrgLedgerEntry } from '../../../../ui/src/models';
import { TableConfig } from '@radium/screens';
import dayjs from 'dayjs';

@Component({
  selector: 'radium-table-modal',
  templateUrl: './table-modal.component.html',
  styleUrls: ['./table-modal.component.scss'],
})
export class TableModalComponent implements OnInit {
  @Input() collData$: Observable<any[]>;
  @Input() title: string;
  @Input() columns: {
    heading: string;
    numeric?: boolean;
    key: string;
    type: string;
  }[];
  @Input() tableConfigOverride;
  @Input() canAdd = false;
  @Input() addButtonText = 'Add';
  @Input() optionsList = [];
  @Input() extras: any;
  @Input() createEditFn: (actionType: string, data: any) => Promise<void>;

  selectedRows: any[] = [];
  tableConfig = <TableConfig>{};

  date: any;
  total = 0;

  constructor(
    private modalCtrl: ModalController,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController
  ) {}

  async ngOnInit() {
    this.tableConfig = {
      sourceData$: this.collData$,
      columns: [],
      actions: {
        position: 'right',
        columnTitle: 'Actions',
        add: true,
        edit: true,
        delete: false,
        view: false,
      },
      addButtonContent: this.addButtonText,
      pager: {
        display: true,
        perPage: 10,
      },
      selectMode: 'multi',
    };

    if (this.columns) {
      this.tableConfig.columns = this.columns;
    } else {
      this.tableConfig = {
        ...this.tableConfig,
        ...this.tableConfigOverride,
      };
    }

    // setTimeout(() => {
    //   this.settingsDone = true;
    //   console.log(this.settings);
    //   setTimeout(() => {
    //     const collection = document.getElementsByClassName('ng2-smart-th');
    //
    //     for (let i = 0; i < collection.length; i++) {
    //       collection[i]?.setAttribute(
    //         'title',
    //         this.columns?.[i]?.['description'] ??
    //           this.settings?.columns?.[i]?.title
    //       );
    //       if (!collection[i]?.classList.contains('word')) {
    //         collection[i]?.setAttribute('style', 'padding: 0');
    //       }
    //     }
    //   }, 500);
    // }, 1000);
  }

  rowSelectedHandler({ selected }) {
    console.log({ selected });

    this.selectedRows = selected;
  }

  async dismiss() {
    console.log(this.selectedRows);
    await this.modalCtrl.dismiss(this.selectedRows);
  }

  async tableModalAction(e: {
    type: 'edit' | 'delete' | 'add';
    tableData?: OrgLedgerEntry;
  }) {
    if (!e || !e?.tableData) return;

    if (e.type === 'delete') {
      await this.deleteField(e.tableData.uid);
      return;
    }

    const fields = ledgerEntryFields.map((f) => {
      if (f.type === 'select') {
        f.props.options = this.optionsList;
      }
      return f;
    });

    const { createdAt, updatedAt, ...rest } = e.tableData;
    const rowItemUid = rest.uid;

    const getUid = (a: string) =>
      this.optionsList.find((o) => o.label === rest[a]).value;

    rest.creditAccount = getUid('creditAccount');
    rest.debitAccount = getUid('debitAccount');
    rest.date = dayjs(rest.date).toISOString();

    const modalRef = await this.modalCtrl.create({
      component: FormModalComponent,
      componentProps: {
        title: this.addButtonText,
        incomingData: rest,
        fields: fields,
      },
    });

    await modalRef.present();

    modalRef.onDidDismiss().then(({ data }) => {
      if (data) {
        const cleanData = cleanAnObject(data);
        this.createEditFn('edit', { ...cleanData, uid: rowItemUid });
      }
    });
  }

  private async deleteField(uid: string) {
    const alert = await this.alertCtrl.create({
      header: 'Confirm Delete',
      message: `Are you sure you want to delete field  ?`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Delete cancelled');
          },
        },
        {
          text: 'Delete',
          handler: async () => {
            await this.createEditFn('delete', {});
          },
        },
      ],
    });
    await alert.present();
  }

  dateChanged(e: any) {}

  calculateTotal(invoice): number {
    let total = 0;
    for (const lineItem of invoice.lineItems) {
      total += lineItem.product.price * lineItem.quantity;
    }
    return total;
  }
}
