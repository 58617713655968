import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'radium-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  styleUrls: ['./select-dropdown.component.scss'],
})
export class SelectDropdownComponent implements OnInit {
  @Input() heading?: string;
  @Input() dataList?: string[];
  @Output() selected = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => this.selected.emit(this.dataList?.[0]), 1000);
  }

  onChange(e: any) {
    this.selected.emit(e.detail.value);
  }
}
