<div
  [ngStyle]="
    isFinal
      ? { marginTop: '10px' }
      : pushLeft
      ? { left: '45%', position: 'relative' }
      : { position: 'relative' }
  "
>
  <!-- allow pass in of custom styles to back button  -->
  <button
    [disabled]="disabledBack"
    [ngStyle]="backButtonStyles"
    mat-raised-button
    color="accent"
    matStepperPrevious
    *ngIf="!isFirst"
  >
    Back
  </button>

  <button
    [disabled]="disabled"
    [ngStyle]="forwardButtonStyles"
    matStepperNext
    mat-raised-button
    color="primary"
    (click)="clickEmit.emit()"
  >
    {{ nextButtonText || 'Continue' }}
  </button>
</div>
