import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(public toast: ToastController) {}

  async toastDismiss(from: string) {
    return await this.toast
      .dismiss()
      .then(() => console.log('toast dismissed ' + from));
  }

  async presentToast(
    message: any,
    from: any,
    position: any,
    duration?: number,
    color?: any
  ) {
    this.toast.getTop().then((x: any) => (x ? x.dismiss() : null));

    const toast = await this.toast.create({
      duration,
      position,
      cssClass: 'toastyCss',
      message: message,
      color,
      buttons: [{ role: 'cancel', text: 'close' }],
    });
    toast.present().catch();
  }
}
