<ion-row>
  <ion-col class="ion-text-center ion-no-padding">
    <ion-button
      class="ion-no-padding ion-no-margin"
      color="clear"
      shape="round"
      (click)="incrDecrAction('decrement')"
      [disabled]="disableDecrement"
      style="height: 80%; margin-bottom: 5px"
      [size]="size"
      *ngIf="canEdit"
    >
      <ion-icon
        [size]="size"
        color="secondary"
        name="remove-circle-outline"
      ></ion-icon>
    </ion-button>
    <span
      [ngStyle]="hasPadding ? { padding: '10px' } : null"
      [ngStyle]="amount > 9 ? { fontSize: '11px' } : null"
    >
      {{ '  ' + amount + '  ' }}
    </span>

    <ion-button
      class="ion-no-padding ion-no-margin"
      color="clear"
      (click)="incrDecrAction('increment')"
      shape="round"
      style="height: 80%; margin-bottom: 5px"
      [size]="size"
      *ngIf="canEdit"
      [disabled]="disableIncrement"
    >
      <ion-icon
        [size]="size"
        color="secondary"
        name="add-circle-outline"
      ></ion-icon>
    </ion-button>
  </ion-col>
</ion-row>
