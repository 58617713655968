import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { popupClose } from '@syncfusion/ej2-angular-schedule';

@Component({
  selector: 'radium-adhoc-job',
  templateUrl: './adhoc-job.component.html',
  styleUrls: ['./adhoc-job.component.scss'],
})
export class AdhocJobComponent {
  @Output() adhocJobEvent = new EventEmitter<{ type: string; data?: string }>();
  adhocJobForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.adhocJobForm = this.formBuilder.group({
      message: ['', Validators.required],
    });
  }

  onSubmit() {
    if (this.adhocJobForm.valid) {
      // Send the form data to the backend for manual follow-up by an admin
      console.log(this.adhocJobForm.value);
      this.adhocJobEvent.emit({
        type: 'addJob',
        data: this.adhocJobForm.value,
      });
    }
  }
}
