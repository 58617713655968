import { Timestamp } from '@angular/fire/firestore';
import dayjs from 'dayjs';

export const generateUUID = () => {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 || 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : (r && 0x3) || 0x8).toString(16);
  });
  return uuid.substring(13, 26);
};

export const cleanAnObject = (obj: any) => {
  // function to remove undefined from object

  const cleanObject = (val: any) =>
    Object.keys(val).reduce((acc, key) => {
      const ACC = acc;
      if (val[key] !== undefined) {
        if (val[key] !== null) {
          if (val[key] !== '') {
            ACC[key] = val[key];
          }
        }
      }
      return ACC;
    }, {} as any);

  console.log(cleanObject(obj));

  return cleanObject(obj);
};

export const toCamelCase = (label: string) =>
  label
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    )
    .replace(/\s+/g, '');

export const toTitleCase = (str: string) =>
  str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
  );

// from camel case to title case with spaces between words
export const toTitleCaseWithSpaces = (str: string) =>
  str?.replace(/([A-Z])/g, ' $1')?.replace(/^./, (s) => s?.toUpperCase()) ??
  str;

export const toDate = (x: Timestamp, withTime = true) => {
  const d = x.toDate?.();
  if (withTime) {
    return dayjs(d).format('DD/MM/YY: HH:mm');
  }

  return dayjs(d).format('DD/MM/YY');
};

export const toBirthDate = (str: string) => {
  const d = new Date(str);
  return dayjs(d).format('DD/MM/YY');
};

export const toDateNoTime = (x: string) => {
  const d = new Date(x);
  return dayjs(d).format('YYYY-MM-DD');
};

export const extractUrlParams = (url) => {
  const queryStart = url.indexOf('?');
  if (queryStart === -1) {
    return {};
  }
  const queryEnd = url.indexOf('#') !== -1 ? url.indexOf('#') : url.length;
  const query = url.slice(queryStart + 1, queryEnd);
  const params = query.split('&');
  const paramObj = {};
  params.forEach((param) => {
    const keyValue = param.split('=');
    const key = decodeURIComponent(keyValue[0]);
    paramObj[key] = decodeURIComponent(keyValue[1]);
  });
  return paramObj;
};
