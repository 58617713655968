import { Appointment } from './user';
import { Image, Like, Service } from './generic';
import { Address } from './address';

interface JobHours {
  [day: string]: string;
}

export interface Job {
  id: string;
  authorId: string;
  authorType: string;
  rating?: number;
  createdAt: any;
  history?: any;
  approved: boolean;
  viewedByAdmin: boolean;
  images?: Image[];
  views?: number;
  likes?: Like[];
  type?: string;
  contactPersonName: string;
  contactPersonNumber: string;
  whatsappNumber?: string;
  services: Service[];
  email: string;
  whatMatters?: string;
  slug?: string;
  status?: string;
  thumbnail?: string;
  metaDescription?: string;
  metaKeywords?: string[];
  address: Address;
  appointment: Partial<Appointment>;
  // bankDetails: BankDetails;
  acceptAgreement: boolean;
  isDraft: boolean;
  state: State;
  packageName?: 'gold' | 'silver' | 'bronze';
  notes?: { createdAt: any; message: string }[];
  cancelReason?: string;
  payment_method?: 'cash' | 'eft';
  adhocMsg?: string;
  // jobHours: { [day: string]: { open: string; close: string } };
}

export interface JobBasic {
  // position: FirePoint;
  approved: boolean;
  jobId: string;
  authorId: string;
  authorType: string;
  hitMetadata?: any;
  packageName?: 'gold' | 'silver' | 'bronze';
}

export enum State {
  services_selected = 1,
  appointment_created = 2,
  address_added = 3,
  contact_details_added = 4,
  tcs_accepted = 5,
  submitted = 6,
  quote_sent = 7,
  quote_accepted = 8,
  job_cancelled = 9,
  job_completed = 10,
  in_progress = 11,
  awaiting_customer_response = 12,
  customer_rating = 13,
  customer_rating_completed = 14,
  awaiting_appointment_confirmation = 15,
  appointment_confirmed = 16,
}
